import Vue from 'vue';

export const placeOrderRequest = async ({
  session,
  comment,
  tipAmount,
  firebase_uid,
  details = {},
}) => {
  const response = await Vue.axios.post(`/cart/${session}/place-order`, {
    comment,
    tipAmount,
    firebase_uid,
    ...details,
  });

  return response.data.order;
};

export const updateCartItemCoursesRequest = async ({ session, items }) => {
  const response = await Vue.axios.post(`/cart/${session}/update-courses`, {
    cart_items: items,
  });

  return response.data.cart;
};
