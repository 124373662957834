<template>
  <div
    :style="cssProps"
    class="d-flex align-center"
    :class="{ 'mb-2': this.$store.state.restaurant.description != null }"
  >
    <img
      v-if="$store.getters.customizationLogo"
      class="mr-2"
      style="max-height: 28px;"
      :src="$store.getters.customizationLogo"
    />
    <h4
      class="tp-title-bold restaurant-title"
      :class="{
        'restaurant-title--white': $store.getters.customizationMenuDarkMode
      }"
    >
      {{ $t(title) }}
    </h4>
  </div>
</template>

<script>
export default {
  computed: {
    title() {
      return this.$store.state.restaurant.name;
    },
    cover() {
      return this.$store.state.restaurant.cover;
    },
    loyaltyProgramEnabled() {
      return this.$store.getters.functionalityLoyaltyProgramEnabled;
    },
    width() {
      if (!this.cover) {
        return this.loyaltyProgramEnabled
          ? 'calc(100% - 140px)'
          : 'calc(100% - 96px)';
      }

      return '100%';
    },
    cssProps() {
      return {
        '--width': this.width,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.restaurant-title {
  font-family: var(--menu-font-family) !important;
  font-size: var(--menu-restaurant-title-font-size) !important;
  font-weight: var(--menu-restaurant-title-font-weight) !important;
  color: var(--menu-restaurant-title-font-color) !important;
  max-width: var(--width);
  &--white {
    color: #fff;
  }
}
</style>
