<template>
    <div class="dish-tags-sheet">
        <div class="dish-tags-sheet__tag d-flex"
             v-for="(tag, key) in tags"
             v-bind:key="key"
        >
            <div class="dish-tags-sheet-tag__icon d-flex align-center" :class="{'mr-1': tag.image}">
                <v-icon v-if="tag.icon" small
                        :color="tag.icon_color"
                        :title="$t(tag.name)">
                    {{ tag.icon }}
                </v-icon>
                <img v-else-if="tag.image" :src="tag.image" :style="{width: tag.width + 'px'}"/>
            </div>
            <div class="dish-tags-sheet-tag__text tp-text-body-gray">
                {{ $t(tag.name) }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
      props: {
        tags: Array
      }
    }
</script>

<style lang="scss">
    .dish-tags-sheet {
        display: flex;
        flex-wrap: wrap;
        gap: 5px 10px;

        &__tag {
            align-items: center;
        }
    }

    .dish-tags-sheet-tag {
        &__text {
            color: gray;
        }
    }
</style>