import { fetchPaymentHistory } from './api';

const initialState = () => ({
  paymentHistory: [],
  paymentHistoryStatus: 'idle',
});

export default {
  state: initialState,
  mutations: {
    SET_PAYMENT_HISTORY(state, paymentHistory) {
      state.paymentHistory = paymentHistory;
    },
    SET_PAYMENT_HISTORY_STATUS(state, paymentHistoryStatus) {
      state.paymentHistoryStatus = paymentHistoryStatus;
    },
  },
  actions: {
    async fetchPaymentHistory({ commit, rootState: { session } }) {
      commit('SET_PAYMENT_HISTORY_STATUS', 'loading');
      try {
        const paymentHistory = await fetchPaymentHistory({ session });
        commit('SET_PAYMENT_HISTORY', paymentHistory);
        commit('SET_PAYMENT_HISTORY_STATUS', 'idle');
      } catch (error) {
        commit('SET_PAYMENT_HISTORY_STATUS', 'failed');
        console.error(error);
      }
    },
  },
  getters: {
    paymentHistory: state => state.paymentHistory,
    paymentHistoryLoading: state => state.paymentHistoryStatus === 'loading',
  },
};
