<template>
  <LayoutCard type="header" shadow compact>
    <div class="header pt-2">
      <div class="header item left">
        <div v-if="showBackArrow">
          <v-icon @click="onBackClick"> mdi-arrow-left </v-icon>
        </div>
      </div>
      <div class="header item">
        <h3 class="tp-text-subtitle-semibold pb-1">{{ title }}</h3>
        <span v-if="subtitle" class="tp-text-body"> {{ subtitle }} </span>
      </div>
      <div class="header item right">
        <div v-if="cta">
          <v-icon @click="cta.onClick">
            {{ cta.icon }}
          </v-icon>
        </div>
      </div>
    </div>
  </LayoutCard>
</template>

<script>
import LayoutCard from '@/components/LayoutCard.vue';

export default {
  components: {
    LayoutCard,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: null,
      required: false,
    },
    showBackArrow: {
      type: Boolean,
      default: true,
    },
    cta: {
      type: Object,
      default: null,
      required: false,
    },
  },
  methods: {
    onBackClick() {
      this.$emit('onBackClick');
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .item {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .left {
    flex: 0.2;
    align-items: flex-start;
    justify-content: flex-start !important;
  }

  .right {
    flex: 0.2;
    align-items: flex-end;
    justify-content: flex-start !important;
  }
}
</style>