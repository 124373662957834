import Vue from 'vue';

export const fetchOrderFromPOSRequest = async ({ session }) => {
  const response = await Vue.axios.get(`/orders/get-paygo-orders/${session}`);

  return response.data.data;
};

export const selectOrderItemsRequest = async ({
  session,
  uniqueOrderNumber,
  selectedOptions,
}) => {
  return await Vue.axios.post(`/orders/select-order-items/${session}`, {
    unique_order_number: uniqueOrderNumber,
    order_items: selectedOptions,
  });
};

export const canPayForOrderRequest = async ({ session, uniqueOrderNumber }) => {
  const response = await Vue.axios.post(
    `/orders/can-pay-for-order/${session}`,
    {
      unique_order_number: uniqueOrderNumber,
    }
  );
  return response.data;
};

export const getSelectedOrderItemsRequest = async ({
  uniqueOrderNumber,
  session,
}) => {
  const response = await Vue.axios.post(
    `/orders/get-selected-order-items/${session}`,
    {
      unique_order_number: uniqueOrderNumber,
    }
  );
  return response.data;
};

export const fetchPaymentByUuidRequest = async ({ paymentUuid }) => {
  const response = await Vue.axios.get(
    `/payments/payment-by-uuid/${paymentUuid}`
  );

  return response.data.data;
};

export const fetchLastPaymentRequest = async ({
  session,
  uniqueOrderNumber,
}) => {
  const response = await Vue.axios.post(`/payments/last-payment/${session}`, {
    unique_order_number: uniqueOrderNumber,
  });

  return response.data.data;
};
