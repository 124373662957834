export const normalizeNewLines = string => {
  if (!string) {
    return null;
  }
  const regex = /<br\s*[/]?>/gi;

  return string.replace(regex, '');
};

export const parseQueryParam = string => {
  if (!string) {
    return null;
  } else if (string === 'null') {
    return null;
  } else if (string === 'undefined') {
    return undefined;
  }

  return string;
};
