<template>
  <span class="dish-tags-list mr-1">
    <span v-for="(tag, key) in tags" :key="key" class="dish-tags-list__tag" :class="{'dish-tags-list__tag--spacing': tag.image}">
      <v-icon
        v-if="tag.icon"
        small
        :color="tag.icon_color"
        :title="$t(tag.name)"
      >
        {{ tag.icon }}
      </v-icon>
      <img v-else-if="tag.image" :src="tag.image" :style="{width: tag.width + 'px'}"/>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    tags: Array,
  },
};
</script>

<style lang="scss">
.dish-tags-list {
  overflow: hidden;
  white-space: nowrap;

  &__tag {
    line-height: 0;
    &--spacing:not(:first-child) {
        margin-left: 3px;
    }
  }
}
</style>
