<template>
  <draggable
    v-bind="dragOptions"
    tag="div"
    class="list-group"
    handle=".handle"
    :list="list"
    :value="value"
    :data-content="$t(`components.sortableCart.dragHereLabel`)"
    @input="onChange"
    @start="onStartDrag"
  >
    <div v-for="(el, key) in realValue" :key="el.id">
      <div class="item">
        <CartItem
          :id="el.id"
          :key="`cart-item-${el.id}`"
          :show-sort-handle="editMode"
          :item-key="key"
          :name="el.name + (el.variation ? ` (${el.variation.name})` : '')"
          :price="el.price"
          :quantity="el.quantity"
          :modifiers="el.modifiers"
          v-bind="$attrs"
          v-on="$listeners"
        />
      </div>
    </div>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable';
import CartItem from '@/components/Cart/CartItem.vue';

export default {
  components: {
    draggable,
    CartItem,
  },
  props: {
    value: {
      required: false,
      type: Array,
      default: null,
    },
    list: {
      required: false,
      type: Array,
      default: null,
    },
    courseId: {
      required: false,
      type: Number,
      default: null,
    },
    editMode: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: !this.editMode,
        ghostClass: 'ghost',
        chosenClass: 'chosen',
        dragClass: 'drag',
      };
    },
    realValue() {
      return this.value ? this.value : this.list;
    },
  },
  methods: {
    onChange(value) {
      this.$emit('change', value, this.courseId);
    },
    onStartDrag() {
      this.$store.dispatch('sendAnalyticsEvent', {
        event: 'app_cart_courses-edit_move-item',
      });
    },
  },
};
</script>

<style>
.ghost {
  opacity: 0;
}

.chosen {
  scale: 1.02;
}
</style>

<style scoped lang="scss">
.list-group:empty {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e0e0e0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: $color-gray;
  opacity: 0.5;
  content: attr(data-content);
}

.list-group:empty:before {
  content: attr(data-content);
}

.item {
  padding: 1rem;
  background-color: #fefefe;
  border-bottom: 1px solid #e0e0e0;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}
</style>
