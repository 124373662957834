import { callWaiterRequest } from './api';

const initialState = {
  callWaiterStatus: 'idle',
};

export default {
  state: initialState,
  mutations: {
    setCallWaiterStatus(state, status) {
      state.callWaiterStatus = status;
    },
  },
  actions: {
    async callWaiter({ commit, rootState }, { orderNumber }) {
      try {
        commit('setCallWaiterStatus', 'loading');
        const { session } = rootState;

        await callWaiterRequest({ session, orderNumber });

        commit('setCallWaiterStatus', 'idle');
      } catch (error) {
        commit('setCallWaiterStatus', 'idle');
        console.error(error);
      }
    },
  },
};
