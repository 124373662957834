<template>
  <div
    class="menu__splash-screen d-flex align-center justify-space-between flex-column"
    :class="{
      'justify-space-between': loadingScreenCustomizations.logo || showButtons,
    }"
    :style="{ ...customizationStyles }"
  >
    <div
      class="menu-splash-screen__content d-flex align-center justify-center flex-column"
      :class="{ 'justify-space-around': showButtons }"
    >
      <div
        class="menu-splash-screen__image-wrapper d-flex align-center justify-center"
      >
        <img
          v-if="getLogo"
          class="menu-splash-screen__image"
          :src="getLogo"
          :style="{
            width: loadingScreenCustomizations.logo_width,
            height: loadingScreenCustomizations.logo_height,
          }"
        />
        <GreetLogo
          v-else
          :width="275"
          :height="70"
          :color="isLightBackground ? 'black' : 'white'"
        ></GreetLogo>
      </div>

      <div
        v-if="status === 'active' && !failure && !showButtons"
        class="menu-splash-screen__loader"
      >
        <Spinner
          class="mt-10"
          :color="loadingScreenCustomizations.loader_color || '#FFF'"
          :stroke-width="2"
        ></Spinner>
      </div>
      <div
        v-else-if="failure"
        class="menu-splash-screen__failure-refresh-wrapper mt-10"
      >
        <div
          v-if="status === 'updating_menu'"
          class="menu-splash-screen__updating-text"
        >
          {{ $t('generic.menuUpdating') }}
        </div>
        <div
          v-else-if="status === 'disabled'"
          class="menu-splash-screen__services-disabled-text"
        >
          {{ $t('generic.servicesAreDisabled') }}
        </div>
        <div v-else>
          <div class="failure-text">
            {{ $t('generic.somethingWentWrong') }}
          </div>
          <button class="failure-button" @click="refreshPage">
            {{ $t('generic.reloadButton') }}
          </button>
        </div>
      </div>

      <Transition name="opacity">
        <div v-if="showButtons" class="menu-splash-screen__pay-go-info">
          <div
            v-if="
              !$store.getters.customizationSelectScreenHideRestaurantTitle ||
                !$store.getters.customizationSelectScreenHideTableName
            "
            class="mb-8"
          >
            <div
              v-if="
                !$store.getters.customizationSelectScreenHideRestaurantTitle
              "
              class="menu-splash-screen-pay-go-info__restaurant-name tp-title-semibold"
              data-cy="pay-go-restaurant-name"
            >
              {{ $store.state.restaurant.name }}
            </div>
            <div
              v-if="!$store.getters.customizationSelectScreenHideTableName"
              class="menu-splash-screen-pay-go-info__table-name tp-text-label mt-2"
            >
              {{
                $store.state.restaurant.type === 'restaurant'
                  ? $t('screens.selectScreen.tableName', {
                      tableNumber: $store.state.table.name,
                    })
                  : $t('screens.selectScreen.tableNameWithoutTable', {
                      tableNumber: $store.state.table.name,
                    })
              }}
            </div>
          </div>

          <SocialLinks :social-links="socialLinks" />
          <div class="menu-splash-screen-pay-go-info__ctas">
            <Button
              v-if="!$store.getters.customizationSelectScreenHideViewMenuButton"
              class="menu-splash-screen-pay-go-infos-ctas__button"
              no-shadow
              data-cy="select-screen-open-menu"
              @click="navigateToMenu"
            >
              <span v-if="$store.getters.customizationSelectScreenViewMenuText">
                {{ $t($store.getters.customizationSelectScreenViewMenuText) }}
              </span>
              <span v-else-if="isOrderEnabled">
                {{ $t('screens.selectScreen.viewMenuAndOrderCta') }}
              </span>
              <span v-else>
                {{ $t('screens.selectScreen.viewMenuCta') }}
              </span>
            </Button>
            <Button
              v-if="isPayGoEnabled"
              class="mt-6 menu-splash-screen-pay-go-infos-ctas__button"
              data-cy="pay-go-split-bill"
              no-shadow
              @click="navigateToPayGo"
            >
              {{ $t('screens.selectScreen.payBillCta') }}
            </Button>
            <CallWaiterButton
              class="mt-6 menu-splash-screen-pay-go-infos-ctas__button"
            />
            <Button
              v-for="(button, index) in selectScreenButtons"
              :key="index"
              class="mt-6 menu-splash-screen-pay-go-infos-ctas__button"
              :data-cy="`select-screen-additional-button-${index}`"
              no-shadow
              @click="onButtonClick(button)"
            >
              {{ $t(button.text) }}
            </Button>
          </div>
        </div>
      </Transition>
    </div>

    <div class="easy-with-greet d-flex align-end mt-2" >
      <span
        class="easy-with-greet__text tp-text-body-2"
        :class="{
          'easy-with-greet__text--dark':
            loadingScreenCustomizations.dark_powered_by || isLightBackground,
        }"
        >{{ $t('generic.easyWith') }}</span
      >
      <GreetLogo
        :width="55"
        :height="14"
        :color="isLightBackground ? 'black' : 'white'"
      ></GreetLogo>
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/Spinner.vue';
import Button from '@/components/Button.vue';
import GreetLogo from '@/components/GreetLogo.vue';
import { lightOrDarkColor } from '@/helpers/lightOrDark.js';
import SocialLinks from '@/components/common/SocialLinks.vue';
import CallWaiterButton from '@/components/SplashScreen/CallWaiterButton.vue';

export default {
  components: {
    Spinner,
    Button,
    GreetLogo,
    SocialLinks,
    CallWaiterButton,
  },
  props: {
    status: String,
    failure: Boolean,
    loadingScreenCustomizations: {
      type: [Object, Array, Boolean],
      default: false,
    },
  },
  computed: {
    customizationStyles() {
      const customizations = [];
      if (this.loadingScreenCustomizations.background_color) {
        customizations.background = this.loadingScreenCustomizations.background_color;
      }
      if (this.loadingScreenCustomizations.background_image) {
        customizations.backgroundImage = `url('${this.loadingScreenCustomizations.background_image}')`;
      }
      return customizations;
    },
    getLogo() {
      if (this.loadingScreenCustomizations.logo) {
        return this.loadingScreenCustomizations.logo;
      }
      return null;
    },
    showSelectScreenEnabled() {
      return this.$store.getters.functionalityShowSelectScreenEnabled;
    },
    showButtons() {
      return this.isPayGoEnabled || this.showSelectScreenEnabled;
    },
    selectScreenButtons() {
      return this.$store.getters.selectScreenButtons;
    },
    isOrderEnabled() {
      return (
        this.$store.getters.functionalityOrderEnabled &&
        !this.$store.getters.ordersTemporarilyDisabled
      );
    },
    isPayGoEnabled() {
      return this.$store.getters.functionalityPayGoEnabled;
    },
    isLightBackground() {
      if (
        this.loadingScreenCustomizations.background_color &&
        lightOrDarkColor(this.loadingScreenCustomizations.background_color) ===
          'light'
      ) {
        return true;
      }
      return false;
    },
    socialLinks() {
      return this.$store.getters.selectRestaurantSocialLinks;
    },
  },
  watch: {
    isPayGoEnabled: function() {
      if (this.isPayGoEnabled) {
        this.$store.dispatch('fetchOrdersFromPOS');
      }
    },
  },
  mounted() {
    this.$store.dispatch('sendAnalyticsEvent', {
      event: 'loading_screen',
    });
    if (this.failure) {
      this.$store.dispatch('sendAnalyticsEvent', {
        event: 'fail_screen_displayed',
      });
    }
    if (this.isPayGoEnabled) {
      this.$store.dispatch('fetchOrdersFromPOS');
    }
  },
  methods: {
    refreshPage() {
      this.$store.dispatch('sendAnalyticsEvent', {
        event: 'onfail-reload-button-click',
      });
      this.$router.go(this.$router.currentRoute);
    },
    async navigateToMenu() {
      if (this.$store.getters.restaurantMenuFilters.length > 0) {
        await this.$router.push({
          name: 'MenuFilters',
          params: { session: this.$store.state.session },
        });
      } else {
        await this.$router.push({
          name: 'Menu',
          params: { session: this.$store.state.session },
        });
      }
    },
    async navigateToPayGo() {
      const orderNumber = this.$store.getters.getFirstPOSOpenOrderId;
      let params = {
        name: 'Order',
        params: { session: this.$store.state.session },
      };
      if (orderNumber) {
        params.query = { orderNumber };
      }
      this.$router.push(params);
    },
    onButtonClick(button) {
      if (button.link && button.open_new_tab) {
        return window.open(button.link, '_blank');
      }

      return (window.location = button.link);
    },
  },
};
</script>

<style lang="scss">
.menu {
  &__splash-screen {
    background-color: $color-primary;
    height: 100vh;
    height: calc(var(--app-height) * 1px);
    width: 100%;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    position: relative;
    padding: 20px 0;
  }
}

.menu-splash-screen {
  &__content {
    width: inherit;
    min-height: 75vh;
  }

  &__image-wrapper {
    min-width: 100%;
  }

  &__image {
    width: 40%;
  }

  &__updating-text {
    color: $color-white;
    text-align: center;
  }

  &__services-disabled-text {
    color: $color-white;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
  }

  &__failure-refresh-wrapper {
    text-align: center;
    color: white;
  }

  &__loader {
    height: 150px;
  }

  &__pay-go-info {
    text-align: center;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
  }
}

.menu-splash-screen-pay-go-info {
  &__restaurant-name {
    color: var(--select-screen-restaurant-title-color);
    text-transform: uppercase;
  }
  &__table-name {
    text-transform: uppercase;
    color: var(--select-screen-restaurant-title-color);
  }
  &__ctas {
    padding-top: 32px;
  }
}

.menu-splash-screen-pay-go-infos-ctas {
  &__button {
    background: var(--select-screen-buttons-background-color) !important;
    color: var(--select-screen-buttons-text-color) !important;
    border-radius: var(--select-screen-buttons-border-radius) !important;
  }
}

.failure-button {
  padding: 5px 15px;
  background: white;
  border-radius: 5px;
  margin-top: 15px;
  color: black;
  font-size: 14px;
}

.easy-with-greet {
  &__text {
    color: white;
    &--dark {
      color: black;
    }
  }
}
</style>
