<template>
  <div
    class="d-flex flex-row flex-wrap pt-3 justify-space-between"
    style="flex: 1"
    :style="cssProps"
  >
    <v-chip-group
      v-model="selection"
      style="flex: 1"
      active-class="deep-purple--text text--accent-4"
      :mandatory="!customTipAmount"
      @change="setTipAmount"
    >
      <v-chip
        v-for="(tip, index) in tips"
        :key="index"
        :value="tip.value"
        color="#F5F5F5"
        label
        style="height: 52px !important; flex: 1;"
        class="d-flex flex-column align-center"
        active-class="active-chip"
        :data-cy="`tip-amount-${index}`"
      >
        <div v-if="tip.percentLabel" class="d-flex flex-column align-center">
          <span>{{ tip.percentLabel }}</span>
          <span class="tp-text-label-2">{{ tip.label }}</span>
        </div>
        <div v-else>
          {{ tip.label }}
        </div>
      </v-chip>
    </v-chip-group>
    <v-chip-group class="custom-amount">
      <v-chip
        color="#F5F5F5"
        label
        class="d-flex flex-column align-center mr-0"
        active-class="active-chip"
        style="height: 52px !important; width: 100%;"
        data-cy="tip-amount-custom"
        :input-value="customTipAmount"
        :value="!showCustomTipSheet"
        @click="toggleCustomTipSheet"
      >
        <div
          v-if="customTipAmount"
          style="text-wrap: wrap; white-space: normal; word-break: break-word;"
        >
          {{ customTipAmountFormatted }}
        </div>
        <div
          v-else
          style="text-wrap: wrap; white-space: normal; word-break: break-word;"
        >
          {{ $t('screens.placeOrder.tipsCustomAmountChipLabel') }}
        </div>
      </v-chip>
    </v-chip-group>
    <MoneyBottomSheet
      :title="$t('screens.placeOrder.tipsSheetTitle')"
      :subtitle="
        subtotalPrice &&
          $t('screens.placeOrder.tipsSheetSubtitle', { subtotalPrice })
      "
      :is-open="showCustomTipSheet"
      :min="min"
      :max="max"
      @on-close="showCustomTipSheet = !showCustomTipSheet"
      @on-save="setCustomTipAmount"
    />
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import formatPriceWithCurrency from '@/helpers/formatPriceWithCurrency.js';

import MoneyBottomSheet from '@/components/common/MoneyBottomSheet.vue';

export default {
  components: {
    MoneyBottomSheet,
  },
  props: {
    tips: {
      type: Array,
      default: () => [],
    },
    subtotalPrice: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
    min: {
      type: Number,
      default: 0.5,
    },
    max: {
      type: Number,
      default: 200,
    },
  },
  data() {
    return {
      selection: 0,
      tipAmount: this.tips.find(tip => tip.selected)?.value || 0,
      customTipAmount: 0,
      showCustomTipSheet: false,
    };
  },
  computed: {
    customTipAmountFormatted() {
      return formatPriceWithCurrency(
        this.customTipAmount,
        this.$store.state.currency.code,
        this.$store.state.locale.locale_code,
        0
      );
    },
    finalTip() {
      if (this.customTipAmount > 0) {
        return this.customTipAmount;
      } else if (this.tipAmount > 0) {
        return this.tipAmount;
      }

      return null;
    },
    cssProps() {
      const customAmountWidth = 100 / (this.tips.length + 1) + '%';
      return {
        '--active-chip-color': this.color || '#E85941',
        '--custom-amount-width': customAmountWidth,
      };
    },
  },
  created() {
    this.debouncedFetch = debounce(finalTip => {
      this.customTipAmount = null;
      this.$emit('on-change', finalTip);
    }, 200);
  },
  mounted() {
    const preselectedTip = this.tips.find(tip => tip.selected);

    if (preselectedTip) {
      this.$emit('on-change', preselectedTip.value);
      this.selection = preselectedTip.value;
    }
  },
  methods: {
    toggleCustomTipSheet() {
      if (!this.showCustomTipSheet) {
        this.showCustomTipSheet = true;
      } else {
        this.showCustomTipSheet = false;
      }
    },
    setCustomTipAmount(amount) {
      this.selection = null;
      this.customTipAmount = amount;
      this.$emit('on-change', this.finalTip);

      this.$store.dispatch('sendAnalyticsEvent', {
        event: 'set_custom_tip_amount',
        amount,
      });
    },
    setTipAmount(value) {
      if (value === null || value === undefined) {
        return;
      }
      this.selection = value;
      this.debouncedFetch(value);

      this.$store.dispatch('sendAnalyticsEvent', {
        event: 'set_tip_amount',
        amount: value,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.active-chip {
  color: white !important;
  font-weight: 600 !important;
  background-color: var(--active-chip-color) !important;
}

.custom-amount ::v-deep {
  text-align: center;
  text-wrap: wrap;
  width: var(--custom-amount-width);
  min-width: 78px;
  .v-slide-group__content {
    width: var(--custom-amount-width);
    min-width: 78px;
  }
}
</style>
