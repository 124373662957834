<template>
  <div>
    <div class="d-flex flex-column justify-center text-center align-center">
      <p class="tp-text-subtitle-bold text-center mb-8 mt-10">
        {{ $t(text) }}
      </p>
      <lottie-vue-player
        src="/assets/order-status-animations/loading.json"
        style="width: 100px; height:100px"
        autoplay
        loop
      />
    </div>
    <div class="d-flex justify-center mt-8" style="height: 36px">
      <Button v-if="showCancel" small dark no-shadow @click="abort">
        <span class="tp-text-subtitle-semibold-white">
          {{ $t('kiosk.payInPerson.dismissCTA') }}
        </span>
      </Button>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';

export default {
  components: {
    Button,
  },
  props: {
    dialogText: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    showCancel: false,
    CRN: null,
  }),
  computed: {
    text() {
      const dialogText = this.dialogText || [];

      if (dialogText.length > 0) {
        return dialogText.join('\n').trim();
      }

      return dialogText.join('\n');
    },
  },
  methods: {
    async show({ showCancel, CRN }) {
      this.showCancel = showCancel;
      this.CRN = CRN;
    },
    hide() {
      this.backdrop = {};
      this.CRN = null;
    },
    abort() {
      this.hide();
      this.$emit('abort');
    },
    getIsOpenForCRN(CRN) {
      return this.CRN === CRN;
    },
  },
};
</script>
