<template>
  <div class="details-container">
    <NavHeader
      :title="$t('screens.newUserForm.navTitle')"
      @onBackClick="goToPlaceOrder"
    />
    <LayoutCard larger-container grow>
      <div>
        <div class="d-flex justify-space-between">
          <h4>{{ $t('screens.newUserForm.headerTitle') }}</h4>
          <v-chip small>{{ $t('required') }}</v-chip>
        </div>
        <div class="tp-text-body mt-3 mb-1">
          {{ $t('screens.newUserForm.headerSubtitle') }}
        </div>
      </div>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="updateUserInfo"
      >
        <v-text-field
          v-model="name"
          :rules="getFieldRules({ required: true })"
          :label="$t('screens.newUserForm.firstNameLabel')"
          :placeholder="$t('screens.newUserForm.firstNamePlaceholder')"
          required
        />
        <v-text-field
          v-model="phoneNumber"
          :rules="getFieldRules({ required: true, type: 'tel' })"
          :label="$t('screens.newUserForm.phoneNumberLabel')"
          :placeholder="$t('screens.newUserForm.phoneNumberPlaceholder')"
          type="tel"
          required
        />
        <v-text-field
          v-model="city"
          :rules="getFieldRules({ required: true })"
          :label="$t('screens.newUserForm.cityLabel')"
          :placeholder="$t('screens.newUserForm.cityPlaceholder')"
          required
        />
        <v-text-field
          v-model="birthDate"
          :rules="getFieldRules({ required: true, type: 'birthDate' })"
          :label="$t('screens.newUserForm.birthdayLabel')"
          :placeholder="$t('screens.newUserForm.birthdayPlaceholder')"
          required
        />
      </v-form>
      <Button
        dark
        class="mt-auto"
        :disabled="saving"
        :loading="saving"
        @click="updateUserInfo"
      >
        {{ $t('screens.newUserForm.ctaLabel') }}
      </Button>
    </LayoutCard>
  </div>
</template>

<script>
import NavHeader from '@/components/Navigation/NavHeader.vue';
import LayoutCard from '@/components/LayoutCard.vue';
import Button from '@/components/Button.vue';
import { PhoneEuropeRegExp } from '../../utils/constants';

export default {
  components: {
    NavHeader,
    LayoutCard,
    Button,
  },
  data() {
    return {
      saving: false,
      valid: true,
      name: '',
      phoneNumber: '',
      city: '',
      birthDate: '',
    };
  },
  computed: {
    session() {
      return this.$store.state.session;
    },
    restaurantName() {
      return this.$store.getters.restaurant.name;
    },
    user() {
      return this.$store.getters.user;
    },
    userAlreadyEnrolled() {
      return this.$store.getters.userHasRestaurantLoyaltyProgram;
    },
    isLoadingUser() {
      return this.$store.state.isLoadingUser;
    },
    userHasWalletDetailsFilled() {
      return this.$store.getters.userHasWalletDetailsFilled;
    },
  },
  watch: {
    user() {
      this.handleNavigation();
    },
  },
  async mounted() {
    const sessionToken = this.$route.params.session;
    await this.$store.dispatch('fetchSessionData', {session: sessionToken});

    this.handleNavigation();
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    goBack() {
      this.$router.go(-1);
    },
    getFieldRules(field) {
      const rules = [];
      const internationalPhoneRegEx = PhoneEuropeRegExp();
      const birthDateRegExp = /^\d{4}-\d{2}-\d{2}$/;
      if (field.required) {
        rules.push(v => !!v || this.$t('generic.requiredField'));
      }
      if (field.required && field.type === 'tel') {
        rules.push(
          v =>
            internationalPhoneRegEx.test(v) || this.$t('generic.invalidPhone')
        );
      }
      if (field.required && field.type === 'birthDate') {
        rules.push(
          v => birthDateRegExp.test(v) || this.$t('generic.invalidBirthDate')
        );
      }

      return rules;
    },
    async updateUserInfo() {
      await this.validate();

      this.saving = true;

      if (!this.valid) {
        this.saving = false;
        return;
      }

      try {
        await this.$store.dispatch('updateUserInfo', {
          name: this.name,
          phone_number: this.phoneNumber,
          city: this.city,
          birth_date: this.birthDate,
        });
        this.goToEnrollLoyalty();
      } catch (error) {
        this.$store.dispatch('showError', error);
        this.saving = false;
      }

      this.saving = false;
    },
    goToEnrollLoyalty() {
      this.$router.push({
        name: 'EnrollLoyalty',
        params: {
          session: this.session,
          loyaltyProgramId: this.$route.params.loyaltyProgramId,
        },
      });
    },
    goToPlaceOrder() {
      this.$router.push({
        name: 'PlaceOrder',
        params: { session: this.session },
      });
    },
    handleNavigation() {
      if (this.userHasWalletDetailsFilled && !this.userAlreadyEnrolled) {
        this.goToEnrollLoyalty();
      } else if (this.userAlreadyEnrolled) {
        this.goToPlaceOrder();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.details-container {
  display: flex;
  flex-flow: column;
  min-height: 100svh;
  height: 100%;
}
</style>
