<template>
  <div
    v-if="description"
    class="d-flex justify-space-between align-center tp-text-body-gray"
  >
    <div>
      <div class="restaurant-description" v-html="$t(description)"></div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    description() {
      return this.$store.state.restaurant.description || false;
    },
  },
};
</script>

<style lang="scss">
.restaurant-description {
  font-family: var(--menu-font-family) !important;
  font-size: var(--menu-restaurant-description-font-size) !important;
  font-weight: var(--menu-restaurant-description-font-weight) !important;
  color: var(--menu-restaurant-description-font-color) !important;
}
</style>
