<template>
  <v-overlay v-if="visible" absolute z-index="0" opacity="0.4">
    <div class="content text-center">
      <span class="tp-text-subtitle-medium white--text">
        {{ $t('components.approveDrinkingAge.title') }}
      </span>
      <Button
        data-cy="age-restriction-cta"
        x-small
        dark
        @click="approveDrinkingAge"
      >
        {{ $t('components.approveDrinkingAge.cta') }}
      </Button>
    </div>
  </v-overlay>
</template>

<script>
import Button from '@/components/Button.vue';

export default {
  components: {
    Button,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    approveDrinkingAge() {
      this.$store.dispatch('setDrinkingAgeApproved', true);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  max-width: 250px !important;
}
</style>
