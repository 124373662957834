export const websocketUrl = localStorage.getItem('posServerIp') || '';

export const OPERATION = {
  SALE: 'sale',
  VOID: 'void',
  REFUND: 'refund',
  SETTLEMENT: 'settlement',
  READ_CARD: 'readCard',
  ABORT: 'abort',
  DIALOG: 'dialog',
  REPORT: 'report',
  PRE_AUTH: 'preAuth',
  COMPLETE: 'complete',
};

export const generateCRN = () => Math.floor(Math.random() * 1000000000);
