import actions from './actions';

const initialState = () => ({
  reviewId: null,
});

export default {
  state: initialState,
  mutations: {
    SET_REVIEW_ID(state, reviewId) {
      state.reviewId = reviewId;
    },
  },
  actions,
  getters: {
    reviewId: state => state.reviewId,
  },
};
