<template>
  <div>
    <div class="carousel">
      <div
        v-for="(waiter, key) in waiters"
        :key="key"
        class="d-flex flex-column align-center item"
        :data-cy="`waiter-${key}`"
        :class="{
          selected: selectedWaiterId === waiter.id,
        }"
        @click="() => onWaiterPick(waiter.id)"
      >
        <v-avatar size="64">
          <img
            :src="getPhotoUrl(waiter)"
            :alt="waiter.name"
            :class="{
              'with-border': selectedWaiterId === waiter.id,
            }"
          />
        </v-avatar>
        <span class="pt-3 tp-text-label black--text">{{ waiter.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { defaultPhotoUrl } from '../../store/modules/user/api';

export default {
  data: () => ({
    selectedWaiterId: null,
  }),
  computed: {
    waiters() {
      return this.$store.getters.personnelList;
    },
  },
  methods: {
    onWaiterPick(id) {
      this.selectedWaiterId = id;
      this.$emit('on-change', id);
    },
    getPhotoUrl(waiter) {
      return waiter.photo || defaultPhotoUrl(waiter.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: scroll;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;

  .item {
    padding: 0 8px;
    cursor: pointer;

    transform-origin: center;
    transition: 0.15s all ease;
    transform: scale(1);
  }

  .item.selected:first-child {
    margin-left: 4px;
  }

  .item.selected:last-child {
    margin-right: 4px;
  }

  .item.selected {
    padding: 0 10px;
    transform-origin: center;
    transform: scale(1.4);
  }

  .with-border {
    border: 3px solid var(--color-primary);
  }

  /* Hide the scrollbar for Internet Explorer, Edge and Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide the scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
