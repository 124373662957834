var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modal',{attrs:{"is-open":_vm.isOpen,"title":_vm.$t('screens.order.notificationsModalTitle'),"subtitle":_vm.notificationsAvailable
      ? _vm.$t('screens.order.notificationsModalSubtitle')
      : _vm.$t('screens.order.notificationsModalSubtitleSMS'),"cta-label":_vm.$t('generic.enable'),"close-label":_vm.$t('generic.notNow'),"cta-disabled":_vm.disabled},on:{"cta-click":_vm.ctaClicked,"close-click":_vm.closeClick}},[_c('template',{slot:"image"},[_c('div',{staticClass:"d-flex justify-center align-center"},[_c('img',{staticClass:"pb-12 pt-4",attrs:{"src":require('@/assets/images/icons/notification-bell.svg')}})])]),(!_vm.notificationsAvailable)?_c('template',{slot:"content"},[_c('div',{staticClass:"phone-input pt-6"},[_c('vue-phone-number-input',{attrs:{"no-flags":"","color":_vm.primaryColor,"default-country-code":_vm.locale,"size":"lg","translations":{
          countrySelectorLabel: _vm.$t(
            'components.notificationModal.countrySelectorLabel'
          ),
          countrySelectorError: _vm.$t(
            'components.notificationModal.countrySelectorError'
          ),
          phoneNumberLabel: _vm.$t(
            'components.notificationModal.phoneNumberLabel'
          ),
          example: _vm.$t('components.notificationModal.example'),
        },"no-example":""},on:{"update":_vm.onNumberChange},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),_c('div',{staticClass:"tp-text-label black--text mt-4"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('screens.order.notificationsModalBodySMS'))+" ")])])],1)]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }