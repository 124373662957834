const initialState = {
  shownPopupIds: [],
};

export default {
  state: initialState,
  mutations: {
    markPopupShow(state, popupId) {
      state.shownPopupIds = [...state.shownPopupIds, popupId];
    },
    setShownPopups(state, shownPopupIds) {
      state.shownPopupIds = shownPopupIds;
    },
  },
  actions: {
    async setPopupShown({ commit, getters }, popupId) {
      const shownPopups = getters.shownPopups;
      commit('markPopupShow', popupId);
      localStorage.setItem(
        'shownPopupIds',
        JSON.stringify([...shownPopups, popupId])
      );
    },
    checkPopupsInLocalStorage({ commit }) {
      const localIds = JSON.parse(localStorage.getItem('shownPopupIds')) || [];
      commit('setShownPopups', localIds);
    },
  },
  getters: {
    showPopup: state => popupId => !state.shownPopupIds.includes(popupId),
    shownPopups: state => state.shownPopupIds,
  },
};
