<template>
  <div class="d-flex flex-column text-center justify-center align-center">
    <img v-if="image" :src="image" width="200px" alt="state image"/>
    <div class="tp-text-title-bold mx-4 mt-8">{{ title }}</div>
    <div v-if="subtitle" class="ma-4 tp-text-body">
      {{ subtitle }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: null,
      required: false,
    },
    image: {
      type: String,
      default: null,
      required: false,
    },
  },
};
</script>

<style>
</style>