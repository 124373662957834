import Vue from 'vue';

const ASB_NO_RESPONSE = 0x00000001;
const ASB_DRAWER_KICK = 0x00000004;
const ASB_OFF_LINE = 0x00000008;
const ASB_COVER_OPEN = 0x00000020;
const ASB_PAPER_FEED = 0x00000040;
const ASB_WAIT_ON_LINE = 0x00000100;
const ASB_PANEL_SWITCH = 0x00000200;
const ASB_MECHANICAL_ERR = 0x00000400;
const ASB_AUTOCUTTER_ERR = 0x00000800;
const ASB_UNRECOVER_ERR = 0x00002000;
const ASB_AUTORECOVER_ERR = 0x00004000;
const ASB_RECEIPT_NEAR_END = 0x00020000;
const ASB_RECEIPT_END = 0x00080000;
const ASB_WAIT_REMOVE_LABEL = 0x01000000; // or BUZZER
const ASB_REMOVAL_DETECT_PAPER_NONE = 0x04000000;
const ASB_REMOVAL_DETECT_UNKNOWN = 0x08000000;
const ASB_SPOOLER_IS_STOPPED = 0x80000000;

function getStatusText(status) {
  var s = 'Status: \n';
  if (status & ASB_NO_RESPONSE) {
    s += ' No printer response\n';
  }
  if (status & ASB_DRAWER_KICK) {
    s += ' Status of the drawer kick number 3 connector pin = "H"\n';
  }
  if (status & ASB_OFF_LINE) {
    s += ' Offline status\n';
  }
  if (status & ASB_COVER_OPEN) {
    s += ' Cover is open\n';
  }
  if (status & ASB_PAPER_FEED) {
    s += ' Paper feed switch is feeding paper\n';
  }
  if (status & ASB_WAIT_ON_LINE) {
    s += ' Waiting for online recovery\n';
  }
  if (status & ASB_PANEL_SWITCH) {
    s += ' Panel switch is ON\n';
  }
  if (status & ASB_MECHANICAL_ERR) {
    s += ' Mechanical error generated\n';
  }
  if (status & ASB_AUTOCUTTER_ERR) {
    s += ' Auto cutter error generated\n';
  }
  if (status & ASB_UNRECOVER_ERR) {
    s += ' Unrecoverable error generated\n';
  }
  if (status & ASB_AUTORECOVER_ERR) {
    s += ' Auto recovery error generated\n';
  }
  if (status & ASB_RECEIPT_NEAR_END) {
    s += ' No paper in the roll paper near end detector\n';
  }
  if (status & ASB_RECEIPT_END) {
    s += ' No paper in the roll paper end detector\n';
  }
  if (status & ASB_WAIT_REMOVE_LABEL) {
    s +=
      ' Sounding the buzzer (certain model) or printer waits for removing paper (certain model)\n';
  }
  if (status & ASB_REMOVAL_DETECT_UNKNOWN) {
    s +=
      ' Paper taken sensor not working (certain model). Please check if not ambient light reaches paper outlet and if paper taken sensor status in the printer is enable if you need to use paper taken sensor status.\n';
  } else {
    if (status & ASB_REMOVAL_DETECT_PAPER_NONE) {
      s += ' Paper taken sensor do not detect paper (certain model)\n';
    } else {
      s +=
        ' Paper taken sensor detects paper (certain model). Please take the receipt\n';
    }
  }
  if (status & ASB_SPOOLER_IS_STOPPED) {
    s += ' Stop the spooler\n';
  }
  return s;
}

export default {
  state: {
    printerInstance: null,
    online: false,
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! TODO - remove hardcoded IP address and port !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    ipAddress: localStorage.getItem('printerIpAddress') || '192.168.1.189',
    port: localStorage.getItem('printerPort') || '8008',
    isKiosk: localStorage.getItem('isKiosk') === 'true',
  },
  mutations: {
    setPrinterInstance(state, printerInstance) {
      state.printerInstance = printerInstance;
    },
    setPrinterOnline(state, online) {
      if (!online) {
        state.printerInstance = null;
      }
      state.online = online;
    },
  },
  actions: {
    connectToPOSPrinter({ commit, dispatch, getters }) {
      const ipAddress = getters.printerIP;
      const port = getters.printerPort;

      const instance = getters.printerInstance;
      const online = getters.printerIsOnline;

      if (!ipAddress || !port) {
        console.log('Printer: No IP address or port provided');
        return;
      }

      if (instance && online) {
        console.log('Printer: already connected');
        return;
      }

      const onConnectToPOSPrinter = data => {
        let instance = getters.printerInstance;
        const createDevice = (data, code) =>
          dispatch('createDevice', { data, code });

        if (data === 'OK') {
          instance.createDevice(
            'local_printer',
            instance.DEVICE_TYPE_PRINTER,
            { crypto: true, buffer: false },
            createDevice
          );

          commit('setPrinterInstance', instance);
          commit('setPrinterOnline', true);
        } else {
          console.log('Printer: failed to connect to printer');
          commit('setPrinterInstance', null);
          commit('setPrinterOnline', false);
          return false;
        }
      };

      let ePosDev = new window.epson.ePOSDevice();
      ePosDev.connect(ipAddress, port, onConnectToPOSPrinter);
      ePosDev.onreconnecting = () => dispatch('onReconnectingToPOSPrinter');
      ePosDev.ondisconnect = () => dispatch('onDisconnectFromPOSPrinter');
      ePosDev.onreconnect = () => dispatch('onReconnectToPOSPrinter');

      commit('setPrinterInstance', ePosDev);
    },
    onReconnectToPOSPrinter({ commit }) {
      console.log('Printer: Reconnected');
      commit('setPrinterOnline', false);
    },
    onDisconnectFromPOSPrinter({ commit }) {
      console.log('Printer: Disconnected');
      commit('setPrinterOnline', false);
    },
    onReconnectingToPOSPrinter() {
      console.log('Printer: Reconnecting...');
    },
    createDevice({ commit, dispatch }, { data, code }) {
      if (data == null) {
        console.log('Printer: Failed to create device', code);
        commit('setPrinterOnline', false);
        return;
      }
      console.log('Printer: ready to print');

      let printer = data;

      printer.onreceive = res => {
        console.log(
          'Print' +
            (res.success ? 'Success' : 'Failure') +
            '\nCode:' +
            res.code +
            '\nBattery:' +
            res.battery +
            '\n' +
            res.status
        );
      };

      printer.onstatuschange = status => {
        console.log(getStatusText(status));
      };
      printer.ononline = () => {
        commit('setPrinterOnline', true);
        dispatch('closeToast');
      };
      printer.onoffline = () => {
        commit('setPrinterOnline', false);
      };
      printer.onpoweroff = () => {
        commit('setPrinterOnline', false);
      };
      printer.oncoverok = () => {
        commit('setPrinterOnline', true);
        dispatch('closeToast');
      };
      printer.oncoveropen = () => {
        commit('setPrinterOnline', false);
        dispatch('showToastInstantly', {
          title: Vue.i18n.translate('kiosk.toasts.printerCoverOpen.title'),
          description: Vue.i18n.translate(
            'kiosk.toasts.printerCoverOpen.description'
          ),
          isError: true,
          doNotClose: true,
        });
      };
      printer.onpaperok = () => {
        commit('setPrinterOnline', true);
        dispatch('closeToast');
      };
      printer.onpapernearend = () => {
        commit('setPrinterOnline', false);
        dispatch('showToastInstantly', {
          title: Vue.i18n.translate(
            'kiosk.toasts.printerAlmostOutOfPaper.title'
          ),
          description: Vue.i18n.translate(
            'kiosk.toasts.printerAlmostOutOfPaper.description'
          ),
          isError: true,
          doNotClose: true,
        });
      };
      printer.onpaperend = () => {
        commit('setPrinterOnline', false);
        dispatch('showToastInstantly', {
          title: Vue.i18n.translate('kiosk.toasts.printerOutOfPaper.title'),
          description: Vue.i18n.translate(
            'kiosk.toasts.printerOutOfPaper.description'
          ),
          isError: true,
          doNotClose: true,
        });
      };

      printer.interval = 1000;
      printer.startMonitor();

      commit('setPrinterInstance', printer);
      commit('setPrinterOnline', true);
    },
    printReceipt(
      { getters },
      {
        text,
        withLogo = true,
        orderId,
        CRN,
        onSuccess,
        onError,
        additionalFields = [],
        QRLink,
      } = {}
    ) {
      const instance = getters.printerInstance;
      const online = getters.printerIsOnline;

      // Image printing WIP (not working yet)
      // const canvas = document.createElement('canvas');
      // const body = document.getElementsByTagName('body')[0];

      // const ctx = canvas.getContext('2d');
      // const img = new Image();
      // img.src = require('@/assets/greet.svg');

      // canvas.setAttribute('display', 'none');
      // body.appendChild(canvas);

      // await new Promise(
      //   resolve =>
      //     (img.onload = () => {
      //       ctx.drawImage(img, 0, 0);
      //       resolve();
      //     })
      // );

      try {
        if (instance && online) {
          instance.addTextSize(1, 1);
          instance.addTextSmooth(true);
          if (withLogo) {
            instance.addTextAlign(instance.ALIGN_CENTER);
            instance.addLogo(32, 32, '');
            instance.addTextAlign(instance.ALIGN_LEFT);
          }
          if (orderId) {
            instance.addFeedLine(3);
            instance.addTextAlign(instance.ALIGN_CENTER);
            instance.addText(Vue.i18n.translate('kiosk.printer.yourOrder'));
            instance.addFeedLine(2);
            instance.addTextSize(4, 4);
            instance.addText(`#${orderId}`);
            instance.addTextSize(1, 1);
          }
          if (additionalFields.length) {
            instance.addFeedLine(1);
            instance.addTextAlign(instance.ALIGN_CENTER);
            instance.addFeedLine(1);
            instance.addText(
              Vue.i18n.translate('kiosk.printer.additionalFields')
            );
            instance.addFeedLine(1);
            instance.addTextAlign(instance.ALIGN_LEFT);
            additionalFields.forEach(field => {
              instance.addFeedLine(1);
              instance.addText(`${field.placeholder}: ${field.value}`);
            });
          }
          instance.addTextAlign(instance.ALIGN_LEFT);
          instance.addFeedLine(4);
          instance.addText(text);
          instance.addFeedLine(1);
          if (QRLink) {
            instance.addTextAlign(instance.ALIGN_CENTER);
            instance.addSymbol(
              QRLink,
              instance.SYMBOL_QRCODE_MODEL_2,
              instance.LEVEL_DEFAULT,
              8,
              5,
              0
            );
            instance.addFeedLine(1);
            instance.addText(Vue.i18n.translate('kiosk.printer.scanQR'));
            instance.addFeedLine(3);
            instance.addTextAlign(instance.ALIGN_LEFT);
          }
          instance.addCut();
          instance.onreceive = res => {
            if (onSuccess) {
              onSuccess(res);
            }
          };
          instance.onerror = function() {
            if (onError) {
              onError();
            }
          };
          instance.send(CRN);
        } else {
          console.log('Printer: is offline');
          if (onError) {
            onError();
          }
        }
      } catch (error) {
        console.error(error);
        if (onError) {
          onError();
        }
      }
    },
  },
  getters: {
    printerInstance: state => state.printerInstance,
    printerIsOnline: state => state.online && !!state.printerInstance,
    printerIP: state => state.ipAddress,
    printerPort: state => state.port,
    isKiosk: state => state.isKiosk,
  },
};
