var render = function render(){var _vm=this,_c=_vm._self._c;return _c('img',{directives:[{name:"intersect",rawName:"v-intersect",value:({
    handler: _vm.onIntersect,
    options: {
      threshold: 1,
      rootMargin: '-50px 0px 100% 0px',
    },
  }),expression:"{\n    handler: onIntersect,\n    options: {\n      threshold: 1,\n      rootMargin: '-50px 0px 100% 0px',\n    },\n  }"}],staticClass:"lazy-img",class:{ 'lazy-img--loading': !_vm.noBlur },attrs:{"src":!_vm.loaded ? _vm.lazyImg : _vm.img}})
}
var staticRenderFns = []

export { render, staticRenderFns }