<template>
  <div class="top-button-container">
    <v-btn
      v-if="showPaymentHistoryButton"
      text
      elevation="0"
      rounded
      class="history-icon"
      data-cy="payment-history-button"
      @click="navigateToPaymentHistory"
    >
      <v-icon left color="black">
        mdi-history
      </v-icon>
      <div class="tp-text-body-2 tp-normal-spacing">
        {{ $t('screens.selectScreen.paymentsCta') }}
      </div>
    </v-btn>
    <v-btn
      v-if="$store.getters.restaurantHasLanguages"
      fab
      small
      icon
      class="language-icon"
      @click="toggleLanguageSelector"
    >
      <img :src="getLanguageImageByCode($i18n.locale())" style="width: 22px" />
    </v-btn>
  </div>
</template>

<script>
export default {
  computed: {
    showPaymentHistoryButton() {
      return this.$store.getters.functionalityPayGoEnabled;
    },
  },
  methods: {
    getLanguageImageByCode: function(code) {
      return require(`@/assets/images/flags/${code}.svg`);
    },
    toggleLanguageSelector: function() {
      this.$store.dispatch('openLanguageSelector');
      this.$store.dispatch('sendAnalyticsEvent', {
        event: 'language_selector_opened',
      });
    },
    navigateToPaymentHistory: function() {
      this.$router.push({ name: 'PaymentHistory' });
    },
  },
};
</script>

<style lang="scss" scoped>
.top-button-container {
  z-index: 150 !important;
  position: fixed;
  top: 0;
  right: 0;
  margin: 10px;
  display: flex;
  gap: 10px;
}

.language-icon {
  background: rgba(255, 255, 255, 0.85) !important;
  box-shadow: none !important;
  width: 36px !important;
  height: 36px !important;
}

.history-icon {
  background: rgba(255, 255, 255, 0.85) !important;
  box-shadow: none !important;
  height: 36px !important;
}
</style>
