<template>
  <v-container class="pa-0 mb-8 payment-container">
    <div v-if="loading" class="d-flex justify-center pt-8">
      <v-progress-circular indeterminate />
    </div>
    <div v-else>
      <div class="px-4 py-6">
        <h2>{{ $t('kiosk.terminalCommands.title') }}</h2>
      </div>
      <LayoutCard :title="$t('kiosk.terminalCommands.x.title')" rounded>
        <p>
          {{ $t('kiosk.terminalCommands.x.description') }}
        </p>
        <Button
          dark
          :disabled="reportDisabled"
          :loading="loadingReport"
          @click="report"
        >
          {{ $t('kiosk.terminalCommands.x.cta') }}
          <v-icon right color="white">mdi-playlist-check</v-icon>
        </Button>
      </LayoutCard>
      <LayoutCard :title="$t('kiosk.terminalCommands.z.title')" rounded>
        <p>
          {{ $t('kiosk.terminalCommands.z.description') }}
        </p>
        <Button
          dark
          :disabled="settleDisabled"
          :loading="loadingSettle"
          @click="settle"
        >
          {{ $t('kiosk.terminalCommands.z.cta') }}
          <v-icon right color="white">mdi-bank</v-icon>
        </Button>
      </LayoutCard>
      <LayoutCard
        :title="
          $t('kiosk.terminalCommands.unresolvedCRNs.cardTitle', {
            count: unresolvedCRNs.length,
          })
        "
        rounded
      >
        <p>
          {{ $t('kiosk.terminalCommands.unresolvedCRNs.cardDescription') }}
        </p>
        <Button
          dark
          :disabled="voidDisabled"
          :loading="loadingVoid"
          @click="voidUnresolvedPayments"
        >
          {{ $t('kiosk.terminalCommands.unresolvedCRNs.cta') }}
          <v-icon right color="white">mdi-transfer</v-icon>
        </Button>
      </LayoutCard>
      <LayoutCard
        :title="$t('kiosk.terminalCommands.connections.title')"
        rounded
      >
        <div class="d-flex mb-2 justify-space-between">
          {{ $t('kiosk.terminalCommands.connections.cardReaderLabel') }}
          <v-chip
            :color="terminalOnline ? 'green' : 'red'"
            text-color="white"
            @click="reconnectToCardReader"
          >
            {{ terminalOnline ? $t('generic.ok') : $t('generic.unavailable') }}
          </v-chip>
        </div>
        <div class="d-flex mb-2 justify-space-between">
          {{ $t('kiosk.terminalCommands.connections.printerLabel') }}
          <v-chip
            :color="printerOnline ? 'green' : 'red'"
            text-color="white"
            @click="tryToConnectToPrinter"
          >
            {{ printerOnline ? $t('generic.ok') : $t('generic.unavailable') }}
          </v-chip>
        </div>
        <div class="d-flex justify-space-between">
          {{ $t('kiosk.terminalCommands.connections.testPrinterLabel') }}
          <v-chip
            dark
            :disabled="!printerOnline"
            text-color="white"
            @click="testPrinter"
          >
            {{ $t('kiosk.terminalCommands.connections.print') }}
          </v-chip>
        </div>
      </LayoutCard>
    </div>
    <v-dialog v-model="dialog.open" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ dialog.title }}
        </v-card-title>

        <v-card-text class="pt-5" style="white-space: pre-line;">
          {{ dialog.text }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog.open = false">
            {{ $t('generic.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Button from '@/components/Button.vue';
import LayoutCard from '@/components/LayoutCard.vue';
import { OPERATION, generateCRN } from '@/utils/worldLine';

export default {
  components: {
    Button,
    LayoutCard,
  },
  data() {
    return {
      loading: true,
      loadingReport: false,
      loadingSettle: false,
      loadingVoid: false,
      paymentIntent: {},
      dialogText: null,
      dialog: {
        open: false,
        text: '',
        title: '',
      },
      settlementCRN: null,
      printerInterval: null,
    };
  },
  computed: {
    printerOnline() {
      return this.$store.getters.printerIsOnline;
    },
    terminalOnline() {
      return this.$store.getters.terminalOnline;
    },
    settleDisabled() {
      return (
        !this.terminalOnline ||
        this.loadingSettle ||
        !this.printerOnline ||
        this.loadingReport
      );
    },
    reportDisabled() {
      return (
        !this.terminalOnline ||
        this.loadingReport ||
        !this.printerOnline ||
        this.loadingSettle
      );
    },
    voidDisabled() {
      return (
        !this.terminalOnline ||
        this.loadingVoid ||
        !this.printerOnline ||
        this.loadingReport ||
        this.loadingSettle
      );
    },
    unresolvedCRNs() {
      return this.$store.getters.unresolvedCRNs || [];
    },
  },
  async mounted() {
    const sessionToken = this.$route.params.session;
    await this.$store.dispatch('fetchSessionData', { session: sessionToken });
    await this.$store.dispatch('subscribeToPosTerminal');
    this.setTerminalMessageCallback();
    this.loading = false;
  },
  methods: {
    settle() {
      const CRN = this.settlementCRN || generateCRN();
      const ecrNameVersion = 'greetKiosk ver 1.0';

      this.loadingSettle = true;
      this.settlementCRN = CRN;

      const data = {
        operation: OPERATION.SETTLEMENT,
        ecrNameVersion,
        CRN,
        dlgDisabled: false,
        receiptLineLength: 40,
      };
      this.$store.dispatch('sendPosTerminalMessage', { data });
    },
    report() {
      this.loadingReport = true;
      const data = {
        operation: OPERATION.REPORT,
        CRN: generateCRN(),
      };
      this.$store.dispatch('sendPosTerminalMessage', { data });
    },
    async voidUnresolvedPayments() {
      this.loadingVoid = true;
      const onVoided = () => {
        this.loadingVoid = false;
      };
      await this.$store.dispatch('voidUnresolvedCRNs', { onVoided });
    },
    setTerminalMessageCallback() {
      const onmessage = async message => {
        const data = JSON.parse(message.data);
        if (data.operation === OPERATION.SETTLEMENT && data.result) {
          const onPrintSuccess = () => {
            this.dialog.title = this.$t('kiosk.terminalCommands.z.title');
            this.dialog.text = data.merchantReceipt;
            this.dialog.open = true;
            this.loadingSettle = false;

            this.$store.dispatch('saveSettlement', {
              data,
            });

            // IMPORTANT to reset the CRN after a successful settlement
            this.settlementCRN = null;
          };
          const onPrinterError = () => {
            this.dialog.title = this.$t('kiosk.terminalCommands.z.title');
            this.dialog.text = this.$t('kiosk.terminalCommands.printerError');
            this.dialog.open = true;
            this.loadingSettle = false;
          };

          this.printReceipt(
            data.merchantReceipt,
            onPrintSuccess,
            onPrinterError
          );

          return;
        } else if (data.operation === OPERATION.REPORT && data.result) {
          const onSuccessfulReport = () => {
            this.dialog.title = this.$t('kiosk.terminalCommands.x.title');
            this.dialog.text = data.merchantReceipt;
            this.dialog.open = true;
            this.loadingReport = false;
          };

          const onFailedReport = () => {
            this.dialog.title = this.$t('kiosk.terminalCommands.x.title');
            this.dialog.text = this.$t('kiosk.terminalCommands.printerError');
            this.dialog.open = true;
            this.loadingReport = false;
          };

          this.printReceipt(
            data.merchantReceipt,
            onSuccessfulReport,
            onFailedReport
          );

          return;
        } else if (data.operation === OPERATION.VOID && data.result) {
          this.printReceipt(data.cardholderReceipt);

          await this.$store.dispatch('updateInPersonPaymentIntent', {
            type: 'payment_voided',
            data,
          });

          this.$store.dispatch('removeUnresolvedCRN', { CRN: data.CRN });

          return;
        } else if (
          data.operation === OPERATION.VOID &&
          !data.result &&
          data.responseCode === 'NF'
        ) {
          console.error('TERMINAL: CRN was not found, nothing to auto-void.');
          this.$store.dispatch('removeUnresolvedCRN', { CRN: data.CRN });
        } else if (!data.result && data.responseCode) {
          this.dialog.title = `Error (${data.responseCode})`;
          this.dialog.text = data.responseText;
          this.dialog.open = true;

          this.loadingReport = false;
          this.loadingSettle = false;

          return;
        }
      };
      this.$store.dispatch('setOnMessageCallback', { callback: onmessage });
    },
    tryToConnectToPrinter() {
      this.$store.dispatch('connectToPOSPrinter');
    },
    async testPrinter() {
      const additionalFields = [
        {
          name: 'Vardas',
          placeholder: 'Jūsų vardas',
          value: 'Vardenis',
        },
        {
          name: 'Vieta',
          placeholder: 'Pasirinkite vietą',
          value: 'Išsinešimui',
        },
      ];
      const text =
        'Greet menu\n   J. Basanavičiaus g. 41-6, LT-03109 Vilnius\n           TID=9999928; MID=TST99928\n          BEKONTAKČIO MOKĖJIMO KVITAS\n                   PARDAVIMAS\nTERMINALO NR.                           TST99928\nPREKYBININKO NR.                         9999928\nLAIKAS                       2024-08-01 17:46:47\nBYLA 240801/234                   KVITO NR. 2414\nVISA                            Visa Contactless\n************0605\nA0000000031010                       Visa Credit\nRRN 421417002414\nBaltic POS Tetra 1.0.001 (0037)\nSuma                                    6.90 EUR\nAutorizacijos kodas 066071                   T:1\n\n    Save receipt to check account statement';
      await this.$store.dispatch('printReceipt', {
        text,
        withLogo: true,
        orderId: '123456',
        QRLink: 'https://greet.menu',
        additionalFields
      });
    },
    async reconnectToCardReader() {
      await this.$store.dispatch('subscribeToPosTerminal');
      this.setTerminalMessageCallback();
    },
    async printReceipt(text, onSuccess, onError) {
      await this.$store.dispatch('printReceipt', {
        text,
        withLogo: false,
        onSuccess,
        onError,
      });
    },
  },
};
</script>
