<template>
  <div class="order-in-list" @click="click">
    <div class="order-in-list__info-wrapper mb-3">
      <div>
        <div
          v-if="cover"
          class="order-in-list-info__image"
          :style="{ backgroundImage: `url(${cover})` }"
        ></div>
        <div class="order-in-list-info__text">
          <div class="order-in-list__order-id tp-text-subtitle-semibold mb-3">
            {{ $t('screens.order.orderListTitle') }} #{{ order_id }}
          </div>
          <div class="order-in-list__restaurant tp-text-body-2 mb-2">
            {{ restaurantName }}
          </div>
          <div class="order-in-list__total tp-text-body-2">
            {{ orderTotalFormatted }}
          </div>
        </div>
      </div>

      <img :src="require('@/assets/images/icons/right-arrow.svg')" />
    </div>
    <div class="order-in-list__date-status-wrapper tp-text-body">
      <span class="order-in-list-date-status-wrapper__date">
        {{ getOrderDateTime }}
      </span>
      <span class="order-in-list-date-status-wrapper__status">
        {{ getOrderStatusString }}
      </span>
    </div>
  </div>
</template>

<script>
import formatPriceWithCurrency from '@/helpers/formatPriceWithCurrency.js';

export default {
  props: {
    order_id: [String, Number],
    unique_order_number: String,
    status: String,
    comment: String,
    items: Array,
    subtotal: Number,
    transaction_fee: Number,
    total: Number,
    is_paid: Boolean,
    payments_sum: Number,
    payment_data: [Array, Object],
    seen: Boolean,
    wait_minutes: Number,
    placed_at: String,
    accepted_at: String,
    created_at: String,
    currencyCode: String,
    localeCode: String,
    cover: String,
    restaurantName: String,
    session_token: String,
  },
  computed: {
    orderTotalFormatted() {
      return formatPriceWithCurrency(
        this.subtotal,
        this.currencyCode,
        this.localeCode
      );
    },
    getOrderDateTime() {
      const date = new Date(this.placed_at);
      return (
        date.toLocaleTimeString(this.localeCode, {
          hour: 'numeric',
          minute: 'numeric',
          hour12: false,
        }) +
        ', ' +
        date.toLocaleDateString(this.localeCode, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
      );
    },
    getOrderStatusString() {
      if (this.$store.getters.orderFlowIsWithoutStatusChanges) {
        if (this.status === 'failed') {
          return this.$t('screens.orders.statusFailed');
        } else if (this.status === 'rejected') {
          return this.$t('screens.orders.statusRejected');
        } else {
          return this.$t('screens.orders.statusClosed');
        }
      } else {
        if (this.status === 'closed') {
          return this.$t('screens.orders.statusClosed');
        }
        if (this.status === 'waiting_confirmation') {
          return this.$t('screens.orders.statusWaitingConfirmation');
        }
        if (this.status === 'failed') {
          return this.$t('screens.orders.statusFailed');
        }
        if (this.status === 'rejected') {
          return this.$t('screens.orders.statusRejected');
        }

        return this.$t('screens.orders.statusPreparing');
      }
    },
  },
  methods: {
    click() {
      const uniqueOrderNumber = this.unique_order_number;
      const session = this.session_token;

      this.$emit('click', { uniqueOrderNumber, session });
    },
  },
};
</script>

<style lang="scss" scoped>
.order-in-list:not(:last-child) {
  margin-bottom: 24px;
}

.order-in-list {
  &__restaurant,
  &__total {
    color: $color-black;
  }
  &__info-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      display: flex;
      gap: 10px;
      flex: 1;
    }
    > img {
      flex: 0;
    }
  }
}

.order-in-list-info {
  &__image {
    border-radius: 5px;
    width: 35%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  &__text {
    max-width: 60%;
    &--no-cover {
      max-width: 100%;
    }
  }
}
</style>
