<template>
  <div class="payment-information container-32">
    <div
      v-if="showTransactionFee"
      class="payment-information__transaction-fee mb-1"
    >
      <div class="tp-text-body">
        {{ $t('screens.order.transactionFee') }}
      </div>
      <div class="tp-text-body">
        {{ transactionFeeFormatted }}
      </div>
    </div>
    <div
      v-if="discountAmount || payAtRestaurant"
      class="d-flex justify-space-between pb-2"
    >
      <span class="tp-text-body">
        <v-icon color="primary">mdi-tag-outline</v-icon>
        {{ discountCode }}
      </span>
      <span class="tp-text-body">
        <span v-if="payAtRestaurant">{{
          $t('screens.payment.payAtRestaurantCouponText')
        }}</span>
        <span v-else>-{{ discountAmountFormatted }}</span>
      </span>
    </div>
    <div v-if="smallOrderFee" class="d-flex justify-space-between pt-3">
      <span class="tp-text-body">
        {{ $t('screens.order.smallOrderFee') }}
      </span>
      <span class="tp-text-body"> {{ smallOrderFeeFormatted }} </span>
    </div>
    <div v-if="showTips" class="payment-information__tips mb-1">
      <div class="tp-text-body">
        {{ $t('screens.order.tips') }}
      </div>
      <div class="tp-text-body">
        {{ tipsFormatted }}
      </div>
    </div>
    <div class="payment-information__total">
      <div class="tp-text-subtitle">
        {{
          getLeftToPayAmountFormatted !== false
            ? $t('screens.order.total')
            : $t('screens.order.totalPaid')
        }}
      </div>
      <div class="tp-text-subtitle">
        {{ orderTotalFormatted }}
      </div>
    </div>

    <div v-if="showPaymentType" class="payment-information__payment-type mt-3">
      <div class="tp-text-body">
        {{ $t('screens.order.paymentType') }}
      </div>
      <div class="payment-information-payment-type__card-wrapper">
        <div
          v-if="getPaymentLastCardDigits"
          class="payment-information-payment-type__last-digits"
        >
          <img :src="require(`@/assets/images/icons/hidden-card-dots.svg`)" />
          <span>{{ getPaymentLastCardDigits }}</span>
        </div>
        <img
          class="payment-information-payment-type__image"
          :src="require(`@/assets/images/payments/${getPaymentIconName}`)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showTransactionFee: Boolean,
    showTips: Boolean,
    showPaymentType: Boolean,
    transactionFee: Number,
    tips: Number,
    total: Number,
    subtotal: Number,
    paymentsSum: Number,
    paymentData: [Object, Array],
    discountCode: String,
    discountAmount: Number,
    payAtRestaurant: Boolean,
    smallOrderFee: {
      type: Number,
      default: null,
    },
  },
  computed: {
    transactionFeeFormatted() {
      return this.$store.getters.getFormattedPrice(this.transactionFee);
    },
    tipsFormatted() {
      return this.$store.getters.getFormattedPrice(this.tips);
    },
    discountFormatted() {
      return this.$store.getters.getFormattedPrice(this.tips);
    },
    orderTotalFormatted() {
      return this.$store.getters.getFormattedPrice(this.total);
    },
    discountAmountFormatted() {
      return this.$store.getters.getFormattedPrice(this.discountAmount);
    },
    getPaymentIconName() {
      if (this.paymentData.wallet) {
        if (this.paymentData.wallet === 'apple_pay') {
          return 'apple-pay.svg';
        } else if (this.paymentData.wallet === 'google_pay') {
          return 'google-pay.svg';
        }
      } else if (this.paymentData.bank) {
        if (['Swedbank','Swedbank Lithuania'].includes(this.paymentData.bank)) {
           return 'swedbank-bank.png';
        } else if (['Luminor','Luminor Lithuania'].includes(this.paymentData.bank)) {
           return 'luminor-bank.png';
        } else if (this.paymentData.bank === 'Šiaulių bankas') {
          return 'siauliu-bank.png';
        } else if (this.paymentData.bank === 'Medicinos bankas') {
           return 'medicinos-bank.png';
        } else if (['SEB','SEB Lithuania'].includes(this.paymentData.bank)) {
           return 'seb-bank.png';
        } else if (['Citadele','Citadele Lithuania'].includes(this.paymentData.bank)) {
           return 'citadele-bank.png';
        } else if (['Revolut','Revolut Lithuania'].includes(this.paymentData.bank)) {
           return 'revolut-bank.png';
        }
      } else {
        if (this.paymentData.card_brand === 'visa') {
          return 'visa.svg';
        } else if (this.paymentData.card_brand === 'mastercard') {
          return 'mastercard.svg';
        } else if (this.paymentData.card_brand === 'american_express') {
          return 'american-express.svg';
        }
      }

      return 'default.svg';
    },
    getPaymentLastCardDigits() {
      if (this.paymentData.card_last4) {
        return this.paymentData.card_last4;
      }

      return false;
    },
    getLeftToPayAmountFormatted() {
      const leftToPay = this.subtotal - this.paymentsSum;

      if (leftToPay <= 0) {
        return false;
      }

      return this.$store.getters.getFormattedPrice(leftToPay);
    },
    smallOrderFeeFormatted() {
      return this.$store.getters.getFormattedPrice(this.smallOrderFee);
    },
  }
};
</script>

<style lang="scss" scoped>
.payment-information {
  &__total,
  &__total-paid,
  &__transaction-fee,
  &__tips,
  &__payment-type {
    display: flex;
    justify-content: space-between;
  }
}

.payment-information-payment-type {
  &__card-wrapper {
    display: flex;
    gap: 8px;
  }
  &__last-digits {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }
  &__image {
    max-width: 40px;
  }
}
</style>
