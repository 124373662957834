<template>
  <div class="flex justify-center">
    <Modal
      :is-open="modalOpen"
      :cta-label="$t('generic.understood')"
      :title="$t('components.sortableCart.modal.title')"
      :subtitle="$t('components.sortableCart.modal.subtitle')"
      :show-close-button="false"
      @cta-click="closeModal"
    >
      <template slot="image">
        <div class="d-flex justify-center align-center">
          <img
            class="pb-11 pt-4"
            src="https://cdn.greet.menu/sorting-modal-2.png"
            height="230px"
          />
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/common/Modal.vue';

export default {
  components: {
    Modal,
  },
  data() {
    return {
      modalOpen: false,
    };
  },
  methods: {
    closeModal() {
      this.modalOpen = false;
      window.localStorage.setItem('sortingModalSeen', true);
    },
    openModal() {
      const sortingModalSeen = window.localStorage.getItem('sortingModalSeen');

      if (sortingModalSeen) {
        return;
      }

      this.modalOpen = true;
    },
  },
};
</script>
