var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"collapsing-top-bar d-flex flex-column justify-space-between",class:{
    'collapsing-top-bar--menu-background-color':
      _vm.$store.getters.customizationMenuBackgroundColor,
  },style:(_vm.cssProps),attrs:{"id":"menu-collapsing-top-bar"}},[_c('v-container',[_c('h5',{staticClass:"collapsing-top-bar__restaurant-name tp-text-subtitle-bold mt-3 d-flex align-center",class:{
        'collapsing-top-bar__restaurant-name--white':
          _vm.$store.getters.customizationMenuDarkMode,
        'ml-12': _vm.showBack
      }},[(_vm.$store.getters.customizationLogo)?_c('img',{staticClass:"mr-2",staticStyle:{"max-height":"21px"},attrs:{"src":_vm.$store.getters.customizationLogo}}):_vm._e(),_c('span',{staticClass:"name-container",on:{"click":_vm.scrollToTop}},[_vm._v(" "+_vm._s(_vm.restaurantName)+" ")])])]),(_vm.showCategorySlider)?_c('category-slider'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }