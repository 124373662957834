import Vue from 'vue';

export const fetchDonationDetailsRequest = async ({
  hash,
  donationSession,
}) => {
  return await Vue.axios.post(`/donations/get-data/${hash}`, {
    session: donationSession,
  });
};

export const generateStripeDonationPaymentIntentRequest = async ({
  donationSession,
  amount,
}) => {
  return await Vue.axios.post('/payments/stripe/payment-intent-donation', {
    session: donationSession,
    amount,
  });
};

export const generateNeoPayDonationPaymentIntentRequest = async ({
  donationSession,
  amount,
}) => {
  return await Vue.axios.post('/payments/neopay/create-donation-transaction', {
    session: donationSession,
    amount,
  });
};

export const getDonateTranslationsRequest = async ({
  restaurantId,
  languageCode,
}) => {
  return Vue.axios.get(`/translations/${restaurantId}/${languageCode}/`);
};
