<template>
  <Button
    v-if="callWaiterEnabled"
    class="button is-primary is-large"
    data-cy="select-screen-call-waiter"
    no-shadow
    @click="callWaiter"
  >
    {{ $t('screens.selectScreen.callWaiterCta') }}
  </Button>
</template>

<script>
import Button from '@/components/Button.vue';

// one minute
// const CLICK_TIMEOUT = 60 * 1000;

export default {
  name: 'CallWaiterButton',
  components: {
    Button,
  },
  data() {
    return {
      clickedAt: null,
      disabled: false,
    };
  },
  computed: {
    callWaiterEnabled() {
      return this.$store.getters.functionalityCallWaiterEnabled;
    },
  },
  methods: {
    callWaiter() {
      this.$store.dispatch('callWaiter', {});
      this.disabled = true;
      this.$store.dispatch('showToastInstantly', {
        title: this.$t('components.callWaiter.toast.title'),
        description: this.$t('components.callWaiter.toast.description'),
        doNotClose: true,
      });
      // setTimeout(() => {
      //   this.disabled = false;
      // }, CLICK_TIMEOUT);
    },
  },
};
</script>
