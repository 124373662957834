import Vue from 'vue';

export const submitRatingRequest = async ({
  session,
  uniqueOrderNumber,
  tipsQrCardHash,
  reviewId,
  rating,
}) => {
  return await Vue.axios.post(`${process.env.VUE_APP_API_URL}reviews/rate-v2`, {
    session_token: session,
    unique_order_number: uniqueOrderNumber,
    tips_qr_card_hash: tipsQrCardHash,
    review_id: reviewId,
    rating,
  });
};

export const submitRatingTagsRequest = async ({
  session,
  uniqueOrderNumber,
  tipsQrCardHash,
  reviewId,
  tags,
}) => {
  return await Vue.axios.post(
    `${process.env.VUE_APP_API_URL}reviews/add-review-tags-v2`,
    {
      session_token: session,
      unique_order_number: uniqueOrderNumber,
      tips_qr_card_hash: tipsQrCardHash,
      review_id: reviewId,
      tags,
    }
  );
};

export const submitRatingCommentRequest = async ({
  session,
  uniqueOrderNumber,
  tipsQrCardHash,
  reviewId,
  comment,
}) => {
  return await Vue.axios.post(
    `${process.env.VUE_APP_API_URL}reviews/leave-comment-v2`,
    {
      session_token: session,
      unique_order_number: uniqueOrderNumber,
      tips_qr_card_hash: tipsQrCardHash,
      review_id: reviewId,
      comment,
    }
  );
};

export const submitAttemptToRateOnGoogleRequest = async ({
  session,
  uniqueOrderNumber,
  tipsQrCardHash,
  reviewId,
}) => {
  return await Vue.axios.post(
    `${process.env.VUE_APP_API_URL}reviews/mark-google-review-attempted-v2`,
    {
      session_token: session,
      unique_order_number: uniqueOrderNumber,
      tips_qr_card_hash: tipsQrCardHash,
      review_id: reviewId,
    }
  );
};
