<template>
  <div class="flex justify-center">
    <div v-if="link" class="d-flex justify-center mt-5">
      <a
        class="white--text tp-text-subtitle-semibold-white"
        data-cy="request-receipt-cta-link"
        @click="openModal"
      >
        {{ $t('components.requestReceiptButton.cta') }}
      </a>
    </div>
    <Button
      v-else
      x-small
      dark
      no-shadow
      data-cy="request-receipt-cta-button"
      @click="openModal"
    >
      {{ $t('components.requestReceiptButton.cta') }}
    </Button>
    <Modal :is-open="modalOpen" :show-close-button="false">
      <template slot="content">
        <v-form ref="form" v-model="valid" @submit.prevent="requestReceipt">
          <div class="d-flex flex-column justify-center text-center">
            <h4 class="pb-5">
              {{ $t('components.requestReceiptButton.modal.title') }}
            </h4>
            <v-text-field
              v-model="email"
              :label="$t('components.requestReceiptButton.modal.emailLabel')"
              type="email"
              required
              :rules="emailRules"
            />
            <Button
              class="mt-4"
              type="submit"
              data-cy="send-request-receipt-button"
              :loading="isLoading"
              :disabled="isLoading"
              dark
              x-small
            >
              {{ $t('components.requestReceiptButton.modal.submitButton') }}
            </Button>
            <Button
              class="mt-4"
              data-cy="request-receipt-cancel-button"
              gray
              type="button"
              x-small
              @click="closeModal"
            >
              {{ $t('generic.cancel') }}
            </Button>
          </div>
        </v-form>
      </template>
    </Modal>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';
import Modal from '@/components/common/Modal.vue';

export default {
  components: {
    Button,
    Modal,
  },
  props: {
    paymentUuid: {
      type: String,
      required: true,
    },
    link: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      modalOpen: false,
      email: null,
      valid: true,
      emailRules: [
        v => !!v || this.$t('generic.requiredField'),
        v =>
          /.+@.+\..+/.test(v) ||
          this.$t('components.requestReceiptButton.modal.emailError'),
      ],
    };
  },
  methods: {
    async requestReceipt() {
      const isValid = this.$refs.form.validate();

      if (!isValid) {
        return;
      }
      this.isLoading = true;

      await this.$store.dispatch('requestReceiptV2', {
        email: this.email,
        paymentUuid: this.paymentUuid,
        onSuccess: () => {
          this.isLoading = false;
          this.closeModal();
          this.email = null;
        },
        onError: () => {
          this.isLoading = false;
        },
      });
    },
    closeModal() {
      this.modalOpen = false;
      this.$emit('on-close');
    },
    openModal() {
      this.modalOpen = true;
      this.$emit('on-open');
    },
  },
};
</script>
