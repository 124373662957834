<template>
  <div>
    <LayoutCard rounded>
      <div class="d-flex flex-row justify-space-between align-center">
        <div class="tp-text-subtitle">
          {{ $t('components.sortableCart.title') }}
        </div>
        <a
          href="#"
          class="tp-text-body-2 text-decoration-none"
          data-cy="edit-cart-sorting-cta"
          @click="toggleEditMode"
        >
          {{
            editMode
              ? $t('components.sortableCart.ctaEnd')
              : $t('components.sortableCart.cta')
          }}
        </a>
      </div>
      <div v-for="course in cartItemsByCourse" :key="course.id">
        <v-slide-y-transition hide-on-leave>
          <div v-show="editMode || course.items.length" class="mt-4">
            <div class="tp-text-body-2 grey--text text--darken-1 item-header">
              {{ course.display_name ? $t(course.display_name) : course.name }}
            </div>
            <SortableCartItem
              v-model="course.items"
              :course-id="course.id"
              :edit-mode="editMode"
              v-bind="$attrs"
              v-on="$listeners"
              @change="changeItemCourses"
            />
          </div>
        </v-slide-y-transition>
      </div>
      <div
        v-if="cartItemsByCourse.length"
        class="d-flex flex-row justify-space-between align-center pb-2 pt-6 w-100"
      >
        <div class="tp-text-subtitle-medium ">
          {{ $t('components.sortableCart.deliverASAPLabel') }}
        </div>
        <v-switch
          inset
          :ripple="false"
          hide-details
          :value="deliverASAP"
          data-cy="deliver-asap-switch"
          class="mt-0 custom-switch"
          @change="toggleDeliverASAP"
        />
      </div>
    </LayoutCard>
    <SortableCartModal ref="sortableCartModalRef" />
  </div>
</template>

<script>
import LayoutCard from '@/components/LayoutCard.vue';
import SortableCartItem from '@/components/Cart/SortableCartItem.vue';
import SortableCartModal from '@/components/Cart/SortableCartModal.vue';

export default {
  components: {
    LayoutCard,
    SortableCartItem,
    SortableCartModal,
  },
  data() {
    return {
      editMode: false,
      deliverASAP: false,
    };
  },
  computed: {
    cartItemsByCourse() {
      return this.$store.getters.normalizedCartItemsByCourse;
    },
  },
  watch: {
    cartItemsByCourse(newValue) {
      const cartItems = newValue.reduce((acc, course) => {
        return [...acc, ...course.items];
      }, []);
      const firstCourseId = newValue[0]?.id;

      if (!cartItems.length) return;

      const items = cartItems.map(item => ({
        cart_item_id: item.id,
        course_id: item.courseId,
      }));

      this.deliverASAP =
        cartItems.every(item => !item.courseId) ||
        cartItems.every(item => item.courseId === firstCourseId);

      this.$store.dispatch('updateCartItemsCourses', { items });
    },
  },
  mounted() {
    const firstCourseId = this.cartItemsByCourse[0]?.id;

    this.deliverASAP =
      this.$store.getters.cartItems.every(item => !item.course_id) ||
      this.$store.getters.cartItems.every(
        item => item.course_id === firstCourseId
      );
  },
  methods: {
    toggleEditMode() {
      this.$store.dispatch('sendAnalyticsEvent', {
        event: 'app_cart_courses-edit_click-cta',
      });

      if (!this.editMode) {
        setTimeout(() => {
          this.$refs.sortableCartModalRef.openModal();
        }, 800);
      }

      this.editMode = !this.editMode;
    },
    changeItemCourses(value, courseId) {
      value.forEach(item => {
        this.$store.dispatch('updateCartItemCourse', {
          cartItemId: item.id,
          courseId,
        });
      });
    },
    toggleDeliverASAP(checked) {
      const cartItems = this.$store.getters.cartItems;

      if (checked) {
        this.$store.dispatch('sendAnalyticsEvent', {
          event: 'app_cart_courses-edit_select-instant-delivery',
        });

        cartItems.forEach(item => {
          this.$store.dispatch('updateCartItemCourse', {
            cartItemId: item.cart_item_id,
            courseId: null,
          });
        });
      } else {
        this.$store.dispatch('sendAnalyticsEvent', {
          event: 'app_cart_courses-edit_unselect-instant-delivery',
        });
        cartItems.forEach(item => {
          const menuItem = this.$store.getters.menuItemById(item.item_id);
          this.$store.dispatch('updateCartItemCourse', {
            cartItemId: item.cart_item_id,
            courseId: menuItem?.course?.id || null,
          });
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.item-header {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 8px;
}

.custom-switch::v-deep {
  width: 44px;
}
</style>
