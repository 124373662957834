<template>
  <Modal
    :is-open="isOpen"
    :title="$t('screens.order.notificationsModalTitle')"
    :subtitle="
      notificationsAvailable
        ? $t('screens.order.notificationsModalSubtitle')
        : $t('screens.order.notificationsModalSubtitleSMS')
    "
    :cta-label="$t('generic.enable')"
    :close-label="$t('generic.notNow')"
    :cta-disabled="disabled"
    @cta-click="ctaClicked"
    @close-click="closeClick"
  >
    <template slot="image">
      <div class="d-flex justify-center align-center">
        <img
          class="pb-12 pt-4"
          :src="require('@/assets/images/icons/notification-bell.svg')"
        />
      </div>
    </template>
    <template v-if="!notificationsAvailable" slot="content">
      <div class="phone-input pt-6">
        <vue-phone-number-input
          v-model="phoneNumber"
          no-flags
          :color="primaryColor"
          :default-country-code="locale"
          size="lg"
          :translations="{
            countrySelectorLabel: $t(
              'components.notificationModal.countrySelectorLabel'
            ),
            countrySelectorError: $t(
              'components.notificationModal.countrySelectorError'
            ),
            phoneNumberLabel: $t(
              'components.notificationModal.phoneNumberLabel'
            ),
            example: $t('components.notificationModal.example'),
          }"
          no-example
          @update="onNumberChange"
        />
        <div class="tp-text-label black--text mt-4">
          <span>
            {{ $t('screens.order.notificationsModalBodySMS') }}
          </span>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/common/Modal.vue';
import cookies from '@/utils/cookies';

export default {
  name: 'NotificationModal',
  components: {
    Modal,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    ctaLabel: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      phoneNumber: cookies.getCookie('foodCourtUserPhoneNumber') || null,
      nationalNumber: null,
      countryCallingCode: null,
      isValid: false,
    };
  },
  computed: {
    primaryColor() {
      return this.$store.getters.customizationPrimaryColor || '#E85941';
    },
    notificationsAvailable() {
      // return 'Notification' in window;
      return false;
    },
    disabled() {
      return !this.notificationsAvailable && !this.isValid;
    },
    locale() {
      const localeCode = this.$store.state.locale.locale_code || 'lt-LT';
      return localeCode.split('-')[1];
    },
  },
  methods: {
    ctaClicked() {
      this.$emit('cta-click', this.countryCallingCode, this.nationalNumber);
      cookies.setCookie({
        name: 'foodCourtUserPhoneNumber',
        value: this.nationalNumber,
      });
    },
    closeClick() {
      this.$emit('close-click');
    },
    onNumberChange({ isValid, countryCallingCode, nationalNumber }) {
      this.countryCallingCode = countryCallingCode;
      this.isValid = isValid;
      this.nationalNumber = nationalNumber;
    },
  },
};
</script>

<style lang="scss" scoped>
.phone-input::v-deep {
  .country-selector {
    .country-selector__list__item {
      font-size: 14px !important;
    }

    &__list {
      z-index: 333;
      height: 150px !important;
    }
  }
}
</style>
