<template>
  <div class="quick-categories">
    <div
      v-for="(item, key) in items"
      :key="key"
      class="quick-categories__category tp-text-body"
      @click="action(item)"
    >
      <span v-if="item.emoji_code" v-html="item.emoji_code"></span>
      {{ $t(item.name) }}
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    items() {
      return this.$store.getters.quickAccessMenu;
    }
  },
  methods: {
    action(item) {
      if (item.type === 'category') {
        const category = this.$store.state.categories.find(category => {
          return category.id === item.data.category_id;
        });

        if (category) {
          this.$store.dispatch('categorySliderChanging', true);
          this.$store.dispatch(
            'changeActiveCategoryTab',
            `category-${category.hash}`
          );

          const scrollAnchor = '#category-' + category.hash;
          const offset =
            130 +
            parseInt(
              this.$store.getters
                .customizationMenuGoToCategoryAdditionalOffset || 0
            );
          this.$vuetify.goTo(scrollAnchor, {
            duration: 300,
            offset: offset,
            easing: 'easeInOutCubic',
          });
          setTimeout(() => {
            this.$store.dispatch('categorySliderChanging', false);
          }, 500);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.quick-categories {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  &__category {
    background: var(--quick-access-menu-buttons-background-color);
    border-radius: 5px;
    padding: 8px;
  }
}
</style>
