<template>
  <div>
    <v-bottom-sheet persistent :value="isOpen">
      <v-sheet class="pa-12 pb-12" rounded="xl b-0" min-height="250px">
        <div class="tp-text-body mb-6 mt-8" style="white-space: pre-line;">
          <div class="d-flex justify-center mb-10">
            <lottie-vue-player
              :src="error.image"
              style="width: 230px; height:230px"
              autoplay
              :loop="false"
            />
          </div>
          <div class="tp-text-title-bold mb-3">
            {{ error.title }}
            {{ error.withCountdown ? ` (${leftTime.toFixed(0)}s)` : '' }}
          </div>
          {{ error.message }}
        </div>
        <div class="mt-8">
          <Button
            dark
            no-shadow
            data-cy="pis-warning-cta"
            :loading="ctaLoading"
            @click="ctaAction"
          >
            {{ error.ctaLabel || $t('kiosk.error.defaultCtaLabel') }}
          </Button>
          <div v-if="error.showStartOver" class="d-flex justify-center mt-6">
            <Button white no-shadow :loading="startingOver" @click="startOver">
              {{ $t('kiosk.error.startOverCtaLabel') }}
            </Button>
          </div>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';

const TTL = 60;

export default {
  components: {
    Button,
  },
  data() {
    return {
      isOpen: false,
      errorCode: null,
      countdown: null,
      ttl: TTL,
      leftTime: TTL,
      interval: null,
      CRN: null,
      ctaLoading: false,
      startingOver: false,
    };
  },
  computed: {
    // • 00, 000-099, 400, 500, 501 – HOST approve,
    // • 100-200, 900-999 – HOST decline,
    // • BC – Banking Card is not allowed,
    // • BS – terminal is busy. Used by TERMINAL to indicate that it is unable to proceed the request.
    // Request is discarded by TERMINAL and should be repeated later,
    // • CE – connection (to host) error,
    // • CF – card fail,
    // • EE – Encryption error,
    // • LC – lost connection (to host),
    // • LP – load parameters, call Worldline and initialize,
    // • MI – message invalid,
    // • NA – operation not allowed,
    // • NB – non-banking card is not allowed,
    // • NF – operation not found in journal,
    // • NP – no TERMINAL,
    // • NR – no response from TERMINAL,
    // • PO – paper out,
    // • PR – partially reconciled,
    // • RC – remove card,
    // • SR – settlement required,
    // • TA – transaction aborted,
    // • TO – host response timeout,
    // • UC – user cancelled,
    // • VR – violation of reference.
    error() {
      const errorAnimationSrc =
        '/assets/order-status-animations/failed-payment.json';
      const errorMessages = {
        GENERIC: {
          title: this.$t('kiosk.error.GENERIC.title'),
          message: this.$t('kiosk.error.GENERIC.message'),
          image: errorAnimationSrc,
          ctaLabel: this.$t('kiosk.error.retryCtaLabel'),
          showStartOver: true,
          onCtaClick: () => {
            this.$emit('retry');
            this.close();
          },
        },
        ORDER_CREATE_FAILED: {
          title: this.$t('kiosk.error.ORDER_CREATE_FAILED.title'),
          message: this.$t('kiosk.error.ORDER_CREATE_FAILED.message'),
          image: errorAnimationSrc,
          withCountdown: false,
          ctaLabel: this.$t('kiosk.error.ORDER_CREATE_FAILED.ctaLabel'),
          onCtaClick: () => {
            this.startOver();
            this.ctaLoading = true;
            this.resetCountdown();
          },
        },
        UC: {
          title: this.$t('kiosk.error.UC.title'),
          message: this.$t('kiosk.error.UC.message'),
          image: errorAnimationSrc,
          withCountdown: true,
          removeUnresolvedCRN: true,
          ctaLabel: this.$t('kiosk.error.retryCtaLabel'),
          showStartOver: true,
          onCtaClick: () => {
            this.resetCountdown();
            this.$emit('retry');
            this.close();
          },
        },
        TA: {
          title: this.$t('kiosk.error.TA.title'),
          message: this.$t('kiosk.error.TA.message'),
          image: errorAnimationSrc,
          withCountdown: true,
          removeUnresolvedCRN: true,
          ctaLabel: this.$t('kiosk.error.retryCtaLabel'),
          showStartOver: true,
          onCtaClick: () => {
            this.resetCountdown();
            this.$emit('retry');
            this.close();
          },
        },
        BS: {
          title: this.$t('kiosk.error.BS.title'),
          message: this.$t('kiosk.error.BS.message'),
          image: errorAnimationSrc,
          ctaLabel: this.$t('kiosk.error.retryCtaLabel'),
          showStartOver: true,
          onCtaClick: () => {
            this.resetCountdown();
            this.$emit('retry');
            this.close();
          },
        },
        NR: {
          title: this.$t('kiosk.error.NR.title'),
          message: this.$t('kiosk.error.NR.message'),
          image: errorAnimationSrc,
          withCountdown: false,
          ctaLabel: this.$t('kiosk.error.retryCtaLabel'),
          showStartOver: true,
          onCtaClick: () => {
            this.resetCountdown();
            this.$emit('retry');
            this.close();
          },
        },
        NP: {
          title: this.$t('kiosk.error.NP.title'),
          message: this.$t('kiosk.error.NP.message'),
          image: errorAnimationSrc,
          withCountdown: false,
          callback: () => {
            this.$store.dispatch('setTerminalOffline');
          },
          ctaLabel: this.$t('kiosk.error.ORDER_CREATE_FAILED.ctaLabel'),
          onCtaClick: () => {
            this.startOver();
            this.ctaLoading = true;
            this.resetCountdown();
          },
        },
        '116': {
          title: this.$t('kiosk.error.116.title'),
          message: this.$t('kiosk.error.116.message'),
          image: errorAnimationSrc,
          removeUnresolvedCRN: true,
          withCountdown: true,
          ctaLabel: this.$t('kiosk.error.retryCtaLabel'),
          showStartOver: true,
          onCtaClick: () => {
            this.resetCountdown();
            this.$emit('retry');
            this.close();
          },
        },
      };

      if (!this.errorCode) {
        return {};
      }

      const error = errorMessages[this.errorCode] || errorMessages.GENERIC;

      return error;
    },
  },
  watch: {
    error(anError) {
      if (anError.removeUnresolvedCRN && this.isOpen) {
        this.$store.dispatch('removeUnresolvedCRN', { CRN: this.CRN });
      }
      if (anError.withCountdown) {
        this.startCountdown();
      }
      if (anError.callback) {
        anError.callback();
      }
    },
  },
  methods: {
    close() {
      this.isOpen = false;
      this.errorCode = null;
      this.stopCountDown();
      this.$emit('close');
    },
    open(code, CRN) {
      this.isOpen = true;
      this.errorCode = code;
      this.CRN = CRN;
    },
    ctaAction() {
      if (this.error.onCtaClick) {
        this.error.onCtaClick();
        return;
      }
      this.resetCountdown();
      this.close();
    },
    startCountdown() {
      this.interval = setInterval(() => {
        this.leftTime -= 1;
        if (this.leftTime <= 0) {
          this.resetCountdown();
          this.startOver();
          this.close();
        }
      }, 1000);
    },
    stopCountDown() {
      this.ttl = null;
      clearInterval(this.interval);
    },
    resetCountdown() {
      clearInterval(this.interval);
      this.leftTime = TTL;
    },
    startOver() {
      this.$emit('startOver');
      this.startingOver = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
