<template>
  <v-dialog
    v-if="popup"
    persistent
    :value="showPopup"
    max-width="800px"
    @click:outside="closePopup"
  >
    <v-container fluid>
      <v-row justify="center">
        <v-card width="100%">
          <v-img width="100%" :src="popup.image" :lazy-src="popup.image">
            <v-btn
              class="ma-3 float-right"
              fab
              x-small
              color="white"
              @click="closePopup"
            >
              <v-icon color="black">
                mdi-close
              </v-icon>
            </v-btn>
          </v-img>
          <v-card-actions
            v-if="popup.cta_text"
            class="d-flex justify-center ma-2"
          >
            <div>
              <Button dark @click="ctaClick">
                {{ $t(popup?.cta_text) }}
              </Button>
            </div>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import Button from '@/components/Button.vue';

export default {
  components: {
    Button,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    popup() {
      return this.$store.getters.popup;
    },
    showPopup() {
      const notShown = this.$store.getters.showPopup(this.popup.id);
      const notLoading = !this.loading;
      const isMenuPage = this.currentRouteName === 'Menu';

      return notShown && notLoading && isMenuPage;
    },
  },
  mounted() {
    this.$store.dispatch('checkPopupsInLocalStorage');

    this.$nextTick(() => {
      setTimeout(() => {
        this.loading = false;
      }, 4000);
    });
  },
  methods: {
    closePopup() {
      this.$store.dispatch('setPopupShown', this.popup.id);
    },
    ctaClick() {
      const url = this.popup.url;

      if (!url) {
        return this.closePopup();
      }

      const withHttp = url =>
        !/^https?:\/\//i.test(url) ? `http://${url}` : url;

      window.open(withHttp(url), '_blank', 'noopener noreferrer');
      this.closePopup();
    },
  },
};
</script>
