<template>
  <LayoutCard
    :title="$t('components.discountCode.title')"
    :default-collapsed="!isDiscountApplied"
    collapsable
    @on-toggle-collapse="onToggleCollapse"
  >
    <div v-if="isDiscountApplied" class="pt-3 w-100">
      <v-alert
        prominent
        color="grey"
        class="coupon-alert"
        dismissible
        :value="!!isDiscountApplied"
        text
        @input="removeDiscount"
      >
        <template slot="prepend">
          <v-icon class="pr-2" color="green">
            mdi-check-circle-outline
          </v-icon>
        </template>
        <div class="tp-text-body">
          <span class="font-weight-medium">{{ discountCode }}</span>
          {{ $t('components.discountCode.successTitle') }}
        </div>
        <div class="tp-text-label grey--text">
          <span v-if="$store.getters.isDiscountTypePayAtRestaurant">{{
            $t('components.discountCode.successPayAtRestaurant')
          }}</span>
          <span v-else>{{
            $t('components.discountCode.successAmount', {
              discountAmountFormatted,
            })
          }}</span>
        </div>
      </v-alert>
    </div>
    <div v-else class="d-flex flex-column pt-3 w-100">
      <v-text-field
        v-model="coupon"
        background-color="#F5F5F5"
        :rules="rules"
        :placeholder="$t('components.discountCode.codeInputPlaceholder')"
        outlined
        class="coupon-input"
        dense
        single-line
        :error-messages="errorMessages"
        @change="onDiscountCodeChange"
      />
      <Button
        dark
        x-small
        class="mt-0"
        :disabled="ctaDisabled"
        :loading="isLoadingDiscount"
        @click="applyDiscount"
      >
        {{ $t('components.discountCode.ctaLabel') }}
      </Button>
    </div>
  </LayoutCard>
</template>

<script>
import Button from '@/components/Button.vue';
import LayoutCard from '@/components/LayoutCard.vue';

export default {
  components: {
    LayoutCard,
    Button,
  },
  data: () => {
    return {
      coupon: null,
      rules: [],
    };
  },
  computed: {
    isLoadingDiscount() {
      return this.$store.getters.loadingDiscount;
    },
    discountAmount() {
      return this.$store.getters.discountAmount;
    },
    discountAmountFormatted() {
      return this.$store.getters.getFormattedPrice(this.discountAmount);
    },
    discountCode() {
      return this.$store.getters.discountCode;
    },
    errorMessages() {
      return this.$store.getters.discountErrorMessages;
    },
    ctaDisabled() {
      return this.isLoadingDiscount || !this.coupon;
    },
    isDiscountApplied() {
      return (
        !!this.$store.getters.discountAmount ||
        this.$store.getters.isDiscountTypePayAtRestaurant
      );
    },
  },
  methods: {
    onDiscountCodeChange(value) {
      this.$store.dispatch('setDiscountCode', value);
    },
    async applyDiscount() {
      await this.$store.dispatch('applyDiscount', { fetchCart: true });

      this.$store.dispatch('sendAnalyticsEvent', {
        event: 'apply_discount',
        coupon: this.coupon,
      });
    },
    async removeDiscount(open) {
      if (!open) {
        this.coupon = null;
        await this.$store.dispatch('removeDiscount', { fetchCart: true });
      }
    },
    onToggleCollapse(collapsed) {
      this.$store.dispatch('sendAnalyticsEvent', {
        event: 'toggle_discount_code',
        collapsed,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.coupon-input::v-deep .v-input__slot {
  height: 48px;
  fieldset {
    border: 0px;
  }
}

.coupon-alert {
  margin-bottom: 0px;
}

.w-100 {
  width: 100%;
}
</style>
