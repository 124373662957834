<template>
  <div class="content" @click="onItemClick">
    <div class="left-part">
      <div
        v-if="showQuantity"
        class="quantity mr-3 tp-text-body-white"
        :class="{ delivered: isPaid, selected: selected }"
      >
        1
      </div>
      <div class="flex">
        <span
          class="tp-text-subtitle-semibold"
          :class="{ 'grey--text': selected }"
          >{{ $t(name) }}</span
        >
        <div v-if="!hideModifiers" class="order-item-modifiers">
          <div
            v-for="(modifier, key) in modifiers"
            :key="key"
            class="tp-text-body"
          >
            {{ $t(modifier.name) }}
          </div>
        </div>
      </div>
    </div>
    <div class="right-part">
      <div class="tp-text-subtitle-normal" :class="{ 'grey--text': selected }">
        {{ formattedPrice }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    itemKey: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    completed: {
      type: Boolean,
      required: false,
      default: false,
    },
    price: {
      type: Number,
      default: null,
      required: false,
    },
    quantity: {
      type: Number,
      default: null,
      required: false,
    },
    modifiers: {
      type: Array,
      default: null,
      required: false,
    },
    isPaid: {
      type: Boolean,
      default: null,
      required: false,
    },
    showQuantity: {
      type: Boolean,
      default: true,
      required: false,
    },
    selected: {
      type: Boolean,
      default: false,
      required: false,
    },
    hideModifiers: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    priceWithModifiers() {
      let price = 0;
      price += this.price;

      this.modifiers.forEach(modifier => {
        price += modifier.price;
      });

      return price;
    },
    formattedPrice() {
      return this.$store.getters.getFormattedPrice(this.priceWithModifiers);
    },
  },
  methods: {
    onItemClick() {
      this.$emit('click', this.itemKey);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  .left-part {
    display: flex;
    flex-direction: row;
    flex: 0.8;
  }
  .right-part {
    display: flex;
    flex: 0.2;
    justify-content: flex-end;
  }
}
.quantity {
  width: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 3px;
  background-color: $color-black;
  color: $color-white;
  display: flex;
  justify-content: center;
  align-items: center;

  &.delivered {
    background-color: $color-success;
  }

  &.selected {
    background-color: $color-gray-500;
  }
}
</style>
