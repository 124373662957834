<template>
    <div v-if="items.length > 0"
         class="mb-2">
        <h3 class="subcategory__name mb-3">{{ $t(name) }}</h3>
        <menu-item v-for="(item, index) in items"
                   v-bind="item"
                   v-bind:key="index"
                   :full-width-info="showFullWidthInfo"
        ></menu-item>
    </div>
</template>

<script>
    import MenuItem from '@/components/MenuItem/MenuItem.vue'
    import categoryHasImages from '../helpers/categoryHasImages.js';

    export default {
      props: {
        name: String,
        description: String,
      },
      components: {
        MenuItem
      },
      computed: {
        items() {
          return this.$store.state.items.filter((item) => {
            return item.subcategory && item.subcategory.name === this.name
          })
        },
        showFullWidthInfo() {
          return !categoryHasImages(this.items);
        },
      }
    }
</script>

<style lang="scss">
    .subcategory__name {
        font-weight: 700;
        font-size: 1em;
    }
</style>