const initialState = {
  orderOnboardingDone: true,
  foodCourtOnboardingDone: true,
  drinkingAgeApproved: false,
  payGoOnboardingDone: true,
};

export default {
  state: initialState,
  mutations: {
    setOrderOnboardingDone(state, orderOnboardingDone) {
      state.orderOnboardingDone = orderOnboardingDone;
    },
    setFoodCourtOnboardingDone(state, foodCourtOnboardingDone) {
      state.foodCourtOnboardingDone = foodCourtOnboardingDone;
    },
    setDrinkingAgeApproved(state, drinkingAgeApproved) {
      state.drinkingAgeApproved = drinkingAgeApproved;
    },
    setPayGoOnboardingDone(state, payGoOnboardingDone) {
      state.payGoOnboardingDone = payGoOnboardingDone;
    },
  },
  actions: {
    async setOrderOnboardingDone({ commit }) {
      commit('setOrderOnboardingDone', true);
      localStorage.setItem('orderOnboardingDone', true);
    },
    setFoodCourtOnboardingDone({ commit }) {
      commit('setFoodCourtOnboardingDone', true);
      localStorage.setItem('foodCourtOnboardingDone', true);
    },
    async setDrinkingAgeApproved({ commit }, drinkingAgeApproved) {
      commit('setDrinkingAgeApproved', drinkingAgeApproved);
      localStorage.setItem('drinkingAgeApproved', drinkingAgeApproved);
    },
    async setPayGoOnboardingDone({ commit }) {
      commit('setPayGoOnboardingDone', true);
      localStorage.setItem('payGoOnboardingDone', true);
    },
    checkOrderOnboardingDone({ commit, getters }) {
      const orderOnboardingEnabled =
        getters.functionalityOrderOnboardingEnabled;

      if (!orderOnboardingEnabled) {
        return;
      }

      const orderOnboardingDone = localStorage.getItem('orderOnboardingDone');
      commit('setOrderOnboardingDone', !!orderOnboardingDone);
    },
    checkDrinkingAgeApproved({ commit }) {
      const drinkingAgeApproved = localStorage.getItem('drinkingAgeApproved');
      commit('setDrinkingAgeApproved', !!drinkingAgeApproved);
    },
    checkFoodCourtOnboardingDone({ commit }) {
      const foodCourtOnboardingDone = localStorage.getItem(
        'foodCourtOnboardingDone'
      );
      commit('setFoodCourtOnboardingDone', !!foodCourtOnboardingDone);
    },
    checkPayGoOnboardingDone({ commit }) {
      const payGoOnboardingDone = localStorage.getItem('payGoOnboardingDone');
      commit('setPayGoOnboardingDone', !!payGoOnboardingDone);
    },
  },
  getters: {
    orderOnboardingDone: state => state.orderOnboardingDone,
    drinkingAgeApproved: state => state.drinkingAgeApproved,
    foodCourtOnboardingDone: state => state.foodCourtOnboardingDone,
    payGoOnboardingDone: state => state.payGoOnboardingDone,
  },
};
