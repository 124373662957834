import Vue from 'vue';

export const fetchFoodCourtDetailsRequest = async ({
  hash,
  foodCourtSession,
}) => {
  return await Vue.axios.post(`/food-courts/get-by-hash/${hash}`, {
    food_court_session: foodCourtSession,
  });
};

export const fetchFoodCourtRestaurantsRequest = async ({ hash }) => {
  return Vue.axios.get(`/food-courts/get-restaurants/${hash}`);
};

export const fetchRestaurantSessionRequest = async ({
  restaurantId,
  foodCourtSession,
  restaurantSession,
}) => {
  return await Vue.axios.post(`/food-courts/go-to-restaurant/${restaurantId}`, {
    food_court_session: foodCourtSession,
    session: restaurantSession,
  });
};

export const fetchFoodCourtOrdersRequest = async ({ foodCourtSession }) => {
  return await Vue.axios.get(`/food-courts/get-orders/${foodCourtSession}`);
};

export const fetchFoodCourtTranslationsRequest = async ({
  hash,
  languageCode,
}) => {
  return await Vue.axios.get(
    `/food-courts/get-translations/${hash}/${languageCode}`
  );
};

export const updateFoodCourtSessionLanguageRequest = async ({
  foodCourtSession,
  languageCode,
}) => {
  return await Vue.axios.post(
    `/food-courts/update-food-court-session-language`,
    {
      food_court_session: foodCourtSession,
      language: languageCode,
    }
  );
};
