<template>
  <div class="d-flex flex-row align-center">
    <div class="menu-item-price__price" :class="priceTextClass">
      {{ priceFormatted }}
    </div>
    <div
      v-if="oldPrice > price"
      :class="oldPriceTextClass"
      class="menu-item-price__old-price ml-2"
    >
      {{ oldPriceFormatted }}
    </div>
  </div>
</template>

<script>
import formatPriceWithCurrency from '../../helpers/formatPriceWithCurrency.js';
export default {
  props: {
    price: Number,
    oldPrice: Number,
    bigger: Boolean,
    customFontFamily: [String, Boolean],
    customFontWeight: [String, Boolean],
    customFontColor: [String, Boolean],
    customFontSize: [String, Boolean],
  },
  data() {
    return {
      currency: this.$store.state.restaurant.currency.code,
      decimals: this.$store.state.restaurant.currency.decimals,
      locale: this.$store.state.restaurant.locale.locale_code,
    };
  },
  computed: {
    priceFormatted() {
      return formatPriceWithCurrency(this.price, this.currency, this.locale);
    },
    oldPriceFormatted() {
      return formatPriceWithCurrency(this.oldPrice, this.currency, this.locale);
    },
    priceTextClass() {
      return {
        'tp-text-body-2': !this.bigger,
        'tp-text-subtitle-semibold': this.bigger,
        'special-deal': this.price < this.oldPrice,
        'custom-font-family': this.customFontFamily !== false,
        'custom-font-weight': this.customFontWeight !== false,
        'custom-font-color': this.customFontColor !== false,
        'custom-font-size': this.customFontSize !== false,
        'custom-font-style': this.customFontStyle !== false,
      };
    },
    oldPriceTextClass() {
      return {
        ...this.priceTextClass,
        'custom-font-color':
          this.customFontColor !== false,
      };
    },
  },
};
</script>

<style lang="scss">
.menu-item-price {
  &__old-price {
    text-decoration: line-through;
  }
  &__price.special-deal {
    color: var(--color-primary) !important;
  }
}
.custom-font-family {
  font-family: var(--menu-items-price-font-family) !important;
}

.custom-font-weight {
  font-weight: var(--menu-items-price-font-weight) !important;
}

.custom-font-color {
  color: var(--menu-items-price-font-color) !important;
}

.custom-font-size {
  font-size: var(--menu-items-price-font-size) !important;
}

.custom-font-style {
  font-style: var(--menu-items-price-font-style) !important;
}
</style>
