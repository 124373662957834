<template>
  <svg
    :width="width"
    :height="height"
    :stroke="color"
    :viewBox="`0 0 24 24`"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g class="spinner">
      <circle cx="12" cy="12" r="9.5" fill="none" :stroke-width="strokeWidth"></circle>
    </g>
  </svg>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: '#000' 
        },
        width: {
            type: Number,
            default: 72
        },
        height: {
            type: Number,
            default: 72
        },
        strokeWidth: {
            type: Number,
            default: 3
        }
    }
}
</script>

<style lang="scss" scoped>
.spinner {
  transform-origin: center;
  animation: spinner_1 2s linear infinite;
}
.spinner circle {
  stroke-linecap: round;
  animation: spinner_2 1.5s ease-in-out infinite;
}
@keyframes spinner_1 {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spinner_2 {
  0% {
    stroke-dasharray: 0 150;
    stroke-dashoffset: 0;
  }
  47.5% {
    stroke-dasharray: 42 150;
    stroke-dashoffset: -16;
  }
  95%,
  100% {
    stroke-dasharray: 42 150;
    stroke-dashoffset: -59;
  }
}
</style>
