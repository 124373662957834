<template>
  <v-btn
    height="48px"
    class="mb-3 rounded-lg"
    :class="{ 'white--text': whiteText }"
    :color="color"
    :data-cy="dataCy"
    type="button"
    @click="onClick"
  >
    <div class="button-container">
      <div class="button-container button-text left">
        <img v-if="image" :src="image" height="20px" width="20px" />
        <v-icon v-if="icon" dense>{{ icon }}</v-icon>
      </div>
      <div class="button-container button-text">
        <span class="pl-2">
          {{ content }}
        </span>
      </div>
      <div class="button-container button-text left" />
    </div>
  </v-btn>
</template>

<script>
export default {
  props: {
    onClick: {
      type: Function,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: null,
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
    image: {
      type: String,
      required: false,
      default: null,
    },
    dataCy: {
      type: String,
      required: false,
      default: null,
    },
    whiteText: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.button-container {
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;

  .button-text {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: 'Poppins', sans-serif !important;
    font-style: normal;
    font-weight: 600;
    font-size: 14px !important;
    letter-spacing: normal !important;
  }

  .left {
    flex: 0.2;
    align-items: flex-start;
    justify-content: flex-start !important;
  }

  .right {
    flex: 0.2;
    align-items: flex-end;
    justify-content: flex-start !important;
  }
}
</style>
