import Vue from 'vue';
import { get } from 'lodash';

import {
  defaultPhotoUrl,
  enrollUserRequest,
  getUserRequest,
  updateUserRequest,
  validateUserRequest,
} from './api';

const initialState = {
  user: null,
  status: 'idle',
  enrolledLoyaltyPrograms: [],
};

export default {
  state: initialState,
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setEnrolledLoyaltyPrograms(state, loyaltyPrograms) {
      state.enrolledLoyaltyPrograms = loyaltyPrograms;
    },
    enrollUser(state, loyaltyProgram) {
      state.enrolledLoyaltyPrograms.push(loyaltyProgram);
    },
    setStatus(state, status) {
      state.status = status;
    },
    updateUser(state, user) {
      state.user = user;
    },
  },
  actions: {
    async setUser({ commit, rootState }, firebaseUser) {
      try {
        commit('setStatus', 'loading');
        const { session } = rootState;

        const { loyalty_programs } = await validateUserRequest(
          session,
          firebaseUser.uid
        );
        const userDetails = await getUserRequest(session, firebaseUser.uid);

        const user = {
          ...userDetails,
          ...firebaseUser,
        };

        commit('setUser', user);
        commit('setEnrolledLoyaltyPrograms', loyalty_programs);
        commit('setStatus', 'idle');
      } catch (error) {
        commit('setStatus', 'idle');
        console.error(error);
      }
    },
    clearUser({ commit }) {
      commit('setUser', null);
      commit('setEnrolledLoyaltyPrograms', []);
    },
    async enrollUser(
      { rootState, commit, getters, dispatch },
      { loyaltyProgram, details }
    ) {
      try {
        const user = getters.user;
        const { session } = rootState;
        const { loyalty_programs } = await enrollUserRequest(
          session,
          user.uid,
          loyaltyProgram.id,
          details
        );
        commit('setEnrolledLoyaltyPrograms', loyalty_programs);
      } catch (error) {
        const toastData = {
          'Card is already used': {
            title: Vue.i18n.translate(
              'components.toast.cardAlreadyExistsTitle'
            ),
            description: Vue.i18n.translate(
              'components.toast.cardAlreadyExistsDescription'
            ),
          },
          'Card is not valid': {
            title: Vue.i18n.translate('components.toast.cardNotValidTitle'),
            description: Vue.i18n.translate(
              'components.toast.cardNotValidDescription'
            ),
          },
        };

        const toast = get(toastData, error.response.data.message, {
          title: Vue.i18n.translate('components.toast.genericErrorTitle'),
          description: Vue.i18n.translate(
            'components.toast.genericErrorDescription'
          ),
        });

        dispatch('setToastData', {
          title: toast.title,
          description: toast.description,
          isError: true,
        });

        dispatch('openToast');
        throw error;
      }
    },
    async updateUserInfo({ rootState, commit, getters }, userDetails) {
      try {
        const user = getters.user;
        const { session } = rootState;

        await updateUserRequest(session, user.uid, userDetails);

        const updatedUser = {
          ...user,
          ...userDetails,
        };

        commit('updateUser', updatedUser);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
  getters: {
    user: state => state.user,
    isLoadingUser: state => state.status === 'loading',
    userId: (_, getters) => {
      const user = getters.user;
      return get(user, 'uid', null);
    },
    userProfilePhoto: (_, getters) => {
      const user = getters.user;

      if (user) {
        const defaultPhoto = defaultPhotoUrl(user.displayName);
        return get(user, 'photoURL', defaultPhoto);
      }

      return null;
    },
    userEnrolledLoyaltyPrograms: state => state.enrolledLoyaltyPrograms || [],
    enrolledRestaurantLoyaltyProgram: (_, getters) => {
      const restaurantLoyaltyPrograms = getters.restaurantLoyaltyPrograms;
      const enrolledLoyaltyPrograms = getters.userEnrolledLoyaltyPrograms;
      const enrolledIds = enrolledLoyaltyPrograms.map(
        loyaltyProgram => loyaltyProgram.id
      );

      return restaurantLoyaltyPrograms.find(loyaltyProgram =>
        enrolledIds.includes(loyaltyProgram.id)
      );
    },
    userHasRestaurantLoyaltyProgram: (_, getters) => {
      const restaurantLoyaltyPrograms = getters.restaurantLoyaltyPrograms;
      const enrolledLoyaltyPrograms = getters.userEnrolledLoyaltyPrograms;
      const enrolledIds = enrolledLoyaltyPrograms.map(
        loyaltyProgram => loyaltyProgram.id
      );

      return restaurantLoyaltyPrograms.some(loyaltyProgram =>
        enrolledIds.includes(loyaltyProgram.id)
      );
    },
    userHasWalletDetailsFilled: (_, getters) => {
      const user = getters.user;
      const hasWalletDetails = get(user, 'phone_number');

      return hasWalletDetails;
    },
    enrolledLoyaltyProgramById: (_, getters) => id => {
      const programs = getters.userEnrolledLoyaltyPrograms;
      return programs.find(program => `${program.id}` === `${id}`);
    },
  },
};
