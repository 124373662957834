import { activateOrderBatchRequest, activateCashOrderRequest } from './api';

export default {
  async activateOrderBatch({ rootState }, { uniqueOrderNumber }) {
    try {
      const response = await activateOrderBatchRequest({
        session: rootState.session,
        uniqueOrderNumber: uniqueOrderNumber,
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async activeCashOrder({ rootState }, { order }) {
    try {
      const session = rootState.session;
      const response = await activateCashOrderRequest({
        session,
        order,
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
