<template>
  <div>
    <v-expand-transition v-if="existingRating">
      <div class="d-flex flex-column">
        <div v-if="showReviewOnGoogle">
          <div class="tp-text-subtitle mb-3">
            {{ $t('components.review.thankYouTitle') }}
          </div>
          <span class="tp-text-body">
            {{ $t('components.review.leaveGoogleReviewContent') }}
          </span>
          <div class="mx-4 mt-6">
            <Button dark @click="leaveAReviewOnGoogle">
              <div class="d-flex align-center">
                <v-img
                  height="20px"
                  width="20px"
                  src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 48 48'%3E%3Cdefs%3E%3Cpath id='a' d='M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z'/%3E%3C/defs%3E%3CclipPath id='b'%3E%3Cuse xlink:href='%23a' overflow='visible'/%3E%3C/clipPath%3E%3Cpath clip-path='url(%23b)' fill='%23FBBC05' d='M0 37V11l17 13z'/%3E%3Cpath clip-path='url(%23b)' fill='%23EA4335' d='M0 11l17 13 7-6.1L48 14V0H0z'/%3E%3Cpath clip-path='url(%23b)' fill='%2334A853' d='M0 37l30-23 7.9 1L48 0v48H0z'/%3E%3Cpath clip-path='url(%23b)' fill='%234285F4' d='M48 48L17 24l-4-3 35-10z'/%3E%3C/svg%3E"
                  class="mr-2"
                />
                {{ $t('components.review.leaveGoogleReviewCtaLabel') }}
              </div>
            </Button>
          </div>
        </div>
        <div v-else>
          <div class="tp-text-subtitle mb-3">
            {{ $t('components.review.thankYouTitle') }}
          </div>
          <span class="tp-text-body">
            {{ $t('components.review.thankYouMessage') }}
          </span>
        </div>
      </div>
    </v-expand-transition>
    <div v-else>
      <div v-if="!hideTitle" class="tp-text-subtitle mb-3">
        {{ $t('components.review.title') }}
      </div>
      <v-chip-group
        v-model="ratingScore"
        active-class="primary"
        class="emoji-group d-flex flex-row"
        @change="submitRating"
      >
        <div
          v-for="(rating, index) in ratings"
          :key="index"
          class="d-flex flex-column justify-center align-center emoji-chip"
        >
          <v-chip
            :key="rating"
            label
            color="#F5F5F5"
            x-large
            :value="index + 1"
            class="emoji-chip ma-1 pa-0 rounded-lg"
          >
            <div style="font-size: 36px" v-html="rating" />
          </v-chip>
          <span class="tp-text-label black--text mt-1">{{
            $t(ratingLabel(index))
          }}</span>
        </div>
      </v-chip-group>
      <v-expand-transition>
        <div v-show="ratingScore > 0">
          <div class="tp-text-subtitle pt-6 pb-4">
            {{ $t('components.review.tagsLabel') }}
          </div>
          <v-chip-group
            v-model="tagsSelection"
            multiple
            active-class="primary white--text"
            column
            @change="submitRatingTags"
          >
            <v-chip v-for="tag in tags" :key="tag" color="#F5F5F5" label>
              {{ $t(tag) }}
            </v-chip>
          </v-chip-group>
          <v-textarea
            v-model="ratingComment"
            class="comment-textarea mt-2"
            name="user-comment"
            rows="1"
            :placeholder="$t('components.review.commentPlaceholder')"
            auto-grow
            outlined
            hide-details
          />
          <div class="px-4 mt-4">
            <Button :loading="loading" dark @click="submitRatingComment">
              {{ $t('components.review.ctaLabel') }}
            </Button>
          </div>
        </div>
      </v-expand-transition>
    </div>
  </div>
</template>

<script>
import { debounce, compact } from 'lodash';
import Button from '@/components/Button.vue';

const RATINGS = [
  '&#128544;',
  '&#128533;',
  '&#128529;',
  '&#128522;',
  '&#129321;',
];

const RATING_LABELS = ['Terrible', 'Bad', 'Meh', 'Good', 'Excellent'];

const DEFAULT_TAGS = ['Taste', 'Service', 'Speed', 'Atmosphere'];

export default {
  components: {
    Button,
  },
  props: {
    uniqueOrderNumber: {
      type: String,
      required: false,
      default: null,
    },
    tipsQrCardHash: {
      type: String,
      required: false,
      default: null,
    },
    review: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    hideTitle: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      ratingScore: this.review?.rating || 0,
      ratingTags: this.review?.rating || 0,
      ratingComment: '',
      loading: false,
      ratingSubmitted: false,
      tagsSelection: [],
      clickedOnReviewOnGoogle: false,
    };
  },
  computed: {
    existingRating() {
      if (this.ratingSubmitted) {
        return this.ratingScore;
      }
      return this.review?.submitted;
    },
    ratings() {
      return RATINGS;
    },
    tags() {
      return DEFAULT_TAGS;
    },
    goodRating() {
      return this.ratingScore > 4;
    },
    googlePlaceId() {
      return this.$store.getters.googlePlaceId;
    },
    attemptedToRateOnGoogle() {
      return (
        this.review?.google_review_attempted || this.clickedOnReviewOnGoogle
      );
    },
    showReviewOnGoogle() {
      return (
        this.goodRating && !this.attemptedToRateOnGoogle && this.googlePlaceId
      );
    },
  },
  mounted() {
    this.tagsSelection = this.getInitialTagSelection();
  },
  methods: {
    ratingLabel(index) {
      return RATING_LABELS[index];
    },
    getInitialTagSelection() {
      if (!this.review?.tags) {
        return [];
      }
      return Object.keys(this.tags)
        .filter(key => this.review?.tags.includes(this.tags[key]))
        .map(Number);
    },
    submitRating(rating) {
      if (!rating) {
        return;
      }

      this.$store.dispatch('submitRating', {
        uniqueOrderNumber: this.uniqueOrderNumber,
        tipsQrCardHash: this.tipsQrCardHash,
        rating,
      });
    },
    submitRatingTags: debounce(async function() {
      const selectedTags = this.tagsSelection.map(index => this.tags[index]);
      const tags = compact(selectedTags); // remove comment tag if present

      await this.$store.dispatch('submitRatingTags', {
        uniqueOrderNumber: this.uniqueOrderNumber,
        tipsQrCardHash: this.tipsQrCardHash,
        tags,
      });
    }, 1000),
    async submitRatingComment() {
      this.loading = true;
      await this.$store.dispatch('submitRatingComment', {
        uniqueOrderNumber: this.uniqueOrderNumber,
        tipsQrCardHash: this.tipsQrCardHash,
        comment: this.ratingComment,
      });
      this.loading = false;
      this.ratingSubmitted = true;
    },
    leaveAReviewOnGoogle() {
      this.$store.dispatch('submitAttemptToRateOnGoogle', {
        uniqueOrderNumber: this.uniqueOrderNumber,
        tipsQrCardHash: this.tipsQrCardHash,
      });

      this.clickedOnReviewOnGoogle = true;

      const url = `https://search.google.com/local/writereview?placeid=${this.googlePlaceId}`;
      window.open(url, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.emoji-group::v-deep .v-slide-group__content {
  justify-content: space-evenly !important;
  padding: 0px !important;
}

.emoji-chip {
  width: 92%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
