<template>
  <div v-if="hasPersonnel" class="text-center">
    <v-bottom-sheet
      v-model="open"
      :max-width="maxAppWidth"
      rounded="xl b-0"
      inset
      persistent
      data-cy="bottom-sheet-personnel-picker"
    >
      <template v-slot:activator="{ on, attrs }">
        <a v-bind="attrs" data-cy="open-personnel-picker-cta" v-on="on">
          {{ $t('screens.tipPersonnelMember.personnelPicker.cta') }}
        </a>
      </template>
      <v-sheet
        rounded="xl b-0"
        max-height="600px"
        min-height="400px"
        style="overflow-y: scroll; background-color: #F3F4F6; overflow-x: hidden;"
      >
        <h2 class="pa-4 pb-2 sticky-header">
          {{ $t('screens.tipPersonnelMember.personnelPicker.title') }}
        </h2>
        <v-row dense class="pa-4 pt-2">
          <v-col
            v-for="(personnel, key) in personnelList"
            :key="personnel.id"
            cols="6"
            :data-cy="`pick-personnel-${key}`"
            @click="pickPersonnel(personnel)"
          >
            <v-card flat rounded="lg" height="100%">
              <v-img
                :src="photoUrl(personnel)"
                :lazy-src="personnel.photo"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.05), rgba(0,0,0,.1)"
                height="140px"
                style="-webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;"
              >
                <v-card-title v-text="personnel.name"></v-card-title>
                <template v-slot:placeholder>
                  <v-skeleton-loader type="image" height="115px" />
                </template>
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { defaultPhotoUrl } from '../../store/modules/user/api';

export default {
  props: {
    defaultOpen: {
      type: Boolean,
      default: false,
    },
    onOpen: {
      type: Function,
      default: () => {},
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    personnelList: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    open: true,
  }),
  computed: {
    hasPersonnel() {
      return this.personnelList.length > 0;
    },
  },
  methods: {
    closeBottomSheet() {
      this.open = false;
      this.$emit('close');
    },
    openBottomSheet() {
      this.open = true;
      this.$emit('open');
    },
    photoUrl(personnel) {
      return personnel.photo || defaultPhotoUrl(personnel.name);
    },
    pickPersonnel(personnel) {
      this.closeBottomSheet();
      this.$emit('pick', personnel.id);
    },
  },
};
</script>

<style scoped lang="scss">
.sticky-header {
  position: sticky;
  top: 0;
  background-color: #f3f4f6;
  z-index: 1;
}
</style>
