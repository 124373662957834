import { lightOrDarkColor } from '@/helpers/lightOrDark.js';

const initialState = {
  customizations: [],
};

export default {
  state: initialState,
  actions: {
    saveCustomizations(context, customizations) {
      context.commit('SET_CUSTOMIZATIONS', customizations);
    },
    setGlobalCssVariables(context) {
      const primaryColorCustomization =
        context.getters.customizationPrimaryColor;

      if (primaryColorCustomization) {
        document.documentElement.style.setProperty(
          '--color-primary',
          primaryColorCustomization
        );
        document.documentElement.style.setProperty(
          '--color-button-gradient-top',
          primaryColorCustomization
        );
        document.documentElement.style.setProperty(
          '--color-button-gradient-bottom',
          primaryColorCustomization
        );
        document.documentElement.style.setProperty(
          '--v-anchor-base',
          primaryColorCustomization
        );
        document.documentElement.style.setProperty(
          '--v-primary-base',
          primaryColorCustomization
        );
      }

      // Default gray texts has to be changed to lighter gray
      // if menu is in dark mode
      const customizationMenuDarkMode =
        context.getters.customizationMenuDarkMode;
      if (customizationMenuDarkMode) {
        document.documentElement.style.setProperty(
          '--menu-short-description-color',
          '#9f9f9f'
        );
        document.documentElement.style.setProperty(
          '--menu-category-description-color',
          '#9f9f9f'
        );
        document.documentElement.style.setProperty(
          '--menu-category-divider-color',
          '#9f9f9f'
        );
        document.documentElement.style.setProperty(
          '--menu-category-slider-inactive-color',
          '#9f9f9f'
        );
      }

      const customizationMenuBackgroundColor =
        context.getters.customizationMenuBackgroundColor;
      if (customizationMenuBackgroundColor) {
        document.documentElement.style.setProperty(
          '--menu-background-color',
          customizationMenuBackgroundColor
        );
      }

      const customizationMenuBackgroundImage =
        context.getters.customizationMenuBackgroundImage;
      if (customizationMenuBackgroundImage) {
        document.documentElement.style.setProperty(
          '--menu-background-image',
          `url(${customizationMenuBackgroundImage})`
        );
      }

      const customizationMenuShortDescriptionFontSize =
        context.getters.customizationMenuShortDescriptionFontSize;
      if (customizationMenuShortDescriptionFontSize) {
        document.documentElement.style.setProperty(
          '--menu-short-description-size',
          customizationMenuShortDescriptionFontSize
        );
      }

      const customizationMenuShortDescriptionFontColor =
        context.getters.customizationMenuShortDescriptionFontColor;
      if (customizationMenuShortDescriptionFontColor) {
        document.documentElement.style.setProperty(
          '--menu-short-description-color',
          customizationMenuShortDescriptionFontColor
        );
      }

      const customizationMenuShortDescriptionFontWeight =
        context.getters.customizationMenuShortDescriptionFontWeight;
      if (customizationMenuShortDescriptionFontWeight) {
        document.documentElement.style.setProperty(
          '--menu-short-description-weight',
          customizationMenuShortDescriptionFontWeight
        );
      }

      const textMainColorCustomization =
        context.getters.customizationColorTextMain;
      if (textMainColorCustomization) {
        document.documentElement.style.setProperty(
          '--color-text-main',
          textMainColorCustomization
        );
        document.documentElement.style.setProperty(
          '--menu-items-font-color',
          textMainColorCustomization
        );
        document.documentElement.style.setProperty(
          '--menu-items-divider-color',
          textMainColorCustomization
        );
        document.documentElement.style.setProperty(
          '--menu-restaurant-title-font-color',
          textMainColorCustomization
        );
      }

      const themeColorCustomization = context.getters.customizationThemeColor;

      if (themeColorCustomization) {
        document
          .querySelector('meta[name="theme-color"]')
          ?.setAttribute('content', themeColorCustomization);
      }

      // Load custom font
      const customizationMenuFontOverrideLink =
        context.getters.customizationMenuFontOverrideLink;
      if (customizationMenuFontOverrideLink) {
        let head = document.head;
        let link = document.createElement('link');

        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.href = customizationMenuFontOverrideLink;
        head.appendChild(link);
      }

      const customizationMenuFontOverrideFamily =
        context.getters.customizationMenuFontOverrideFamily;
      if (customizationMenuFontOverrideFamily) {
        document.documentElement.style.setProperty(
          '--menu-font-family',
          customizationMenuFontOverrideFamily
        );
        document.documentElement.style.setProperty(
          '--menu-categories-font-family',
          customizationMenuFontOverrideFamily
        );

        document.documentElement.style.setProperty(
          '--menu-item-description-font-family',
          customizationMenuFontOverrideFamily
        );

        document.documentElement.style.setProperty(
          '--menu-items-price-font-family',
          customizationMenuFontOverrideFamily
        );
      }

      const customizationMenuCategoriesFontOverrideLink =
        context.getters.customizationMenuCategoriesFontOverrideLink;
      if (customizationMenuCategoriesFontOverrideLink) {
        let head = document.head;
        let link = document.createElement('link');

        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.href = customizationMenuCategoriesFontOverrideLink;
        head.appendChild(link);
      }

      const customizationMenuCategoriesFontOverrideFamily =
        context.getters.customizationMenuCategoriesFontOverrideFamily;
      if (customizationMenuCategoriesFontOverrideFamily) {
        document.documentElement.style.setProperty(
          '--menu-categories-font-family',
          customizationMenuCategoriesFontOverrideFamily
        );
      }

      const customizationMenuItemDescriptionFontOverrideFamily =
        context.getters.customizationMenuItemDescriptionFontOverrideFamily;
      if (customizationMenuItemDescriptionFontOverrideFamily) {
        document.documentElement.style.setProperty(
          '--menu-item-description-font-family',
          customizationMenuItemDescriptionFontOverrideFamily
        );
      }

      const customizationMenuItemDescriptionFontStyle =
        context.getters.customizationMenuItemDescriptionFontStyle;
      if (customizationMenuItemDescriptionFontStyle) {
        document.documentElement.style.setProperty(
          '--menu-item-description-font-style',
          customizationMenuItemDescriptionFontStyle
        );
      }

      const customizationMenuItemDescriptionLetterSpacing =
        context.getters.customizationMenuItemDescriptionLetterSpacing;
      if (customizationMenuItemDescriptionLetterSpacing) {
        document.documentElement.style.setProperty(
          '--menu-item-description-letter-spacing',
          customizationMenuItemDescriptionLetterSpacing
        );
      }

      const customizationMenuItemsFontWeight =
        context.getters.customizationMenuItemsFontWeight;
      if (customizationMenuItemsFontWeight) {
        document.documentElement.style.setProperty(
          '--menu-items-font-weight',
          customizationMenuItemsFontWeight
        );
      }

      const customizationMenuItemsFontSize =
        context.getters.customizationMenuItemsFontSize;
      if (customizationMenuItemsFontSize) {
        document.documentElement.style.setProperty(
          '--menu-items-font-size',
          customizationMenuItemsFontSize
        );
      }

      const customizationMenuItemsFontColor =
        context.getters.customizationMenuItemsFontColor;
      if (customizationMenuItemsFontColor) {
        document.documentElement.style.setProperty(
          '--menu-items-font-color',
          customizationMenuItemsFontColor
        );
        document.documentElement.style.setProperty(
          '--menu-items-divider-color',
          customizationMenuItemsFontColor
        );
      }

      const customizationMenuItemsFontStyle =
        context.getters.customizationMenuItemsFontStyle;
      if (customizationMenuItemsFontStyle) {
        document.documentElement.style.setProperty(
          '--menu-items-font-style',
          customizationMenuItemsFontStyle
        );
      }

      const customizationMenuItemsTextTransform =
        context.getters.customizationMenuItemsTextTransform;
      if (customizationMenuItemsTextTransform) {
        document.documentElement.style.setProperty(
          '--menu-items-text-transform',
          customizationMenuItemsTextTransform
        );
      }

      const customizationMenuItemsPriceFontWeight =
        context.getters.customizationMenuItemsPriceFontWeight;
      if (customizationMenuItemsPriceFontWeight) {
        document.documentElement.style.setProperty(
          '--menu-items-price-font-weight',
          customizationMenuItemsPriceFontWeight
        );
      }

      const customizationMenuItemsPriceFontColor =
        context.getters.customizationMenuItemsPriceFontColor;
      if (customizationMenuItemsPriceFontColor) {
        document.documentElement.style.setProperty(
          '--menu-items-price-font-color',
          customizationMenuItemsPriceFontColor
        );
      }

      const customizationMenuItemsPriceFontSize =
        context.getters.customizationMenuItemsPriceFontSize;
      if (customizationMenuItemsPriceFontSize) {
        document.documentElement.style.setProperty(
          '--menu-items-price-font-size',
          customizationMenuItemsPriceFontSize
        );
      }

      const customizationMenuItemsPriceFontStyle =
        context.getters.customizationMenuItemsPriceFontStyle;
      if (customizationMenuItemsPriceFontStyle) {
        document.documentElement.style.setProperty(
          '--menu-items-price-font-style',
          customizationMenuItemsPriceFontStyle
        );
      }

      const customizationMenuItemsTitleLetterSpacing =
        context.getters.customizationMenuItemsTitleLetterSpacing;
      if (customizationMenuItemsTitleLetterSpacing) {
        document.documentElement.style.setProperty(
          '--menu-items-title-letter-spacing',
          customizationMenuItemsTitleLetterSpacing
        );
      }

      const customizationMenuCategoriesFontSize =
        context.getters.customizationMenuCategoriesFontSize;
      if (customizationMenuCategoriesFontSize) {
        document.documentElement.style.setProperty(
          '--menu-categories-font-size',
          customizationMenuCategoriesFontSize
        );
      }

      const customizationMenuCategoriesFontWeight =
        context.getters.customizationMenuCategoriesFontWeight;
      if (customizationMenuCategoriesFontWeight) {
        document.documentElement.style.setProperty(
          '--menu-categories-font-weight',
          customizationMenuCategoriesFontWeight
        );
        document.documentElement.style.setProperty(
          '--menu-categories-slider-font-weight',
          customizationMenuCategoriesFontWeight
        );
      }

      const customizationMenuCategoriesFontColor =
        context.getters.customizationMenuCategoriesFontColor;
      if (customizationMenuCategoriesFontColor) {
        document.documentElement.style.setProperty(
          '--menu-categories-font-color',
          customizationMenuCategoriesFontColor
        );
      }

      const customizationMenuCategoriesTextShadow =
        context.getters.customizationMenuCategoriesTextShadow;
      if (customizationMenuCategoriesTextShadow) {
        document.documentElement.style.setProperty(
          '--menu-categories-text-shadow',
          customizationMenuCategoriesTextShadow
        );
      }

      const customizationMenuCategoriesTextTransform =
        context.getters.customizationMenuCategoriesTextTransform;
      if (customizationMenuCategoriesTextTransform) {
        document.documentElement.style.setProperty(
          '--menu-categories-text-transform',
          customizationMenuCategoriesTextTransform
        );
      }

      const customizationMenuCategoryDescriptionFontSize =
        context.getters.customizationMenuCategoryDescriptionFontSize;
      if (customizationMenuCategoryDescriptionFontSize) {
        document.documentElement.style.setProperty(
          '--menu-category-description-font-size',
          customizationMenuCategoryDescriptionFontSize
        );
      }

      const customizationMenuCategoryDescriptionFontWeight =
        context.getters.customizationMenuCategoryDescriptionFontWeight;
      if (customizationMenuCategoryDescriptionFontWeight) {
        document.documentElement.style.setProperty(
          '--menu-category-description-font-weight',
          customizationMenuCategoryDescriptionFontWeight
        );
      }

      const customizationMenuCategoryDescriptionColor =
        context.getters.customizationMenuCategoryDescriptionColor;
      if (customizationMenuCategoryDescriptionColor) {
        document.documentElement.style.setProperty(
          '--menu-category-description-color',
          customizationMenuCategoryDescriptionColor
        );
      }

      const customizationMenuCategoriesBackgroundColor =
        context.getters.customizationMenuCategoriesBackgroundColor;
      if (customizationMenuCategoriesBackgroundColor) {
        document.documentElement.style.setProperty(
          '--menu-categories-background-color',
          customizationMenuCategoriesBackgroundColor
        );
      }

      const customizationSelectScreenRestaurantTitleColor =
        context.getters.customizationSelectScreenRestaurantTitleColor;
      if (customizationSelectScreenRestaurantTitleColor) {
        document.documentElement.style.setProperty(
          '--select-screen-restaurant-title-color',
          customizationSelectScreenRestaurantTitleColor
        );
      }

      const customizationSelectScreenButtonsBackgroundColor =
        context.getters.customizationSelectScreenButtonsBackgroundColor;
      if (customizationSelectScreenButtonsBackgroundColor) {
        document.documentElement.style.setProperty(
          '--select-screen-buttons-background-color',
          customizationSelectScreenButtonsBackgroundColor
        );
      }

      const customizationSelectScreenButtonsTextColor =
        context.getters.customizationSelectScreenButtonsTextColor;
      if (customizationSelectScreenButtonsTextColor) {
        document.documentElement.style.setProperty(
          '--select-screen-buttons-text-color',
          customizationSelectScreenButtonsTextColor
        );
      }

      const customizationSelectScreenButtonsBorderRadius =
        context.getters.customizationSelectScreenButtonsBorderRadius;
      if (customizationSelectScreenButtonsBorderRadius) {
        document.documentElement.style.setProperty(
          '--select-screen-buttons-border-radius',
          customizationSelectScreenButtonsBorderRadius
        );
      }

      const customizationMenuCategoriesLetterSpacing =
        context.getters.customizationMenuCategoriesLetterSpacing;
      if (customizationMenuCategoriesLetterSpacing) {
        document.documentElement.style.setProperty(
          '--menu-categories-letter-spacing',
          customizationMenuCategoriesLetterSpacing
        );
      }

      const customizationMenuCategoriesFontStyle =
        context.getters.customizationMenuCategoriesFontStyle;
      if (customizationMenuCategoriesFontStyle) {
        document.documentElement.style.setProperty(
          '--menu-categories-font-style',
          customizationMenuCategoriesFontStyle
        );
      }

      const customizationMenuItemsDividerColor =
        context.getters.customizationMenuItemsDividerColor;
      if (customizationMenuItemsDividerColor) {
        document.documentElement.style.setProperty(
          '--menu-items-divider-color',
          customizationMenuItemsDividerColor
        );
      }

      const customizationQuickAccessMenuButtonsBackgroundColor =
        context.getters.customizationQuickAccessMenuButtonsBackgroundColor;
      if (customizationQuickAccessMenuButtonsBackgroundColor) {
        document.documentElement.style.setProperty(
          '--quick-access-menu-buttons-background-color',
          customizationQuickAccessMenuButtonsBackgroundColor
        );
      }

      const customizationMenuFilterCardsColor =
        context.getters.customizationMenuFilterCardsColor;
      if (customizationMenuFilterCardsColor) {
        document.documentElement.style.setProperty(
          '--menu-filter-cards-color',
          customizationMenuFilterCardsColor
        );
      }

      const customizationMenuFilterCardsTextColor =
        context.getters.customizationMenuFilterCardsTextColor;
      if (customizationMenuFilterCardsTextColor) {
        document.documentElement.style.setProperty(
          '--menu-filter-cards-text-color',
          customizationMenuFilterCardsTextColor
        );
      }

      const customizationMenuCategoryDividerColor =
        context.getters.customizationMenuCategoryDividerColor;
      if (customizationMenuCategoryDividerColor) {
        document.documentElement.style.setProperty(
          '--menu-category-divider-color',
          customizationMenuCategoryDividerColor
        );
      }

      const customizationMenuCategoryDividerHeight =
        context.getters.customizationMenuCategoryDividerHeight;
      if (customizationMenuCategoryDividerHeight) {
        document.documentElement.style.setProperty(
          '--menu-category-divider-height',
          customizationMenuCategoryDividerHeight
        );
      }

      const customizationMenuItemsPriceFontFamily =
        context.getters.customizationMenuItemsPriceFontFamily;
      if (customizationMenuItemsPriceFontFamily) {
        document.documentElement.style.setProperty(
          '--menu-items-price-font-family',
          customizationMenuItemsPriceFontFamily
        );
      }

      const customizationMenuItemsTitleLineHeight =
        context.getters.customizationMenuItemsTitleLineHeight;
      if (customizationMenuItemsTitleLineHeight) {
        document.documentElement.style.setProperty(
          '--menu-items-title-line-height',
          customizationMenuItemsTitleLineHeight
        );
      }

      const customizationMenuItemsTitleMaxHeight =
        context.getters.customizationMenuItemsTitleMaxHeight;
      if (customizationMenuItemsTitleMaxHeight) {
        document.documentElement.style.setProperty(
          '--menu-items-title-max-height',
          customizationMenuItemsTitleMaxHeight
        );
      }

      const customizationMenuRestaurantTitleFontSize =
        context.getters.customizationMenuRestaurantTitleFontSize;
      if (customizationMenuRestaurantTitleFontSize) {
        document.documentElement.style.setProperty(
          '--menu-restaurant-title-font-size',
          customizationMenuRestaurantTitleFontSize
        );
      }

      const customizationMenuRestaurantTitleFontWeight =
        context.getters.customizationMenuRestaurantTitleFontWeight;
      if (customizationMenuRestaurantTitleFontWeight) {
        document.documentElement.style.setProperty(
          '--menu-restaurant-title-font-weight',
          customizationMenuRestaurantTitleFontWeight
        );
      }

      const customizationMenuRestaurantTitleFontColor =
        context.getters.customizationMenuRestaurantTitleFontColor;
      if (customizationMenuRestaurantTitleFontColor) {
        document.documentElement.style.setProperty(
          '--menu-restaurant-title-font-color',
          customizationMenuRestaurantTitleFontColor
        );
      }

      const customizationMenuRestaurantDescriptionFontSize =
        context.getters.customizationMenuRestaurantDescriptionFontSize;
      if (customizationMenuRestaurantDescriptionFontSize) {
        document.documentElement.style.setProperty(
          '--menu-restaurant-description-font-size',
          customizationMenuRestaurantDescriptionFontSize
        );
      }

      const customizationMenuRestaurantDescriptionFontWeight =
        context.getters.customizationMenuRestaurantDescriptionFontWeight;
      if (customizationMenuRestaurantDescriptionFontWeight) {
        document.documentElement.style.setProperty(
          '--menu-restaurant-description-font-weight',
          customizationMenuRestaurantDescriptionFontWeight
        );
      }

      const customizationMenuRestaurantDescriptionFontColor =
        context.getters.customizationMenuRestaurantDescriptionFontColor;
      if (customizationMenuRestaurantDescriptionFontColor) {
        document.documentElement.style.setProperty(
          '--menu-restaurant-description-font-color',
          customizationMenuRestaurantDescriptionFontColor
        );
      }

      const customizationMenuCategorySliderInactiveColor =
        context.getters.customizationMenuCategorySliderInactiveColor;
      if (customizationMenuCategorySliderInactiveColor) {
        document.documentElement.style.setProperty(
          '--menu-category-slider-inactive-color',
          customizationMenuCategorySliderInactiveColor
        );
      }
    },
  },
  mutations: {
    SET_CUSTOMIZATIONS(state, customizations) {
      state.customizations = customizations;
    },
  },
  getters: {
    customizationPrimaryColor: function(state) {
      const primaryColorCustomization = state.customizations.find(
        customization => {
          return customization.name === 'primary-color';
        }
      );
      return primaryColorCustomization?.value || null;
    },
    customizationColorTextMain: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'color-text-main';
      });
      return customization?.value || null;
    },
    customizationThemeColor: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'theme-color';
      });
      return customization?.value || null;
    },
    customizationLoyaltyCardIcon: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'loyalty-card-icon';
      });
      return customization?.value || null;
    },
    customizationLogo: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'title-logo';
      });
      return customization?.value || null;
    },
    customizationOrderInfoStatusPendingDisclaimerText: function(state) {
      const customization = state.customizations.find(customization => {
        return (
          customization.name === 'order-info-status-pending-disclaimer-text'
        );
      });
      return customization?.value || null;
    },
    customizationOrderInfoStatusPreparingDisclaimerText: function(state) {
      const customization = state.customizations.find(customization => {
        return (
          customization.name === 'order-info-status-preparing-disclaimer-text'
        );
      });
      return customization?.value || null;
    },
    customizationOrderInfoStatusPartialDeliveryDisclaimerText: function(state) {
      const customization = state.customizations.find(customization => {
        return (
          customization.name ===
          'order-info-status-partial-delivery-disclaimer-text'
        );
      });
      return customization?.value || null;
    },
    customizationOrderInfoStatusClosedDisclaimerText: function(state) {
      const customization = state.customizations.find(customization => {
        return (
          customization.name === 'order-info-status-closed-disclaimer-text'
        );
      });
      return customization?.value || null;
    },
    customizationExtraDetailsDisclaimerTitle: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'extra-details-disclaimer-title';
      });
      return customization?.value || null;
    },
    customizationExtraDetailsDisclaimerSubtitle: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'extra-details-disclaimer-subtitle';
      });
      return customization?.value || null;
    },
    customizationMenuHideRestaurantTitle: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-hide-restaurant-title';
      });
      return customization?.value || false;
    },
    customizationMenuShowRestaurantDescription: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-show-restaurant-description';
      });
      return customization?.value || false;
    },
    customizationMenuHideLayoutCards: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-hide-layout-cards';
      });
      return customization?.value || false;
    },
    customizationMenuHideLayoutCardsForBanners: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-hide-layout-cards-for-banners';
      });
      return customization?.value || false;
    },
    customizationMenuBackgroundColor: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-background-color';
      });
      return customization?.value || null;
    },
    customizationMenuBackgroundImage: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-background-image';
      });
      return customization?.value || null;
    },
    customizationMenuShortDescriptionFontColor: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-short-description-font-color';
      });
      return customization?.value || null;
    },
    customizationMenuShortDescriptionFontWeight: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-short-description-font-weight';
      });
      return customization?.value || null;
    },
    customizationMenuShortDescriptionFontSize: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-short-description-font-size';
      });
      return customization?.value || null;
    },
    customizationMenuFontOverrideFamily: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-font-override-family';
      });
      return customization?.value || null;
    },
    customizationMenuFontOverrideLink: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-font-override-link';
      });
      return customization?.value || null;
    },
    customizationMenuCategoriesFontOverrideFamily: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-categories-font-override-family';
      });
      return customization?.value || null;
    },
    customizationMenuCategoriesFontOverrideLink: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-categories-font-override-link';
      });
      return customization?.value || null;
    },
    customizationMenuItemDescriptionFontOverrideFamily: function(state) {
      const customization = state.customizations.find(customization => {
        return (
          customization.name === 'menu-item-description-font-override-family'
        );
      });
      return customization?.value || null;
    },
    customizationMenuItemDescriptionFontStyle: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-item-description-font-style';
      });
      return customization?.value || null;
    },
    customizationMenuItemDescriptionLetterSpacing: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-item-description-letter-spacing';
      });
      return customization?.value || null;
    },
    customizationMenuHeaderCoverContain: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-header-cover-contain';
      });
      return customization?.value || false;
    },
    customizationMenuHeaderCoverHeight: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-header-cover-height';
      });
      return customization?.value || null;
    },
    customizationMenuItemsFontWeight: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-items-font-weight';
      });
      return customization?.value || null;
    },
    customizationMenuItemsFontColor: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-items-font-color';
      });
      return customization?.value || null;
    },
    customizationMenuItemsPriceFontWeight: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-items-price-font-weight';
      });
      return customization?.value || null;
    },
    customizationMenuItemsPriceFontColor: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-items-price-font-color';
      });
      return customization?.value || null;
    },
    customizationMenuItemsPriceFontSize: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-items-price-font-size';
      });
      return customization?.value || null;
    },
    customizationMenuItemsPriceFontStyle: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-items-price-font-style';
      });
      return customization?.value || null;
    },
    customizationMenuItemsPriceVerticalAlignCenter: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-items-price-vertical-align-center';
      });
      return customization?.value || false;
    },
    customizationMenuItemsTitleLetterSpacing: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-items-title-letter-spacing';
      });
      return customization?.value || null;
    },
    customizationMenuCategoriesFontSize: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-categories-font-size';
      });
      return customization?.value || null;
    },
    customizationMenuCategoriesFontWeight: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-categories-font-weight';
      });
      return customization?.value || null;
    },
    customizationMenuCategoriesFontColor: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-categories-font-color';
      });
      return customization?.value || null;
    },
    customizationMenuCategoriesTextShadow: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-categories-text-shadow';
      });
      return customization?.value || null;
    },
    customizationMenuCategoriesBackgroundColor: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-categories-background-color';
      });
      return customization?.value || null;
    },
    customizationMenuCategoryDescriptionFontSize: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-category-description-font-size';
      });
      return customization?.value || null;
    },
    customizationMenuCategoryDescriptionFontWeight: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-category-description-font-weight';
      });
      return customization?.value || null;
    },
    customizationMenuCategoryDescriptionColor: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-category-description-color';
      });
      return customization?.value || null;
    },
    customizationMenuCategoriesCenter: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-categories-center';
      });
      return customization?.value || false;
    },
    customizationMenuDarkMode: function(state, getters) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-dark-mode';
      });

      if (
        customization?.value ||
        (getters.customizationMenuBackgroundColor &&
          lightOrDarkColor(getters.customizationMenuBackgroundColor) === 'dark')
      ) {
        return true;
      }

      return false;
    },
    customizationMenuCategoriesRotatingFontColors: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-categories-font-rotating-colors';
      });
      return customization?.value.split(';') || null;
    },
    customizationMenuItemsDivider: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-items-divider';
      });
      return customization?.value || false;
    },
    customizationMenuCategoriesSectionsRotatingBackgroundColors: function(
      state
    ) {
      const customization = state.customizations.find(customization => {
        return (
          customization.name === 'menu-categories-sections-rotating-bg-colors'
        );
      });
      return customization?.value.split(';') || null;
    },
    customizationMenuCategoryDividerEnabled: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-category-divider-enabled';
      });
      return customization?.value || false;
    },
    customizationSelectScreenRestaurantTitleColor: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'select-screen-restaurant-title-color';
      });
      return customization?.value || null;
    },
    customizationSelectScreenButtonsBackgroundColor: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'select-screen-buttons-background-color';
      });
      return customization?.value || null;
    },
    customizationSelectScreenButtonsTextColor: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'select-screen-buttons-text-color';
      });
      return customization?.value || null;
    },
    customizationSelectScreenHideRestaurantTitle: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'select-screen-hide-restaurant-title';
      });
      return customization?.value || false;
    },
    customizationSelectScreenHideTableName: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'select-screen-hide-table-name';
      });
      return customization?.value || false;
    },
    customizationSelectScreenViewMenuText: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'select-screen-view-menu-text';
      });
      return customization?.value || false;
    },
    customizationHideQuickAccessMenuTitle: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'quick-access-menu-hide-title';
      });
      return customization?.value || false;
    },
    customizationMenuCategoriesLetterSpacing: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-categories-letter-spacing';
      });
      return customization?.value || false;
    },
    customizationMenuItemsDividerColor: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-items-divider-color';
      });
      return customization?.value || false;
    },
    customizationMenuItemsDividerSkipFirst: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-items-divider-skip-first';
      });
      return customization?.value || false;
    },
    customizationQuickAccessMenuButtonsBackgroundColor: function(state) {
      const customization = state.customizations.find(customization => {
        return (
          customization.name === 'quick-access-menu-buttons-background-color'
        );
      });
      return customization?.value || null;
    },
    customizationOrderInfoHideReviews: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'order-info-hide-reviews';
      });
      return customization?.value || false;
    },
    customizationOrderInfoCustomOrderStatusText: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'order-info-custom-order-status-text';
      });
      return customization?.value || null;
    },
    customizationOrderInfoContactButtonText: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'order-info-contact-button-text';
      });
      return customization?.value || null;
    },
    customizationOrderInfoHideRestaurantInfo: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'order-info-hide-restaurant-info';
      });
      return customization?.value || false;
    },
    customizationEmptyCartScreenDescriptionText: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'empty-cart-screen-description-text';
      });
      return customization?.value || null;
    },
    customizationOrderInfoAdditionalSection1Title: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'order-info-additional-section-1-title';
      });
      return customization?.value || null;
    },
    customizationOrderInfoAdditionalSection1ImageUrl: function(state) {
      const customization = state.customizations.find(customization => {
        return (
          customization.name === 'order-info-additional-section-1-image-url'
        );
      });
      return customization?.value || null;
    },
    customizationOrderInfoAdditionalSection1Text: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'order-info-additional-section-1-text';
      });
      return customization?.value || null;
    },
    customizationCollectiveTipsDefaultSelectionIndex: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'collective-tips-default-selection-index';
      });
      if (customization?.value != null) {
        return parseInt(customization?.value);
      } else {
        return null;
      }
    },
    customizationCollectiveTipsRequiredGuestCountThreshold: function(state) {
      const customization = state.customizations.find(customization => {
        return (
          customization.name ===
          'collective-tips-required-guest-count-threshold'
        );
      });
      if (customization?.value != null) {
        return parseInt(customization?.value);
      } else {
        return null;
      }
    },
    customizationMenuLayoutCardColor: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-layout-card-color';
      });
      return customization?.value || null;
    },
    customizationMenuLayoutCardRadius: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-layout-card-radius';
      });
      return customization?.value || null;
    },
    customizationMenuLayoutCardBorderEnabled: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-layout-card-border-enabled';
      });
      return customization?.value || false;
    },
    customizationMenuLayoutCardBorderColor: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-layout-card-border-color';
      });
      return customization?.value || null;
    },
    customizationMenuFilterCardsColor: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-filter-cards-color';
      });
      return customization?.value || null;
    },
    customizationMenuFilterCardsTextColor: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-filter-cards-text-color';
      });
      return customization?.value || null;
    },
    customizationMenuFilterCardsTextCenter: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-filter-cards-text-center';
      });
      return customization?.value || false;
    },
    customizationMenuCategoryDoubleDividerEnabled: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-category-double-divider-enabled';
      });
      return customization?.value || false;
    },
    customizationMenuCategoryDividerColor: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-category-divider-color';
      });
      return customization?.value || null;
    },
    customizationMenuCategoryDividerStyle: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-category-divider-style';
      });
      return customization?.value || null;
    },
    customizationMenuCategoryDividerHeight: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-category-divider-height';
      });
      return customization?.value || null;
    },
    customizationMenuPlaceOrderButtonWhite: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-place-order-button-white';
      });
      return customization?.value || false;
    },
    customizationMenuCategoriesFontStyle: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-categories-font-style';
      });
      return customization?.value || null;
    },
    customizationMenuBannerFullWidth: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-banner-full-width';
      });
      return customization?.value || false;
    },
    customizationMenuHideCategoryTitle: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-hide-category-title';
      });
      return customization?.value || false;
    },
    customizationMenuItemsFontSize: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-items-font-size';
      });
      return customization?.value || null;
    },
    customizationMenuItemsFontStyle: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-items-font-style';
      });
      return customization?.value || null;
    },
    customizationMenuItemsPriceFontFamily: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-items-price-font-family';
      });
      return customization?.value || null;
    },
    customizationMenuPlaceOrderButtonBackgroundColor: function(state) {
      const customization = state.customizations.find(customization => {
        return (
          customization.name === 'menu-place-order-button-background-color'
        );
      });
      return customization?.value || null;
    },
    customizationMenuPlaceOrderButtonTextColor: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-place-order-button-text-color';
      });
      return customization?.value || null;
    },
    customizationMenuItemsTitleLineHeight: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-items-title-line-height';
      });
      return customization?.value || null;
    },
    customizationMenuItemsTitleMaxHeight: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-items-title-max-height';
      });
      return customization?.value || null;
    },
    customizationQuickAccessMenuTitleText: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'quick-access-menu-title-text';
      });
      return customization?.value || null;
    },
    customizationMenuHideSearch: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-hide-search';
      });
      return customization?.value || false;
    },
    customizationMenuRestaurantTitleFontSize: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-restaurant-title-font-size';
      });
      return customization?.value || null;
    },
    customizationMenuRestaurantTitleFontWeight: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-restaurant-title-font-weight';
      });
      return customization?.value || null;
    },
    customizationMenuRestaurantTitleFontColor: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-restaurant-title-font-color';
      });
      return customization?.value || null;
    },
    customizationMenuRestaurantDescriptionFontSize: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-restaurant-description-font-size';
      });
      return customization?.value || null;
    },
    customizationMenuRestaurantDescriptionFontWeight: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-restaurant-description-font-weight';
      });
      return customization?.value || null;
    },
    customizationMenuRestaurantDescriptionFontColor: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-restaurant-description-font-color';
      });
      return customization?.value || null;
    },
    customizationMenuItemAddToCardButtonColor: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-item-add-to-cart-button-color';
      });
      return customization?.value || null;
    },
    customizationMenuShortDescriptionRotatingCategoryColorsEnabled: function(
      state
    ) {
      const customization = state.customizations.find(customization => {
        return (
          customization.name ===
          'menu-short-description-rotating-category-colors-enabled'
        );
      });
      return customization?.value || false;
    },
    customizationMenuGoToCategoryAdditionalOffset: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-go-to-category-additional-offset';
      });
      return customization?.value || false;
    },
    customizationSelectScreenHideViewMenuButton: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'select-screen-hide-view-menu-button';
      });
      return customization?.value || false;
    },
    customizationSelectScreenButtonsBorderRadius: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'select-screen-buttons-border-radius';
      });
      return customization?.value || null;
    },
    customizationMenuCategorySliderInactiveColor: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-category-slider-inactive-color';
      });
      return customization?.value || null;
    },
    customizationMenuCategoriesTextTransform: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-categories-text-transform';
      });
      return customization?.value || null;
    },
    customizationMenuItemsTextTransform: function(state) {
      const customization = state.customizations.find(customization => {
        return customization.name === 'menu-items-text-transform';
      });
      return customization?.value || null;
    },
    loadingScreenCustomizations: function(state, getters) {
      const findByKey = key =>
        state.customizations.find(customization => customization.name === key);
      return {
        language: getters.mainLanguage,
        background_color: findByKey('loader-screen-background-color')?.value,
        background_image: findByKey('loader-screen-background-image')?.value,
        logo: findByKey('loader-screen-logo')?.value,
        logo_width: findByKey('loader-screen-logo-width')?.value,
        logo_height: findByKey('loader-screen-logo-height')?.value,
        loader: findByKey('loader-screen-loader')?.value,
        loader_height: findByKey('loader-screen-loader-height')?.value,
        loader_color: findByKey('loader-screen-loader-color')?.value,
        dark_powered_by: findByKey('dark-powered-by')?.value,
        powered_by_similar_bg: findByKey('powered-by-similar-bg')?.value,
      };
    },
  },
};
