<template>
  <LayoutCard v-if="!enrolledLoyaltyProgram" rounded>
    <div class="d-flex" @click="navigateToRegister">
      <LoyaltyCard
        :small="true"
        :loyalty-program="restaurantLoyaltyProgram"
        width="64px"
        height="41px"
      />
      <div class="tp-text-body-gray pl-3 mx-auto">
        {{
          $t('screens.placeOrder.registerToSaveContent', { restaurantName })
        }}
      </div>
      <v-icon @click="navigateToRegister">
        mdi-chevron-right
      </v-icon>
    </div>
  </LayoutCard>
</template>

<script>
import LoyaltyCard from '@/components/Loyalty/LoyaltyCard.vue';
import LayoutCard from '@/components/LayoutCard.vue';

export default {
  components: {
    LoyaltyCard,
    LayoutCard,
  },
  computed: {
    enrolledLoyaltyProgram() {
      return this.$store.getters.enrolledRestaurantLoyaltyProgram;
    },
    restaurantLoyaltyProgram() {
      return this.$store.getters.restaurantLoyaltyProgram();
    },
    restaurantName() {
      return this.$store.state.restaurant.name;
    },
    amountWithLoyaltyDiscount() {
      return this.$store.getters.cartAmountWithLoyaltyPricingFormatted;
    },
    // TODO Might need to use this later. If not remove it.
    potentialDiscountAmount() {
      return this.$store.getters.potentialDiscountAmount;
    },
    loyaltyDiscountAmount() {
      return this.$store.getters.getFormattedPrice(
        this.potentialDiscountAmount
      );
    },
  },
  methods: {
    navigateToRegister: function() {
      this.$router.push({
        name: 'LoyaltyPreview',
        params: {
          session: this.$store.state.session,
        },
      });
    },
  },
};
</script>

<style></style>
