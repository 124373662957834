import { render, staticRenderFns } from "./FloatingAvatars.vue?vue&type=template&id=549eb9cd&scoped=true"
import script from "./FloatingAvatars.vue?vue&type=script&lang=js"
export * from "./FloatingAvatars.vue?vue&type=script&lang=js"
import style0 from "./FloatingAvatars.vue?vue&type=style&index=0&id=549eb9cd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "549eb9cd",
  null
  
)

export default component.exports