<template>
  <div>
    <div class="d-flex justify-space-between">
      <span class="tp-text-body">
        {{ $t('screens.payment.payGoSummaryAmountLabel') }}
      </span>
      <span class="tp-text-body">
        {{ subtotalFormatted }}
      </span>
    </div>
    <div v-if="discountAmount" class="d-flex justify-space-between pt-3">
      <span class="tp-text-body">
        <v-icon color="primary">mdi-tag-outline</v-icon>
        {{ couponCode ? couponCode : $t('screens.payment.discountLabel') }}
      </span>
      <span class="tp-text-body"> -{{ discountAmountFormatted }} </span>
    </div>
    <!-- <div v-if="transactionFee" class="d-flex justify-space-between pt-3">
      <span class="tp-text-body">
        {{ $t('screens.payment.summaryFeeLabel') }}
      </span>
      <span class="tp-text-body">
        {{ feeFormatted }}
      </span>
    </div> -->
    <!-- <div v-if="smallOrderFee" class="d-flex justify-space-between pt-3">
      <span class="tp-text-body">
        {{ $t('screens.payment.summarySmallOrderFeeLabel') }}
      </span>
      <span class="tp-text-body">
        {{ smallOrderFeeFormatted }}
      </span>
    </div> -->
    <!-- <div v-if="tipsAmount" class="d-flex justify-space-between pt-3">
      <span class="tp-text-body">
        {{ $t('screens.payment.summaryTipsLabel') }}
      </span>
      <span class="tp-text-body">
        {{ tipsFormatted }}
      </span>
    </div> -->

    <div v-if="payments && payments.length > 0">
      <div v-for="(payment, index) in payments" :key="`payment-${index}`" class="d-flex justify-space-between mt-3">
        <span class="tp-text-body">
          {{ $t('screens.payment.paymentLabel') + ` #${index + 1}` }}
        </span>
        <span class="tp-text-body">
          {{ $store.getters.getFormattedPrice(payment.amount) }}
        </span>
      </div>
    </div>

    <div
      class="d-flex justify-space-between mt-3"
    >
      <span class="tp-text-subtitle font-weight-bold">
        {{ cartTotalTitle }}
      </span>
      <span class="tp-text-subtitle font-weight-bold">
        {{ leftToPayAmountFormatted }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    subtotalAmount: {
      type: Number,
      default: null,
    },
    totalAmount: {
      type: Number,
      default: null,
    },
    transactionFee: {
      type: Number,
      default: null,
    },
    tipsAmount: {
      type: Number,
      default: null,
    },
    discountAmount: {
      type: Number,
      default: null,
    },
    paidAmount: {
      type: Number,
      default: null,
    },
    couponCode: {
      type: String,
      default: null,
    },
    smallOrderFee: {
      type: Number,
      default: null,
    },
    payments: {
      type: [Array, Object],
      default: null,
    },
  },
  methods: {

  },
  computed: {
    subtotalFormatted() {
      return this.$store.getters.getFormattedPrice(this.subtotalAmount);
    },
    feeFormatted() {
      return this.$store.getters.getFormattedPrice(this.transactionFee);
    },
    tipsFormatted() {
      return this.$store.getters.getFormattedPrice(this.tipsAmount);
    },
    totalAmountFormatted() {
      return this.$store.getters.getFormattedPrice(this.totalAmount);
    },
    discountAmountFormatted() {
      return this.$store.getters.getFormattedPrice(this.discountAmount);
    },
    smallOrderFeeFormatted() {
      return this.$store.getters.getFormattedPrice(this.smallOrderFee);
    },
    showCartOrderValue() {
      return (
        this.tipsAmount ||
        this.discountAmount ||
        this.transactionFee ||
        this.smallOrderFee
      );
    },
    cartTotalTitle() {
      return this.$t('components.cartSubtotal.leftToPay');
    },
    leftToPayAmountFormatted() {
      return this.$store.getters.getFormattedPrice(
        this.subtotalAmount - this.paidAmount
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
