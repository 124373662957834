var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"menu__header",class:{
      'mb-3':
        _vm.$store.getters.customizationMenuHideRestaurantTitle &&
        !_vm.$store.getters.customizationMenuShowRestaurantDescription,
    }},[_c('HeaderImage'),_c('div',{staticClass:"menu-header-buttons"},[(_vm.showBack)?_c('v-icon',{staticClass:"back-icon",attrs:{"data-cy":"menu-back"},on:{"click":_vm.onBack}},[_vm._v(" mdi-arrow-left ")]):_vm._e(),(_vm.isSelfService)?_c('v-btn',{staticClass:"menu__more-information-icon mr-3",attrs:{"fab":"","small":"","icon":"","data-cy":"reset-self-service-cta"},on:{"click":_vm.startNewSelfServiceSession}},[(_vm.resetting)?_c('v-progress-circular',{attrs:{"size":"20","width":"2","indeterminate":""}}):_c('v-icon',[_vm._v("mdi-refresh")])],1):_vm._e(),(_vm.loyaltyProgramEnabled)?_c('v-btn',{staticClass:"menu__more-information-icon mr-3",attrs:{"fab":"","small":""},on:{"click":_vm.navigateToProfile}},[_c('v-avatar',{attrs:{"size":36,"data-cy":"login"}},[(_vm.userPhotoUrl)?_c('img',{attrs:{"src":_vm.userPhotoUrl,"alt":_vm.user?.email}}):_c('v-icon',{attrs:{"color":"#5F5F5F"}},[_vm._v(" mdi-account-circle ")])],1)],1):_vm._e(),(_vm.$store.getters.restaurantHasLanguages)?_c('v-btn',{staticClass:"menu__more-information-icon",class:{ 'mr-3': !_vm.$store.getters.customizationMenuHideSearch },attrs:{"fab":"","small":"","icon":""},on:{"click":_vm.toggleLanguageSelector}},[_c('img',{staticStyle:{"width":"22px"},attrs:{"src":_vm.getLanguageImageByCode(_vm.$i18n.locale())}})]):_vm._e(),(!_vm.$store.getters.customizationMenuHideSearch)?_c('v-btn',{staticClass:"menu__more-information-icon",attrs:{"fab":"","small":"","icon":""},on:{"click":_vm.openSearch}},[_c('v-icon',[_vm._v("mdi-magnify")])],1):_vm._e()],1),(
        !_vm.$store.getters.customizationMenuHideRestaurantTitle ||
          _vm.$store.getters.customizationMenuShowRestaurantDescription
      )?_c('LayoutCard',{staticClass:"mb-3",attrs:{"type":`header`,"hide-background":!!_vm.$store.getters.customizationMenuHideLayoutCards}},[(!_vm.$store.getters.customizationMenuHideRestaurantTitle)?_c('HeaderRestaurantTitle',{attrs:{"data-cy":"restaurant-title"}}):_vm._e(),_c('HeaderRestaurantDescription',{attrs:{"data-cy":"restaurant-desc"}})],1):_vm._e(),_c('CollapsingTopBar',{attrs:{"show-back":_vm.showBack,"show-category-slider":_vm.showCategorySlider}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }