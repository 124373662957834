<template>
  <v-bottom-sheet v-model="sheet" inset :max-width="maxAppWidth">
    <v-sheet class="px-4 pt-10 pb-8" rounded="xl b-0">
      <div class="d-flex justify-center">
        <v-img
          src="/assets/icons/pis-warning.svg"
          max-height="100px"
          max-width="100px"
          min-width="100px"
          min-height="100px"
        />
      </div>
      <div class="tp-text-body mb-6 mt-8" style="white-space: pre-line;">
        <div class="tp-text-title-bold mb-3">
          {{ $t('components.pisWarning.title') }}
        </div>
        {{ $t('components.pisWarning.content') }}
      </div>
      <Button dark no-shadow data-cy="pis-warning-cta" @click="pressPay">
        {{ $t('components.pisWarning.cta') }}
      </Button>
      <Button
        gray
        no-shadow
        class="mt-3"
        data-cy="pis-warning-cancel"
        @click="close"
      >
        {{ $t('generic.cancel') }}
      </Button>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import Button from '@/components/Button.vue';

export default {
  components: {
    Button,
  },
  props: {
    onCtaPress: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    sheet: false,
  }),
  methods: {
    close() {
      this.sheet = false;
    },
    open() {
      this.sheet = true;
    },
    async pressPay() {
      this.$emit('pay');
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped></style>
