<template>
  <div class="orders">
    <NavHeader
      :title="$t('screens.orders.headerTitle')"
      @onBackClick="goBack"
    />
    <LayoutCard v-if="getActiveOrders.length > 0">
      <div class="tp-text-subtitle-semibold mb-6">
        {{ $t('screens.orders.ongoingOrders') }}
      </div>
      <div class="orders__list">
        <OrderInList
          v-bind="order"
          v-for="(order, key) in getActiveOrders"
          :key="key"
          @click="openOrder"
        ></OrderInList>
      </div>
    </LayoutCard>
    <LayoutCard rounded v-if="getCompletedOrders.length > 0">
      <div class="tp-text-subtitle-semibold mb-6">
        {{ $t('screens.orders.completedOrders') }}
      </div>
      <div class="orders__list">
        <OrderInList
          v-bind="order"
          v-for="(order, key) in getCompletedOrders"
          :key="key"
          @click="openOrder"
        ></OrderInList>
      </div>
    </LayoutCard>

    <Transition name="slide-from-bottom">
      <OrderInfo v-if="selectedOrder" v-bind="selectedOrder"></OrderInfo>
    </Transition>
    <Transition name="opacity">
      <div v-if="selectedOrder" class="backdrop"></div>
    </Transition>
  </div>
</template>

<script>
import LayoutCard from '@/components/LayoutCard.vue';
import NavHeader from '@/components/Navigation/NavHeader.vue';
import OrderInList from '@/components/Orders/OrderInList.vue';
import OrderInfo from '@/components/Orders/OrdersView/OrderInfo.vue';

export default {
  components: {
    LayoutCard,
    NavHeader,
    OrderInList,
    OrderInfo,
  },
  computed: {
    getActiveOrders() {
      const orders = this.$store.getters.getOrders;
      return orders.filter(order => {
        return order.status !== 'closed';
      });
    },
    getCompletedOrders() {
      const orders = this.$store.getters.getOrders;
      return orders.filter(order => {
        return order.status === 'closed';
      });
    },
    selectedOrder() {
      const { getOrderByUniqueOrderNumber } = this.$store.getters;
      return getOrderByUniqueOrderNumber(this.$store.state.openedOrder);
    },
    ordersDisabled() {
      return (
        !this.$store.getters.functionalityOrderEnabled &&
        !this.$store.getters.functionalityPayGoEnabled
      );
    },
    noActiveOrdersAndCompleted() {
      return (
        this.getActiveOrders.length == 0 && this.getCompletedOrders.length == 0
      );
    },
  },
  async mounted() {
    const sessionToken = this.$route.params.session;
    this.isLoading = true;
    await this.$store.dispatch('fetchSessionData', { session: sessionToken });
    if (this.ordersDisabled || this.noActiveOrdersAndCompleted) {
      this.$router.push({
        name: 'Menu',
        params: { session: sessionToken },
      });
      return;
    }
    await this.$store.dispatch('fetchOrders');
    this.$store.dispatch('subscribeToPusherChannels');

    this.isLoading = false;
  },
  methods: {
    goBack() {
      this.$router.push({
        name: 'Menu',
        params: { session: this.$store.state.session },
      });
    },

    openOrder(order_number) {
      this.$store.dispatch('openOrder', order_number);
    },
  },
};
</script>

<style lang="scss" scoped>
.orders {
  background-color: $color-background-gray !important;
  height: 100%;
}

.order-item {
  display: flex;
  justify-content: space-between;
}
</style>
