<template>
  <div>
    <div
      v-if="donationQrCodeUnavailable"
      :style="{ height: '100vh' }"
      :data-cy="`donate-empty-state`"
      class="d-flex flex-column justify-center align-center white"
    >
      <StateScreen
        :title="$t('screens.donate.empty.title')"
        :subtitle="$t('screens.donate.empty.subtitle')"
        :image="require(`@/assets/images/states/sad-mascot.png`)"
      />
    </div>
    <div v-else>
      <div
        v-if="isLoading"
        :style="{ height: '100vh' }"
        class="py-6 d-flex justify-center align-center"
      >
        <v-progress-circular indeterminate color="black" />
      </div>
      <div v-else>
        <form id="payment-form" @submit="payByCard">
          <LayoutCard v-if="donatee" style="border-radius: 0 0 20px 20px;">
            <TipsHeader :color-override="donationColor" />
            <div class="top-buttons">
              <v-btn
                class="lang-button"
                icon
                :ripple="false"
                data-cy="language-selector-button"
                @click="openLanguageSelector"
              >
                <img :src="languageImage" style="width: 22px" />
              </v-btn>
            </div>
            <EasyWithGreet
              style="position: absolute; top: 26px; left: 50%; transform: translate(-50%, -50%);"
            />
            <div class="d-flex flex-row justify-end pb-6"></div>
            <div class="d-flex flex-column align-center text-center mt-5">
              <Avatar
                :photo-url="donatee.cover"
                :name="donatee.name"
                :lazy-src="donatee.cover_thumbnail"
                data-cy="donatee-avatar"
                :size="100"
              />
              <h2 class="pt-3" data-cy="donatee-name">
                {{ donatee.name }}
              </h2>
              <div class="tp-text-body pt-3" data-cy="donatee-description">
                {{ $t(donatee.description) }}
              </div>
            </div>
            <h4 class="pt-8 pb-3 text-center">
              {{ $t('screens.donate.donateTitle') }}
            </h4>
            <TipsPicker
              v-if="donatee.name"
              :tips="donateAmountsChips"
              :color="donationColor"
              :min="donateMinAmount"
              :max="donateMaxAmount"
              @on-change="onDonationChange"
            />
            <div class="py-8">
              <div
                v-if="donationAmount"
                class="d-flex justify-space-between pb-2"
              >
                <span class="tp-text-body">
                  {{
                    $t('screens.donate.subtotal', {
                      name: donatee.name,
                    })
                  }}
                </span>
                <span class="tp-text-body">
                  {{ donationAmountFormatted }}
                </span>
              </div>
              <div
                v-if="transactionFee"
                class="d-flex justify-space-between pb-2"
              >
                <span class="tp-text-body" data-cy="donation-transaction-fee">
                  {{ $t('screens.donate.transactionFee') }}
                </span>
                <span
                  class="tp-text-body"
                  data-cy="donation-transaction-fee-amount"
                >
                  {{ feeFormatted }}
                </span>
              </div>
              <div class="d-flex justify-space-between">
                <span class="tp-text-subtitle font-weight-medium">
                  {{ $t('screens.donate.total') }}
                </span>
                <span
                  class="tp-text-subtitle font-weight-medium"
                  data-cy="donation-total-amount"
                >
                  {{ totalFormatted }}
                </span>
              </div>
            </div>
            <v-expand-transition>
              <div v-show="donationAmount">
                <PaymentMethods
                  ref="paymentMethods"
                  montonio-disabled
                  :color="donationColor"
                  :wallet-available="walletAvailable"
                  :payment-methods-override="paymentMethodsOverride"
                  @on-change="onPaymentMethodChange"
                />
              </div>
            </v-expand-transition>
          </LayoutCard>
          <div class="px-4 pb-16">
            <v-alert
              v-show="errorMessage && donationAmount"
              border="left"
              colored-border
              color="red accent-4"
              class="mt-7"
              dismissible
              close-icon="mdi-close"
              >{{ errorMessage }}</v-alert
            >
            <v-expand-transition>
              <div v-show="showPayCta" class="pt-3 px-6">
                <Button
                  ref="CTAButton"
                  block
                  dark
                  :disabled="isLoadingPayments"
                  type="submit"
                  data-cy="donate-cta"
                >
                  {{ $t('screens.donate.payCta') }}
                </Button>
              </div>
            </v-expand-transition>
          </div>
        </form>
      </div>
    </div>
    <PaymentStatusBackdrop ref="backdrop" />
    <LocaleSelector
      ref="languageSelector"
      :language-list="languageList"
      :selected-language="this.$i18n.locale()"
      @changeLanguage="changeLanguage"
    />
    <PaymentBottomSheetError ref="paymentBottomSheetError" />
  </div>
</template>

<script>
import StateScreen from '@/components/StateScreen.vue';
import LayoutCard from '@/components/LayoutCard.vue';
import TipsHeader from '@/components/Tips/TipsHeader.vue';
import LocaleSelector from '@/components/AgnosticLocaleSelector.vue';
import Avatar from '@/components/common/Avatar.vue';
import PaymentStatusBackdrop from '@/components/Payments/PaymentStatusBackdrop.vue';
import PaymentMethods from '@/components/Payments/PaymentMethods.vue';
import TipsPicker from '@/components/Cart/TipsPicker.vue';
import EasyWithGreet from '@/components/EasyWithGreet.vue';
import Button from '@/components/Button.vue';
import PaymentBottomSheetError from '@/components/Payments/PaymentBottomSheetError.vue';

import { PROVIDERS } from '@/utils/constants';

export default {
  components: {
    StateScreen,
    LayoutCard,
    TipsHeader,
    LocaleSelector,
    Avatar,
    PaymentStatusBackdrop,
    PaymentMethods,
    TipsPicker,
    EasyWithGreet,
    Button,
    PaymentBottomSheetError,
  },
  data() {
    return {
      isLoading: true,
      isLoadingPayments: false,
      stripe: undefined,
      donationAmount: null,
      paymentMethod: null,
      walletAvailable: false,
      errorMessage: null,
      paymentRequest: null,
      elements: null,
      donateQrCardHash: null,
      total: 0,
      transactionFee: 0,
      updatingDonationAmount: false,
      neopayPaymentIntent: null,
      stripePaymentUuid: null,
    };
  },
  computed: {
    donationSession() {
      return this.$store.getters.donationSession;
    },
    paymentMethodsOverride() {
      return [PROVIDERS.STRIPE, PROVIDERS.NEOPAY];
    },
    languageList() {
      return ['lt', 'en']; // hardcoding the languages for now
    },
    languageImage() {
      const code = this.$i18n.locale();

      return require(`@/assets/images/flags/${code}.svg`);
    },
    donatee() {
      return this.$store.getters.donatee;
    },
    donationColor() {
      return this.donateColorGlobal;
    },
    donateAmountsChips() {
      const donateAmounts =
        this.donatee?.donation_settings?.donation_amounts || [];
      const preselectedIndex =
        this.donatee?.donation_settings?.preselected_index || 0;
      const formatPrice = amount => {
        return this.$store.getters.getFormattedPrice(amount, 0);
      };

      return donateAmounts.map((amount, index) => {
        return {
          value: amount,
          selected: index === preselectedIndex,
          label: formatPrice(amount),
        };
      });
    },
    donationAmountFormatted() {
      return this.$store.getters.getFormattedPrice(this.donationAmount, 0);
    },
    totalFormatted() {
      return this.$store.getters.getFormattedPrice(this.total, 0);
    },
    feeFormatted() {
      return this.$store.getters.getFormattedPrice(this.transactionFee, 0);
    },
    donationQrCodeUnavailable() {
      return this.$store.getters.donationQrCodeUnavailable;
    },
    showPayCta() {
      return (
        this.paymentMethod === PROVIDERS.STRIPE ||
        this.paymentMethod === PROVIDERS.NEOPAY
      );
    },
    donateMinAmount() {
      return this.donatee?.donation_settings?.min_amount || 0.5;
    },
    donateMaxAmount() {
      return this.donatee?.donation_settings?.max_amount || 200;
    },
  },
  watch: {
    async donationAmount(newDonationAmount, oldDonationAmount) {
      if (oldDonationAmount !== newDonationAmount && !!newDonationAmount) {
        this.showBackdrop('loadingGeneric');
        await this.generatePaymentIntents(newDonationAmount);
        this.hideBackdrop();
      }
    },
  },
  async mounted() {
    const hash = this.$route.params.donateQrCardHash;

    this.isLoading = true;

    await this.loadStripeIfNeeded();
    await this.$store.dispatch('fetchDonationSession', { hash });

    this.isLoading = false;
    this.donateQrCardHash = hash;
  },
  methods: {
    async goToSuccess(clientSecret) {
      await this.showBackdrop('success', 2000);
      this.$router.replace({
        name: 'DonateSuccess',
        params: {
          donateQrCardHash: this.donateQrCardHash,
        },
        query: {
          payment_intent_client_secret: clientSecret,
          donationSession: this.donationSession,
          paymentUuid: this.stripePaymentUuid,
        },
      });
    },
    async generatePaymentIntents(newDonationAmount) {
      if (!newDonationAmount) {
        return;
      }

      this.updatingDonationAmount = true;
      await this.loadStripeIfNeeded();

      const [paymentRequestResponse, neoPaymentResponse] = await Promise.all([
        this.generatePaymentIntent(newDonationAmount),
        this.createNeopayTransaction({ amount: newDonationAmount }),
      ]);

      this.neopayPaymentIntent = neoPaymentResponse;

      this.updatingDonationAmount = false;
      this.stripePaymentUuid = paymentRequestResponse.uuid;
      const { clientSecret } = paymentRequestResponse;

      const options = {
        clientSecret,
        locale: this.$i18n.locale(),
        appearance: {
          theme: 'flat',
          variables: {
            fontFamily: ' "Poppins", sans-serif',
            fontLineHeight: '1.5',
            borderRadius: '10px',
            colorBackground: '#F6F8FA',
            colorPrimaryText: '#262626',
            colorPrimary: this.donationColor,
          },
        },
      };

      this.total = paymentRequestResponse.total;
      this.transactionFee = paymentRequestResponse.transaction_fee;

      this.elements = this.stripe.elements(options);
      this.paymentRequest = this.stripe.paymentRequest({
        country: 'LT',
        currency: 'eur',
        total: {
          label: this.donatee.name,
          amount: Math.round(this.total * 100),
        },
        requestPayerName: false,
        requestPayerEmail: false,
      });

      const paymentElementOptions = {
        wallets: {
          applePay: 'never',
          googlePay: 'never',
        },
      };

      const card = this.elements.create('payment', paymentElementOptions);
      const prButton = this.elements.create('paymentRequestButton', {
        paymentRequest: this.paymentRequest,
      });

      const result = await this.paymentRequest.canMakePayment();

      if (this.$refs?.paymentMethods?.$refs?.card) {
        try {
          card.mount(this.$refs.paymentMethods.$refs.card);
        } catch (error) {
          this.payByCardDisabled = true;
        }
      }

      if (result && this.$refs?.paymentMethods?.$refs?.prButton) {
        prButton.mount(this.$refs.paymentMethods.$refs.prButton);
        this.walletAvailable = true;
      } else {
        this.walletAvailable = false;
        console.log('Cannot make payment');
      }

      this.paymentRequest.on('paymentmethod', async e => {
        const {
          paymentIntent,
          error: confirmError,
        } = await this.stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: e.paymentMethod.id,
          },
          {
            handleActions: false,
          }
        );

        if (confirmError) {
          e.complete('fail');
          this?.$refs?.paymentBottomSheetError?.open(confirmError);
          return;
        } else {
          e.complete('success');
          if (paymentIntent && paymentIntent.status === 'requires_action') {
            const { error } = await this.stripe.confirmCardPayment(
              clientSecret
            );
            if (error) {
              this.walletAvailable = false;
              this?.$refs?.paymentBottomSheetError?.open(error);
            } else {
              this.goToSuccess(clientSecret);
            }
          } else {
            this.goToSuccess(clientSecret);
          }
        }
      });
    },
    async generatePaymentIntent(newDonationAmount) {
      return this.$store.dispatch('createStripeDonationPaymentIntent', {
        donateQrCardHash: this.donateQrCardHash,
        amount: newDonationAmount,
      });
    },
    async createNeopayTransaction({ amount = null } = {}) {
      return this.$store.dispatch('createNeoPayDonationPaymentIntent', {
        amount,
        donateQrCardHash: this.donateQrCardHash,
      });
    },
    openLanguageSelector() {
      this.$refs.languageSelector.open();
    },
    async changeLanguage(language) {
      const translations = (await import(`../../i18n/${language.code}.js`))
        .default;

      this.$store.dispatch('getDonateTranslations', language.code);

      this.$i18n.add(language.code, translations);
      this.$i18n.set(language.code);
      this.$store.dispatch('setSelectedLanguage', language.code);
    },
    async loadStripeIfNeeded() {
      if (!this.stripe) {
        this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_PK || '');
      }
    },
    onPaymentMethodChange(method) {
      // This is not stripe but card payment method
      if (method === 'stripe') {
        setTimeout(this.scrollToPayCTA, 300);
      } else if (method === PROVIDERS.NEOPAY) {
        setTimeout(this.scrollToPayCTA, 300);
        this.transactionFee = this.neopayPaymentIntent.transaction_fee;
        this.total = this.neopayPaymentIntent.total;
      }

      this.paymentMethod = method;
    },
    async onDonationChange(amount) {
      this.donationAmount = amount;
    },
    async payByCard(e) {
      this.isLoadingPayments = true;
      e.preventDefault();

      if (this.paymentMethod === PROVIDERS.NEOPAY) {
        await this.payByNeopay(e);
        return;
      }

      this.showBackdrop('loading');

      const { error } = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          return_url: `${process.env.VUE_APP_BASE_URL}/donation/success/${this.donateQrCardHash}?&donationSession=${this.donationSession}&paymentUuid=${this.stripePaymentUuid}`,
        },
      });

      if (error.type === 'card_error' || error.type === 'validation_error') {
        this?.$refs?.paymentBottomSheetError?.open(error);
        this.errorMessage = error.message;
        setTimeout(this.scrollToPayCTA, 300);
      } else {
        this?.$refs?.paymentBottomSheetError?.open(error);
        console.error('An unexpected error occurred.');
      }

      this.isLoadingPayments = false;
      this.hideBackdrop();
    },
    payByNeopay() {
      if (this.neopayPaymentIntent.redirect_url) {
        this.isLoading = true;
        window.location = this.neopayPaymentIntent.redirect_url;
      }
    },
    async showBackdrop(type, duration) {
      await this.$refs.backdrop.show(type, duration);
    },
    async hideBackdrop() {
      await this.$refs.backdrop.hide();
    },
  },
};
</script>

<style scoped lang="scss">
.donate-container {
  height: 100vh;
  height: 100dvh;
}

.top-buttons {
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 100;
}

.lang-button {
  background-color: rgb(255 255 255 / 70%) !important;
  box-shadow: none !important;
  transition: background-color 100ms ease-in-out;
}
</style>
