import {
  generateInPersonPaymentIntentRequest,
  fetchPaymentByUUIDRequest,
  updateInPersonPaymentIntentRequest,
} from './api';

export default {
  state: {},
  mutations: {},
  actions: {
    async generateInPersonPaymentIntent({ rootState }, { order }) {
      try {
        const { session } = rootState;
        const response = await generateInPersonPaymentIntentRequest({
          session,
          order,
        });
        return response?.data;
      } catch (error) {
        console.error('Error generating in person payment intent', error);
        throw error;
      }
    },
    async updateInPersonPaymentIntent({ rootState }, { data, type }) {
      try {
        const { session } = rootState;
        const response = await updateInPersonPaymentIntentRequest({
          session,
          data,
          type,
        });

        return response.data;
      } catch (error) {
        console.error('Error updating in person payment intent', error);
        throw error;
      }
    },
    async saveSettlement({ rootState }, { data }) {
      try {
        const { session } = rootState;

        const response = await updateInPersonPaymentIntentRequest({
          session,
          data,
          type: 'settlement_succeeded',
        });

        return response.data;
      } catch (error) {
        console.error('Error saving settlement', error);
        throw error;
      }
    },
    async fetchPaymentByUUID(_, { paymentUUID }) {
      try {
        if (!paymentUUID || paymentUUID === 'null') {
          return null;
        }
        const response = await fetchPaymentByUUIDRequest({
          paymentUUID,
        });
        return response.data.data;
      } catch (error) {
        console.error('Error fetching payment by UUID', error);
      }
    },
  },
};
