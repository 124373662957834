<template>
  <div class="sticky">
    <LayoutCard type="header" shadow compact>
      <div class="header">
        <div class="header item left">
          <div v-if="showBackArrow">
            <v-icon @click="onBackClick"> mdi-arrow-left </v-icon>
          </div>
        </div>
        <div class="item">
          <v-text-field
            v-bind="$attrs"
            class="input"
            flat
            solo
            hide-details
            :placeholder="placeholder"
            v-on="$listeners"
          >
          </v-text-field>
        </div>
      </div>
    </LayoutCard>
  </div>
</template>

<script>
import LayoutCard from '@/components/LayoutCard.vue';

export default {
  components: {
    LayoutCard,
  },
  props: {
    showBackArrow: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  methods: {
    onBackClick() {
      this.$emit('onBackClick');
    },
  },
};
</script>

<style lang="scss" scoped>
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  @media screen and (min-width: $max-app-width) {
    max-width: $max-app-width;
    margin-left: auto;
    margin-right: auto;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .item {
    flex: 1;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .left {
    flex: 0.1;
    align-items: flex-start;
    justify-content: flex-start !important;
  }
}

.input::v-deep {
  .v-input__slot {
    textarea {
      font-size: 16px;
    }
    textarea::placeholder {
      font-size: 16px;
    }
    font-size: 16px;
  }
}
</style>
