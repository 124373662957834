<template>
  <div>
    <div
      class="menu__header"
      :class="{
        'mb-3':
          $store.getters.customizationMenuHideRestaurantTitle &&
          !$store.getters.customizationMenuShowRestaurantDescription,
      }"
    >
      <HeaderImage />
      <div class="menu-header-buttons">
        <v-icon
          v-if="showBack"
          class="back-icon"
          data-cy="menu-back"
          @click="onBack"
        >
          mdi-arrow-left
        </v-icon>
        <v-btn
          v-if="isSelfService"
          fab
          small
          icon
          class="menu__more-information-icon mr-3"
          data-cy="reset-self-service-cta"
          @click="startNewSelfServiceSession"
        >
          <v-progress-circular
            v-if="resetting"
            size="20"
            width="2"
            indeterminate
          />
          <v-icon v-else>mdi-refresh</v-icon>
        </v-btn>
        <v-btn
          v-if="loyaltyProgramEnabled"
          fab
          small
          class="menu__more-information-icon mr-3"
          @click="navigateToProfile"
        >
          <v-avatar :size="36" data-cy="login">
            <img v-if="userPhotoUrl" :src="userPhotoUrl" :alt="user?.email" />
            <v-icon v-else color="#5F5F5F">
              mdi-account-circle
            </v-icon>
          </v-avatar>
        </v-btn>
        <v-btn
          v-if="$store.getters.restaurantHasLanguages"
          fab
          small
          icon
          class="menu__more-information-icon"
          :class="{ 'mr-3': !$store.getters.customizationMenuHideSearch }"
          @click="toggleLanguageSelector"
        >
          <img
            :src="getLanguageImageByCode($i18n.locale())"
            style="width: 22px"
          />
        </v-btn>
        <v-btn
          v-if="!$store.getters.customizationMenuHideSearch"
          fab
          small
          icon
          class="menu__more-information-icon"
          @click="openSearch"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </div>
      <LayoutCard
        v-if="
          !$store.getters.customizationMenuHideRestaurantTitle ||
            $store.getters.customizationMenuShowRestaurantDescription
        "
        class="mb-3"
        :type="`header`"
        :hide-background="!!$store.getters.customizationMenuHideLayoutCards"
      >
        <HeaderRestaurantTitle
          v-if="!$store.getters.customizationMenuHideRestaurantTitle"
          data-cy="restaurant-title"
        />
        <HeaderRestaurantDescription data-cy="restaurant-desc" />
      </LayoutCard>
      <CollapsingTopBar
        :show-back="showBack"
        :show-category-slider="showCategorySlider"
      />
    </div>
  </div>
</template>

<script>
import LayoutCard from '@/components/LayoutCard.vue';

import HeaderImage from '@/components/Header/HeaderImage.vue';
import HeaderRestaurantTitle from '@/components/Header/HeaderRestaurantTitle.vue';
import HeaderRestaurantDescription from '@/components/Header/HeaderRestaurantDescription.vue';
import CollapsingTopBar from '@/components/Header/CollapsingTopBar.vue';

export default {
  components: {
    LayoutCard,
    HeaderImage,
    HeaderRestaurantTitle,
    HeaderRestaurantDescription,
    CollapsingTopBar,
  },
  props: {
    showCategorySlider: {
      type: Boolean,
      default: true,
    },
    onBack: {
      type: Function,
      default: null,
    },
    showBack: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      resetting: false,
    };
  },
  computed: {
    isSelfService() {
      return this.$store.getters.tableTypeIsSelfService;
    },
    loyaltyProgramEnabled() {
      return this.$store.getters.functionalityLoyaltyProgramEnabled;
    },
    user() {
      return this.$store.getters.user;
    },
    userPhotoUrl() {
      return this.$store.getters.userProfilePhoto;
    },
  },
  methods: {
    getLanguageImageByCode: function(code) {
      return require(`@/assets/images/flags/${code}.svg`);
    },
    toggleLanguageSelector: function() {
      this.$store.dispatch('openLanguageSelector');
      this.$store.dispatch('sendAnalyticsEvent', {
        event: 'language_selector_opened',
      });
    },
    navigateToProfile: function() {
      this.$router.push({
        name: 'Profile',
        params: {
          session: this.$store.state.session,
        },
      });
    },
    openSearch() {
      this.$store.dispatch('sendAnalyticsEvent', {
        event: 'open_search',
      });
      this.$store.dispatch('openSearch');
      this.$router.push({
        name: 'Search',
        params: { session: this.$store.state.session },
      });
    },
    startNewSelfServiceSession() {
      this.resetting = true;
      this.$store.dispatch('generateNewKioskSession');
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  &__header {
    position: relative;
    border-radius: 0 0 20px 20px;
  }

  &__more-information-icon {
    z-index: 150 !important;
    margin-top: 15px !important;
    background: rgba(255, 255, 255, 0.85) !important;
    box-shadow: none !important;
    transition: background-color 100ms ease-in-out;
    width: 36px !important;
    height: 36px !important;

    &.sticky {
      background-color: rgb(255 255 255 / 100%) !important;
    }
  }
}

.back-icon {
  position: absolute;
  top: 15px;
  left: 15px;
  background: rgba(255, 255, 255, 0.85) !important;
  box-shadow: none !important;
  width: 36px !important;
  height: 36px !important;
  border-radius: 50%;
  z-index: 990;
}

.menu-header-buttons {
  z-index: 150 !important;
  position: fixed;
  top: 0;
  max-width: $max-app-width;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: flex-end;
  padding-right: 16px;
}
</style>
