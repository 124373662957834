<template>
  <div class="py-3 tp-text-body-small text-center">
    {{ title || $t('screens.payment.termsOfService') }}
    <a
      class="text-decoration-none font-weight-bold black--text"
      href="https://www.greet.menu/taisykles/"
      target="_blank"
      @click="event => openModal(event, 'https://www.greet.menu/taisykles/')"
    >
      {{ $t('screens.payment.termsOfServiceLink') }}
    </a>
    {{ $t('generic.and') }}
    <a
      class="text-decoration-none font-weight-bold black--text"
      href="https://www.greet.menu/privatumo-politika"
      target="_blank"
      @click="
        event => openModal(event, 'https://www.greet.menu/privatumo-politika')
      "
    >
      {{ $t('screens.payment.privacyPolicyLink') }}
    </a>
    <Modal
      close-icon
      :is-open="showModal"
      :show-close-button="false"
      @close-click="showModal = false"
    >
      <template slot="content">
        <div class="mt-3 text-center">
          <iframe
            :src="link"
            width="100%"
            height="600px"
            frameborder="0"
            style="border-radius: 10px;"
          ></iframe>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/common/Modal.vue';

export default {
  components: {
    Modal,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    showInModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
      link: 'https://www.greet.menu/taisykles/',
    };
  },
  methods: {
    openModal(event, link = null) {
      event.preventDefault();
      if (this.showInModal) {
        this.showModal = true;
        this.link = link || 'https://www.greet.menu/taisykles/';
      } else {
        window.open(link, '_blank');
      }
    },
  },
};
</script>

<style></style>
