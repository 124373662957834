<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 648 209"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g :fill="color">
      <path
        d="M64.3292 153.879C52.8969 154.079 41.5962 151.347 31.4481 145.929C21.97 140.816 14.0256 133.143 8.46873 123.736C2.69732 113.742 -0.231626 102.283 0.0149053 90.6628C-0.236243 78.9275 2.69073 67.3516 8.46873 57.229C13.9866 47.7949 21.9414 40.1206 31.4481 35.06C41.6217 29.7258 52.9114 27.0374 64.3292 27.23C74.4322 27.0645 84.4295 29.3695 93.4973 33.9552C102.256 38.5411 109.449 45.763 114.118 54.659C119.178 63.9782 121.715 75.9875 121.731 90.6865C121.731 105.098 119.193 116.938 114.118 126.21C109.434 135.123 102.248 142.38 93.4973 147.034C84.4546 151.703 74.4468 154.052 64.3292 153.879ZM68.1357 209.001C56.5014 209.036 44.9181 207.419 33.7133 204.197C23.687 201.537 14.3151 196.742 6.20345 190.122L19.538 167.329C25.8004 172.455 32.9243 176.354 40.5557 178.834C48.9917 181.789 57.8462 183.283 66.7579 183.253C80.5826 183.253 90.7493 179.947 97.2571 173.333C103.765 166.721 107.018 156.713 107.018 143.311V120.061L109.354 90.6628L108.396 61.0239V28.9353H136.747V139.924C136.747 163.607 130.87 181.036 119.116 192.212C107.361 203.389 90.3681 208.985 68.1357 209.001ZM68.8597 127.915C75.8139 128.033 82.6883 126.382 88.8735 123.111C94.553 120.109 99.2952 115.523 102.558 109.877C105.859 104.05 107.546 97.4052 107.439 90.6628C107.607 83.7972 105.934 77.0165 102.605 71.0635C99.3293 65.4948 94.5836 60.9972 88.9202 58.0937C82.7078 54.9144 75.8439 53.3163 68.9064 53.4341C61.9319 53.3223 55.0316 54.9195 48.7761 58.0937C43.0586 60.9713 38.2614 65.4718 34.951 71.0635C31.6053 77.0116 29.9166 83.7923 30.0703 90.6628C29.9625 97.4052 31.6503 104.05 34.951 109.877C38.2488 115.546 43.0429 120.135 48.7761 123.111C54.9907 126.37 61.8848 128.019 68.8597 127.915Z"
      />
      <path
        d="M173.72 159.76V28.9321H202.048V64.9596L198.732 54.4876C202.235 45.8257 208.549 38.6793 216.597 34.2642C225.946 29.3598 236.349 26.9639 246.839 27.2988V56.4331C245.718 56.289 244.621 56.169 243.5 56.0729C242.378 55.9767 241.351 55.9527 240.417 55.9527C228.974 55.9527 219.921 59.2994 213.257 65.9924C206.594 72.6853 203.255 82.8936 203.239 96.6158V159.832L173.72 159.76Z"
      />
      <path
        d="M314.495 160.699C301.968 157.701 290.363 151.543 280.727 142.781C272.185 134.932 265.948 124.782 262.721 113.479C259.543 101.765 259.543 89.3805 262.721 77.6675C265.431 65.8708 271.155 55.033 279.303 46.2753C287.086 38.0593 296.893 32.1732 307.677 29.2463C319.172 26.1641 331.256 26.2553 342.706 29.5105C353.962 32.2833 364.316 38.0383 372.738 46.2032C380.678 54.067 386.338 64.0456 389.085 75.025C391.998 87.2118 391.773 99.9685 388.431 112.038C388.151 113.142 387.754 114.44 387.264 116.169C386.773 117.898 386.306 119.243 385.839 120.468L283.085 93.2791L288.013 73.5843L375.447 96.738L362.369 99.8605C364.29 93.2777 364.46 86.2859 362.86 79.6126C361.384 73.4108 358.244 67.7603 353.799 63.3044C348.958 58.5835 342.987 55.2682 336.494 53.697C330.047 51.8774 323.256 51.7782 316.76 53.4087C310.637 54.9869 305.113 58.4171 300.904 63.2562C296.362 68.5489 293.154 74.908 291.563 81.7743L290.372 86.5778C288.509 93.6072 288.509 101.021 290.372 108.051C292.223 114.808 295.967 120.856 301.137 125.44C306.962 130.581 313.908 134.199 321.385 135.984C327.751 137.793 334.406 138.266 340.955 137.377C347.122 136.454 353.037 134.23 358.329 130.844L369.375 153.517C361.93 158.695 353.4 161.987 344.481 163.125C334.427 164.316 324.245 163.492 314.495 160.699Z"
      />
      <path
        d="M467.35 160.699C454.824 157.709 443.219 151.559 433.582 142.805C425.033 134.958 418.788 124.808 415.553 113.503C412.374 101.798 412.374 89.4203 415.553 77.7156C418.248 65.9116 423.965 55.0644 432.111 46.2993C439.892 38.0765 449.699 32.1822 460.484 29.2463C471.98 26.1641 484.063 26.2553 495.514 29.5105C506.774 32.2594 517.131 38.0083 525.546 46.1793C533.484 54.0446 539.144 64.0226 541.893 75.0013C544.804 87.1867 544.586 99.9406 541.263 112.014C540.982 113.118 540.585 114.415 540.095 116.121C539.604 117.826 539.114 119.195 538.671 120.42L435.917 93.3035L440.844 73.608L528.278 96.7137L515.2 99.8361C517.129 93.2561 517.307 86.2637 515.715 79.5889C514.212 73.3927 511.066 67.7474 506.63 63.2803C501.774 58.6068 495.805 55.3341 489.326 53.793C482.878 51.9734 476.087 51.8742 469.592 53.5048C463.47 55.0871 457.948 58.5166 453.735 63.3524C449.195 68.6551 445.988 75.0215 444.394 81.8949L443.226 86.6984C441.347 93.725 441.347 101.144 443.226 108.17C445.098 114.925 448.848 120.969 454.016 125.56C459.844 130.689 466.789 134.298 474.263 136.08C480.629 137.888 487.284 138.362 493.832 137.473C500.004 136.55 505.92 134.317 511.207 130.916L522.277 153.59C514.829 158.762 506.3 162.054 497.382 163.197C487.309 164.359 477.111 163.511 467.35 160.699Z"
      />
      <path
        d="M552.881 54.4977V29.9989H637.676V54.4977H552.881ZM617.662 161.547C603.65 161.547 592.853 157.833 585.271 150.403C577.689 142.973 573.883 132.069 573.852 117.69V0H603.37V116.633C603.029 121.881 604.707 127.056 608.041 131.044C609.792 132.819 611.885 134.195 614.187 135.084C616.488 135.974 618.944 136.357 621.399 136.208C627.245 136.432 632.979 134.517 637.582 130.804L646.153 152.612C642.25 155.679 637.798 157.923 633.052 159.218C628.049 160.702 622.869 161.462 617.662 161.475V161.547Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 80,
    },
    height: {
      type: [Number, String],
      default: 20,
    },
    color: {
      type: String,
      default: '#e85941',
    },
  },
};
</script>
