import {
  submitRatingRequest,
  submitRatingTagsRequest,
  submitRatingCommentRequest,
  submitAttemptToRateOnGoogleRequest,
} from './api';

export default {
  async submitRating(context, { uniqueOrderNumber, tipsQrCardHash, rating }) {
    const reviewId = context.getters.reviewId;
    const session = context.rootState.session;
    try {
      const response = await submitRatingRequest({
        session,
        uniqueOrderNumber,
        tipsQrCardHash,
        reviewId,
        rating,
      });
      context.commit('SET_REVIEW_ID', response.data.data.id);
    } catch (error) {
      console.error(error);
    }

    context.dispatch('sendAnalyticsEvent', {
      event: 'submit_rating',
      rating,
    });
  },
  async submitRatingTags(context, { uniqueOrderNumber, tipsQrCardHash, tags }) {
    const reviewId = context.getters.reviewId;
    const session = context.rootState.session;
    try {
      await submitRatingTagsRequest({
        session,
        uniqueOrderNumber,
        tipsQrCardHash,
        reviewId,
        tags,
      });
    } catch (error) {
      console.error(error);
    }

    context.dispatch('sendAnalyticsEvent', {
      event: 'submit_rating_tags',
      tags,
    });
  },
  async submitRatingComment(
    context,
    { uniqueOrderNumber, tipsQrCardHash, comment }
  ) {
    const reviewId = context.getters.reviewId;
    const session = context.rootState.session;
    try {
      await submitRatingCommentRequest({
        session,
        uniqueOrderNumber,
        tipsQrCardHash,
        reviewId,
        comment,
      });

    } catch (error) {
      console.error(error);
    }

    context.dispatch('sendAnalyticsEvent', {
      event: 'submit_rating_comment',
      comment,
    });
  },
  async submitAttemptToRateOnGoogle(
    context,
    { uniqueOrderNumber, tipsQrCardHash }
  ) {
    const reviewId = context.getters.reviewId;
    const session = context.rootState.session;
    try {
      await submitAttemptToRateOnGoogleRequest({
        session,
        uniqueOrderNumber,
        tipsQrCardHash,
        reviewId,
      });
    } catch (error) {
      console.error(error);
    }

    context.dispatch('sendAnalyticsEvent', {
      event: 'submit_attempt_to_rate_on_google',
    });
  },
};
