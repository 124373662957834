<template>
  <div class="orders">
    <NavHeader
      :title="$t('screens.orders.headerTitle')"
      @onBackClick="goBack"
    />
    <div v-if="loading" class="loaderContainer">
      <v-progress-circular indeterminate />
    </div>
    <div v-else>
      <LayoutCard v-if="activeOrders.length" rounded>
        <div class="tp-text-subtitle-semibold mb-6">
          {{ $t('screens.orders.ongoingOrders') }}
        </div>
        <div>
          <OrderListItem
            v-for="(order, key) in activeOrders"
            :key="key"
            :order_id="order.order.order_id"
            :unique_order_number="order.order.unique_order_number"
            :status="order.order.status"
            :comment="order.order.comment"
            :items="order.order.items"
            :subtotal="order.order.subtotal"
            :transaction_fee="order.order.transaction_fee"
            :restaurant-name="order.restaurant.name"
            :total="order.order.total"
            :is_paid="order.order.is_paid"
            :payments_sum="order.order.payments_sum"
            :payment_data="order.order.payment_data"
            :seen="order.order.seen"
            :wait_minutes="order.order.wait_minutes"
            :placed_at="order.order.placed_at"
            :accepted_at="order.order.accepted_at"
            :created_at="order.order.created_at"
            :currency-code="order.restaurant.currency.code"
            :locale-code="order.restaurant.locale.locale_code"
            :cover="order.restaurant.cover"
            :session_token="order.order.session_token"
            @click="openOrder"
          />
        </div>
      </LayoutCard>
      <LayoutCard v-if="completedOrders.length" rounded>
        <div class="tp-text-subtitle-semibold mb-6">
          {{ $t('screens.orders.completedOrders') }}
        </div>
        <OrderListItem
          v-for="(order, key) in completedOrders"
          :key="key"
          :order_id="order.order.order_id"
          :unique_order_number="order.order.unique_order_number"
          :status="order.order.status"
          :comment="order.order.comment"
          :items="order.order.items"
          :subtotal="order.order.subtotal"
          :transaction_fee="order.order.transaction_fee"
          :restaurant-name="order.restaurant.name"
          :total="order.order.total"
          :is_paid="order.order.is_paid"
          :payments_sum="order.order.payments_sum"
          :payment_data="order.order.payment_data"
          :seen="order.order.seen"
          :wait_minutes="order.order.wait_minutes"
          :placed_at="order.order.placed_at"
          :accepted_at="order.order.accepted_at"
          :created_at="order.order.created_at"
          :currency-code="order.restaurant.currency.code"
          :locale-code="order.restaurant.locale.locale_code"
          :cover="order.restaurant.cover"
          :session_token="order.order.session_token"
          @click="openOrder"
        />
      </LayoutCard>
      <LayoutCard v-if="noOrders" rounded>
        <StateScreen
          :title="$t('screens.orders.emptyStateTitle')"
          :subtitle="$t('screens.orders.emptyStateSubtitle')"
          :image="require(`@/assets/images/states/empty.svg`)"
        />
      </LayoutCard>
    </div>
  </div>
</template>

<script>
import LayoutCard from '@/components/LayoutCard.vue';
import NavHeader from '@/components/Navigation/NavHeader.vue';
import OrderListItem from '@/components/Orders/OrderListItem.vue';
import StateScreen from '@/components/StateScreen.vue';

export default {
  components: {
    LayoutCard,
    NavHeader,
    OrderListItem,
    StateScreen,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    activeOrders() {
      return this.$store.getters.getFoodCourtOrders.filter(order => {
        return order.order.status !== 'closed';
      });
    },
    completedOrders() {
      return this.$store.getters.getFoodCourtOrders.filter(order => {
        return order.order.status === 'closed';
      });
    },
    noOrders() {
      return !this.activeOrders.length && !this.completedOrders.length;
    },
  },
  async mounted() {
    this.loading = true;
    const foodCourtSession = this.$route.params.foodCourtSession;

    await this.$store.dispatch('fetchFoodCourtOrders', { foodCourtSession });
    // this.$store.dispatch('subscribeToPusherChannels');

    this.loading = false;
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },

    async openOrder({ uniqueOrderNumber, session }) {
      await this.$store.dispatch('resetRestaurantId');
      this.$router.push({
        name: 'ViewFoodCourtOrder',
        params: { session, uniqueOrderNumber },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.orders {
  background-color: #f3f4f6;
  height: 100%;
}
.loaderContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
