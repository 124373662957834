<template lang="html">
  <div v-if="isEnded">
    <slot name="ended" />
  </div>

  <div v-else>
    <slot :days="days" :hours="hours" :minutes="minutes" :seconds="seconds" />
  </div>
</template>

<script>
export default {
  props: {
    endDate: {
      type: Date,
      default: null,
    },
  },
  data() {
    return {
      days: null,
      hours: null,
      minutes: null,
      seconds: null,
      isEnded: null,
    };
  },

  mounted() {
    this.tick();
    this.timer = setInterval(this.tick.bind(this), 999);
  },
  methods: {
    updateRemaining(distance) {
      this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

      this.seconds = Math.max(Math.floor((distance % (1000 * 60)) / 1000), 0)
        .toString()
        .padStart(2, '0');

      if (!this.days && !this.hours && !this.minutes && this.seconds <= 91) {
        this.$emit('ending-soon');
      }
      if (!this.days && !this.hours && !this.minutes && !this.seconds) {
        this.$emit('ended');
      }
    },

    tick() {
      const currentTime = new Date();
      const distance = Math.max(this.endDate - currentTime, 0);
      this.updateRemaining(distance);

      if (distance === 0) {
        clearInterval(this.timer);
        this.isEnded = true;
        this.$emit('ended');
      }
    },
  },

  destroy() {
    clearInterval(this.timer);
  },
};
</script>
