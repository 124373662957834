<template>
  <div class="extra-details">
    <NavHeader
      :title="$t('screens.extraDetails.headerTitle')"
      @onBackClick="goBack"
    />
    <LayoutCard :title="$t('screens.extraDetails.formTitle')" rounded required>
      <div class="tp-text-body">{{ fieldsText }}</div>
      <div class="pb-0">
        <div v-for="(field, key) in additionalFields" :key="key">
          <v-autocomplete
            v-if="field.type === 'autocomplete'"
            :key="field.key"
            v-model="details[field.key]"
            :rules="[
              () =>
                !field.required ||
                !!details[field.key] ||
                $t('generic.requiredField'),
            ]"
            :label="$t(field.placeholder)"
            :placeholder="$t(field.placeholder)"
            :items="
              field.select_options.map(option => ({
                label: $t(option),
                value: option,
              }))
            "
            item-text="label"
            item-value="value"
            class="py-3"
          ></v-autocomplete>
          <v-select
            v-else-if="field.type === 'select'"
            :key="field.key"
            v-model="details[field.key]"
            :rules="[
              () =>
                !field.required ||
                !!details[field.key] ||
                $t('generic.requiredField'),
            ]"
            :label="$t(field.placeholder)"
            :placeholder="$t(field.placeholder)"
            :items="
              field.select_options.map(option => ({
                label: $t(option),
                value: option,
              }))
            "
            item-text="label"
            item-value="value"
            class="py-3"
          ></v-select>
          <v-checkbox
            v-else-if="field.type === 'checkbox'"
            :key="field.key"
            v-model="details[field.key]"
            :rules="[
              () =>
                !field.required ||
                !!details[field.key] ||
                $t('generic.requiredField'),
            ]"
            :label="$t(field.placeholder)"
          ></v-checkbox>
          <v-text-field
            v-else
            :key="field.key"
            v-model="details[field.key]"
            :rules="[
              () =>
                !field.required ||
                !!details[field.key] ||
                $t('generic.requiredField'),
            ]"
            :label="$t(field.placeholder)"
            :placeholder="$t(field.placeholder)"
            type="text"
            class="py-3"
            maxlength="100"
          ></v-text-field>
        </div>
      </div>
    </LayoutCard>
    <LayoutCard v-if="functionalitySkipPaymentEnabled" rounded primary>
      <div class="tp-text-subtitle white--text">
        {{
          $store.getters.customizationExtraDetailsDisclaimerTitle
            ? $t($store.getters.customizationExtraDetailsDisclaimerTitle)
            : $t('screens.extraDetails.disclaimerTitle')
        }}
      </div>
      <div class="tp-text-body white--text pt-3">
        {{
          $store.getters.customizationExtraDetailsDisclaimerSubtitle
            ? $t($store.getters.customizationExtraDetailsDisclaimerSubtitle)
            : $t('screens.extraDetails.disclaimerSubtitle')
        }}
      </div>
    </LayoutCard>
    <CartSubtotal id="paySummary" />
    <div class="px-10 py-3">
      <Button
        dark
        :disabled="!formValid || loading"
        :loading="loading"
        data-cy="confirm-details"
        @click="confirmOrder"
      >
        {{ ctaLabel }}
      </Button>
    </div>
    <v-overlay :value="backdropVisible">
      <div class="d-flex flex-column justify-center align-center">
        <lottie-vue-player
          :src="backdrop.image"
          style="width: 100px; height:100px"
          autoplay
          loop
          background-color="transparent"
        />
        <p class="tp-text-subtitle-bold white--text pt-6">
          {{ $t(backdrop.text) }}
        </p>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import NavHeader from '@/components/Navigation/NavHeader.vue';
import LayoutCard from '@/components/LayoutCard.vue';
import Button from '@/components/Button.vue';
import CartSubtotal from '@/components/Cart/CartSubtotal.vue';
import isEqual from 'lodash/isEqual';

const BACKDROPS = {
  success: {
    text: 'screens.extraDetails.successBackdropText',
    image: '/assets/order-status-animations/successful-payment.json',
  },
  loading: {
    text: 'screens.extraDetails.loadingBackdropText',
    image: '/assets/order-status-animations/loading.json',
  },
  error: {
    text: 'screens.extraDetails.failedBackdropText',
    image: '/assets/order-status-animations/failed-payment.json',
  },
};

export default {
  components: {
    NavHeader,
    LayoutCard,
    Button,
    CartSubtotal,
  },
  data() {
    return {
      details: {},
      order: {},
      backdropVisible: false,
      backdrop: {},
      loading: false,
      uniqueOrderNumber: null,
    };
  },
  computed: {
    functionalitySkipPaymentEnabled() {
      return this.$store.getters.functionalitySkipPaymentEnabled;
    },
    fieldsText() {
      return this.$t(this.$store.getters.additionalOrderFieldsText);
    },
    additionalFields() {
      return this.$store.getters.additionalOrderFields;
    },
    session() {
      return this.$store.state.session;
    },
    cartSubtotalPrice() {
      return this.$store.getters.cartSubtotalPrice;
    },
    tipAmount() {
      return this.$store.getters.tipAmount;
    },
    formValid() {
      if (!this.additionalFields) {
        return false;
      }

      const fields = this.additionalFields;
      const details = this.details;
      const valid = fields.every(field => {
        if (field.required) {
          return (
            (details[field.key] && details[field.key].length > 0) ||
            details[field.key] === true
          );
        }

        return true;
      });

      return valid;
    },
    ctaLabel() {
      if (this.functionalitySkipPaymentEnabled || this.hasFullDiscount) {
        return this.$t('screens.extraDetails.ctaLabel');
      }

      if (this.isSelfService) {
        return this.$t('screens.extraDetails.payCtaLabel');
      }

      return this.$t('screens.placeOrder.nextCtaLabel');
    },
    hasFullDiscount() {
      return this.$store.getters.hasFullDiscount;
    },
    placedOrder() {
      const { getOrderByUniqueOrderNumber } = this.$store.getters;
      return getOrderByUniqueOrderNumber(this.uniqueOrderNumber);
    },
    isSelfService() {
      return this.$store.getters.tableTypeIsSelfService;
    },
  },
  watch: {
    placedOrder(newOrder, oldOrder) {
      if (!this.isSelfService || isEqual(newOrder, oldOrder)) {
        return;
      }

      const sendingToPOS = newOrder.sending_to_pos;
      const orderSentToPOS = newOrder.sent_to_pos;
      const orderFailed = newOrder.status === 'failed';

      if (orderFailed) {
        this.printReceipt(
          this.$t('kiosk.printer.orderFailed'),
          newOrder.order_id
        );
        this.showBackdrop('error', 2000);
        this.loading = false;
      } else if (sendingToPOS && orderSentToPOS) {
        this.printReceipt(
          this.$t('kiosk.printer.couponCharge'),
          newOrder.order_id
        );
        this.$router.push({
          name: 'PayInPersonSuccess',
          query: {
            orderNumber: newOrder.unique_order_number,
          },
          params: {
            session: newOrder.session_token,
            paymentUUID: 'null',
          },
        });
      } else if (sendingToPOS) {
        this.showBackdrop('loading');
      }
    },
  },
  async mounted() {
    const sessionToken = this.$route.params.session;
    await this.$store.dispatch('fetchSessionData', { session: sessionToken });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async confirmOrder() {
      this.loading = true;
      if (this.functionalitySkipPaymentEnabled) {
        await this.showBackdrop('loading');
      }

      const onSuccess = async order => {
        // Coupon code covers the entire order amount
        const shouldSkipPayment =
          this.functionalitySkipPaymentEnabled ||
          order.total === 0 ||
          this.$store.getters.isDiscountTypePayAtRestaurant;

        const uniqueOrderId = order.unique_order_number;

        if (shouldSkipPayment) {
          if (this.isSelfService) {
            this.uniqueOrderNumber = order.unique_order_number;
            this.$store.dispatch('subscribeToPusherChannels');
            return;
          }

          await this.showBackdrop('success', 2000);
          await this.$store.dispatch('fetchOrders');
          await this.$store.dispatch('openOrder', uniqueOrderId);

          this.$router.push({
            name: 'Menu',
            query: {
              order: uniqueOrderId,
            },
            params: {
              session: this.session,
            },
          });
        } else {
          this.navigateToPaymentScreen(uniqueOrderId);
        }
        this.loading = false;
      };
      const onError = async () => {
        this.loading = false;
        await this.showBackdrop('error', 2000);
      };

      this.$store.dispatch('placeOrder', {
        comment: this.$store.getters.orderComment,
        tipAmount: this.tipAmount * 100 || 0,
        details: this.details,
        onSuccess,
        onError,
      });
    },
    async showBackdrop(backdrop, timeout) {
      await this.hideBackdrop(); // hide previous backdrop
      this.backdropVisible = true;
      this.backdrop = BACKDROPS[backdrop];

      if (timeout) {
        await new Promise(resolve => setTimeout(resolve, timeout));
        this.hideBackdrop();
      }
    },
    hideBackdrop() {
      this.backdropVisible = false;
      this.backdrop = {};
    },
    navigateToPaymentScreen(uniqueOrderNumber) {
      const session = this.session;
      const path = this.isSelfService ? 'PayInPerson' : 'Pay';

      this.$router.push({
        name: path,
        params: {
          session,
          order: uniqueOrderNumber,
        },
        query: {
          ref: 'extra-details',
        },
      });
    },
    printReceipt(text, orderId = null) {
      this.$store.dispatch('printReceipt', {
        text,
        orderId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.extra-details {
  padding-bottom: 100px;
}

.float {
  position: fixed;
  width: 100%;
  max-width: $max-app-width;
}
</style>
