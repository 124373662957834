export const getAuthErrorMessage = (t, error) => {
  switch (error.code) {
    case 'auth/account-exists-with-different-credential':
      return t('components.firebaseLogin.errorAccountExists');
    case 'auth/wrong-password':
      return t('components.firebaseLogin.errorWrongPassword');
    case 'auth/user-not-found':
      return t('components.firebaseLogin.errorUserNotFound');
    case 'auth/passwords-do-not-match':
      return t('components.firebaseLogin.errorPasswordsDoNotMatch');
    case 'auth/invalid-email':
      return t('components.firebaseLogin.errorInvalidEmail');
    case 'auth/email-already-in-use':
      return t('components.firebaseLogin.errorEmailAlreadyInUse');
    case 'auth/weak-password':
      return t('components.firebaseLogin.errorWeakPassword');
    case 'auth/popup-closed-by-user':
      return t('components.firebaseLogin.errorPopupClosedByUser');
    case 'auth/operation-not-allowed':
      return 'operation-not-allowed';
    case 'auth/invalid-credential':
      return 'invalid-credential';
    default:
      return t('components.firebaseLogin.errorGeneric');
  }
};
