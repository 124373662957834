<template>
  <div class="d-flex py-6 flex-column align-center">
    <div v-if="isLoadingUser" class="py-6 d-flex justify-center align-center">
      <v-progress-circular indeterminate color="primary" />
    </div>
    <div v-show="!isLoadingUser" style="max-width: 310px">
      <div class="d-flex flex-column">
        <LoginButton
          :content="$t('components.firebaseLogin.signInWithGoogle')"
          color="white"
          data-cy="google-login"
          image="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 48 48'%3E%3Cdefs%3E%3Cpath id='a' d='M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z'/%3E%3C/defs%3E%3CclipPath id='b'%3E%3Cuse xlink:href='%23a' overflow='visible'/%3E%3C/clipPath%3E%3Cpath clip-path='url(%23b)' fill='%23FBBC05' d='M0 37V11l17 13z'/%3E%3Cpath clip-path='url(%23b)' fill='%23EA4335' d='M0 11l17 13 7-6.1L48 14V0H0z'/%3E%3Cpath clip-path='url(%23b)' fill='%2334A853' d='M0 37l30-23 7.9 1L48 0v48H0z'/%3E%3Cpath clip-path='url(%23b)' fill='%234285F4' d='M48 48L17 24l-4-3 35-10z'/%3E%3C/svg%3E"
          :on-click="signInWithGoogle"
        />
        <LoginButton
          :content="$t('components.firebaseLogin.signInWithFacebook')"
          color="#216fdb"
          white-text
          data-cy="facebook-login"
          :image="require('@/assets/brands/facebook_100.png')"
          :on-click="signInWithFacebook"
        />
        <DividerWithText :text="$t('generic.or')" class="mb-6 mt-3" />
        <LoginButton
          :content="$t('components.firebaseLogin.registerWithEmail')"
          color="primary"
          white-text
          data-cy="email-signup"
          :on-click="navigateToSignUp"
          icon="mdi-email-outline"
        />
        <div class="text-center pt-1 pb-2 tp-text-label black--text">
          {{ $t('components.firebaseLogin.alreadyHaveAccount') }}
          <a data-cy="email-login" icon @click="navigateToSignIn">
            {{ $t('components.firebaseLogin.signInWithEmail') }}
          </a>
        </div>
      </div>
      <TermsAndConditions :title="$t('components.firebaseLogin.tosTitle')" />
      <v-alert
        v-if="error"
        ref="loginError"
        dense
        border="left"
        colored-border
        color="red accent-4"
        class="mt-6"
        dismissible
        elevation="1"
        close-icon="mdi-close"
      >
        <div class="tp-text-body">{{ error }}</div>
      </v-alert>
    </div>
  </div>
</template>

<script>
import {
  getAuth,
  signInWithRedirect,
  GoogleAuthProvider,
  FacebookAuthProvider,
  getRedirectResult,
} from 'firebase/auth';

import TermsAndConditions from '@/components/common/TermsAndConditions.vue';
import { getAuthErrorMessage } from '@/utils/firebase';
import LoginButton from '@/components/Auth/LoginButton.vue';
import DividerWithText from '@/components/common/DividerWithText.vue';

export default {
  components: {
    TermsAndConditions,
    LoginButton,
    DividerWithText,
  },
  data() {
    return {
      error: null,
      email: '',
      password: '',
      showEmailSignIn: false,
      showCreateUserWithEmail: false,
      isLoading: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    session() {
      return this.$store.state.session;
    },
    restaurantLocaleCode() {
      return this.$store.getters.restaurantLocaleCode;
    },
    isLoadingUser() {
      return this.$store.state.isLoadingUser || this.isLoading;
    },
  },
  async mounted() {
    const auth = getAuth();

    try {
      this.isLoading = true;
      await getRedirectResult(auth);
      this.isLoading = false;
    } catch (error) {
      const message = getAuthErrorMessage(this.$t, error);
      this.error = message;
      this.isLoading = false;
      this.scrollToError();
    }
  },
  methods: {
    onSingInWithEmail() {
      this.showEmailSignIn = !this.showEmailSignIn;
      this.showCreateUserWithEmail = false;
    },
    onSingUpWithEmail() {
      this.showEmailSignIn = false;
      this.showCreateUserWithEmail = !this.showCreateUserWithEmail;
    },
    async signInWithGoogle() {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: 'select_account',
      });
      this.signInWithProvider(provider);
    },
    signInWithFacebook() {
      const provider = new FacebookAuthProvider();
      provider.setCustomParameters({
        auth_type: 'rerequest',
        display: 'popup',
      });
      this.signInWithProvider(provider);
    },
    async signInWithProvider(provider) {
      try {
        this.isLoading = true;
        const auth = getAuth();
        auth.languageCode = this.restaurantLocaleCode;
        auth.useDeviceLanguage();
        await signInWithRedirect(auth, provider);

        this.error = null;
        this.isLoading = false;
      } catch (error) {
        const message = getAuthErrorMessage(this.$t, error);
        this.error = message;
        this.isLoading = false;
      }
    },
    navigateToSignUp() {
      this.$router.push({
        name: 'SignUp',
        params: { session: this.session },
      });
    },
    navigateToSignIn() {
      this.$router.push({
        name: 'SignIn',
        params: { session: this.session },
      });
    },
    async scrollToError() {
      await new Promise(resolve => setTimeout(resolve, 100));
      this.$refs.loginError.$el.scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>

<style></style>
