var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',_vm._g(_vm._b({directives:[{name:"ripple",rawName:"v-ripple",value:({ center: false }),expression:"{ center: false }"}],staticClass:"button d-flex tp-text-subtitle-semibold-white",class:{
    disabled: _vm.disabled,
    'button--dark': _vm.dark,
    'button--x-small': _vm.xSmall,
    'button--white': _vm.white,
    'button--gray': _vm.gray,
    'button--no-shadow': _vm.noShadow,
    'rounded-pill': _vm.pill,
    'button--small': _vm.small,
  },style:({ background: _vm.backgroundColor }),attrs:{"as":"button","disabled":_vm.disabled}},'button',_vm.$attrs,false),_vm.$listeners),[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":15,"width":2}}):_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }