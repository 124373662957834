<template>
  <div class="program-details-container">
    <NavHeader
      :title="$t('screens.loyaltyProgramDetails.navTitle')"
      @onBackClick="goBack"
    />
    <LayoutCard v-if="loyaltyProgram" larger-container grow>
      <div class="text-center">
        <LoyaltyCard :loyalty-program="loyaltyProgram" />
        <div class="mt-7">
          <div v-for="(field, key) in fieldsToDisplay" :key="key">
            <v-text-field disabled :label="field.label" :value="field.value" />
          </div>
        </div>
      </div>
      <div class="mt-auto"></div>
    </LayoutCard>
  </div>
</template>

<script>
import NavHeader from '@/components/Navigation/NavHeader.vue';
import LayoutCard from '@/components/LayoutCard.vue';
import LoyaltyCard from '@/components/Loyalty/LoyaltyCard.vue';

export default {
  components: {
    NavHeader,
    LoyaltyCard,
    LayoutCard,
  },
  computed: {
    session() {
      return this.$store.state.session;
    },
    loyaltyProgram() {
      const loyaltyProgramId = this.$route.params.loyaltyProgramId;
      return this.$store.getters.enrolledLoyaltyProgramById(loyaltyProgramId);
    },
    fieldsToDisplay() {
      const program = this.loyaltyProgram;
      const isExternal = program?.is_external;

      const labels = {
        card_number: this.$t('screens.loyaltyProgramDetails.cardNumberLabel'),
        expires_at: this.$t('screens.loyaltyProgramDetails.cardExpiryLabel'),
      };

      if (isExternal) {
        return Object.entries(program?.external_data || {}).map(
          ([key, value]) => ({
            label: labels[key] || '',
            value,
          })
        );
      }

      return [
        {
          label: this.$t('screens.loyaltyProgramDetails.cardNumberLabel'),
          value: this.loyaltyProgram?.loyalty_card_number || null,
        },
      ];
    },
  },
  async mounted() {
    const sessionToken = this.$route.params.session;
    await this.$store.dispatch('fetchSessionData', {session: sessionToken});
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss">
.program-details-container {
  display: flex;
  flex-flow: column;
  min-height: 100svh;
  height: 100%;
}
</style>
