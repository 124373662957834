<template>
  <button
    class="button d-flex tp-text-subtitle-semibold-white"
    :class="{
      disabled: disabled,
      'button--dark': dark,
      'button--x-small': xSmall,
      'button--white': white,
      'button--gray': gray,
      'button--no-shadow': noShadow,
    }"
    :style="{ background: backgroundColor, ...cssProps }"
    as="button"
    v-bind="$attrs"
    :disabled="disabled"
    v-on="$listeners"
    @click="click"
  >
    <div
      ref="progress"
      class="button__progress"
      :class="{
        'button__progress--animating': !stop,
      }"
    ></div>
    <v-progress-circular
      v-if="loading"
      indeterminate
      :size="15"
      :width="2"
    ></v-progress-circular>
    <span v-else class="button__text"> <slot></slot></span>
  </button>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dark: Boolean,
    xSmall: Boolean,
    white: {
      type: Boolean,
      default: false,
    },
    gray: {
      type: Boolean,
      default: false,
    },
    noShadow: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: [String, Boolean],
      default: false,
    },
    withProgress: {
      type: Boolean,
      default: false,
    },
    progressDuration: {
      type: Number,
      default: 10000,
    },
    stop: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cssProps() {
      return {
        '--duration': `${this.progressDuration}ms`,
      };
    },
  },
  mounted() {
    this.$refs.progress.addEventListener('animationend', this.onTimeoutEnd);
  },
  destroyed() {
    this.$refs.progress.removeEventListener('animationend', this.onTimeoutEnd);
  },
  methods: {
    click() {
      if (!this.loading && !this.disabled) {
        this.$emit('click');
        this.stopProgress();
      }
    },
    onTimeoutEnd() {
      this.$emit('onTimeoutEnd');
    },
    stopProgress() {
      this.$refs.progress.style.width = '0%';
      this.$refs.progress.style.animation = 'none';
    },
  },
};
</script>
<style lang="scss" scoped>
.button {
  transition: padding 0.3s ease-in-out;

  background: linear-gradient(
    180deg,
    var(--color-button-gradient-top) 0%,
    var(--color-button-gradient-bottom) 557.28%
  );
  border-radius: 10px;
  padding: 16px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;

  &--dark {
    background: rgba(0, 0, 0, 0.3);
    color: white;
  }

  &--x-small {
    transition: padding 0.3s ease-in-out;
    margin-top: 20px;
    padding: 8px 20px !important;
  }

  &--white {
    background: white !important;
    color: black !important;
  }
  &--gray {
    background: #ebebeb !important;
    color: black !important;
  }

  &--no-shadow {
    box-shadow: none;
  }
}

.button__text {
  position: relative;
}

.button__progress {
  position: absolute;
  height: 100%;
  width: 0%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 1);
  transition: width 0.3s;

  &--animating {
    animation: shrink var(--duration) linear;
  }
}

@keyframes shrink {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
</style>
