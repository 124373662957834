<template>
  <div
    class="loyalty-price d-flex flex-row align-center"
    v-bind:class="priceTextClass"
  >
    <div class="loyalty-price__price mr-1">{{ priceFormatted }}</div>
    <img
      v-if="$store.getters.customizationLoyaltyCardIcon"
      style="width: 16px;"
      :src="$store.getters.customizationLoyaltyCardIcon"
    />
    <v-icon
      v-else
      small
      :color="$store.getters.customizationPrimaryColor || 'primary'"
      class="ml-1 mr-1"
    >
      mdi-credit-card-fast-outline
    </v-icon>
  </div>
</template>

<script>
import formatPriceWithCurrency from '../../helpers/formatPriceWithCurrency.js';
export default {
  props: {
    price: Number,
    bigger: Boolean,
    customFontFamily: [String, Boolean],
    customFontWeight: [String, Boolean],
    customFontSize: [String, Boolean],
    withTopMargin: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currency: this.$store.state.restaurant.currency.code,
      decimals: this.$store.state.restaurant.currency.decimals,
      locale: this.$store.state.restaurant.locale.locale_code,
    };
  },
  computed: {
    priceFormatted() {
      return formatPriceWithCurrency(this.price, this.currency, this.locale);
    },
    priceTextClass() {
      return {
        'tp-text-body-2': !this.bigger,
        'tp-text-subtitle-semibold': this.bigger,
        'custom-font-family': this.customFontFamily !== false,
        'custom-font-weight': this.customFontWeight !== false,
        'custom-font-size': this.customFontSize !== false,
        'custom-font-style': this.customFontStyle !== false,
        'mt-1': this.withTopMargin,
      };
    },
  },
};
</script>

<style lang="scss">
.loyalty-price {
  color: var(--color-primary) !important;
}

.custom-font-family {
  font-family: var(--menu-items-price-font-family) !important;
}

.custom-font-weight {
  font-weight: var(--menu-items-price-font-weight) !important;
}

.custom-font-size {
  font-size: var(--menu-items-price-font-size) !important;
}

.custom-font-style {
  font-style: var(--menu-items-price-font-style) !important;
}

//.menu-item-price {
//    &__price, &__old-price {
//        font-weight: 400!important;
//    }
//}
</style>
