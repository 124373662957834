<template>
  <v-bottom-sheet
    :value="isOpen"
    class="bottom-sheet"
    :max-width="maxAppWidth"
    @click:outside="close"
  >
    <v-sheet class="text-center pa-3" rounded="xl b-0">
      <div class="d-flex flex-column align-center">
        <div class="header">
          <div class="header item left"></div>
          <div class="d-flex pa-6 header item">
            <span :v-if="title" class="tp-text-subtitle-medium-bold">
              {{ title }}
            </span>
          </div>
          <div class="header item right">
            <img
              :src="
                require(`@/assets/images/icons/chevron-down-white-icon.svg`)
              "
              @click="close"
            />
          </div>
        </div>
        <MoneyInput
          v-model.number="tipAmount"
          :min="min"
          :max="max"
          @on-error-update="onError"
        />
        <div class="pb-6">
          <span :v-if="subtitle" class="tp-text-body-gray">
            {{ subtitle }}
          </span>
        </div>
        <Button
          dark
          :disabled="isDisabled"
          data-cy="save-money-input-amount"
          @click="save"
        >
          {{ $t('generic.save') }}
        </Button>
      </div>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import MoneyInput from '@/components/common/MoneyInput.vue';
import Button from '@/components/Button.vue';

export default {
  components: {
    Button,
    MoneyInput,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    subtitle: {
      type: String,
      required: false,
      default: null,
    },
    min: {
      type: Number,
      default: 0.5,
    },
    max: {
      type: Number,
      default: 200,
    },
  },
  data: () => {
    return {
      tipAmount: null,
      isValid: false,
    };
  },
  computed: {
    isDisabled() {
      return !this.isValid;
    },
  },
  methods: {
    close() {
      this.$emit('on-close');
    },
    save() {
      this.$emit('on-close');
      this.$emit('on-save', this.tipAmount);
    },
    onError(hasErrors) {
      this.isValid = !hasErrors;
    },
  },
};
</script>

<style scoped lang="scss">
.bottom-sheet::v-deep {
  v-bottom-sheet {
    border-radius: 20px;
  }
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .item {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .left {
    flex: 0.2;
    align-items: flex-start;
    justify-content: flex-start !important;
  }

  .right {
    flex: 0.2;
    align-items: flex-end;
    justify-content: flex-start !important;
  }
}
</style>
