<template>
  <div class="easy-with-greet d-flex align-end mt-2">
    <span class="easy-with-greet__text tp-text-body-2">
      {{ $t('generic.easyWith') }}
    </span>
    <GreetLogo :width="55" :height="14" :color="color" />
  </div>
</template>

<script>
import GreetLogo from '@/components/GreetLogo.vue';

export default {
  components: {
    GreetLogo,
  },
  props: {
    color: {
      type: String,
      default: 'white',
    },
  },
};
</script>
