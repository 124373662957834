var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',_vm._g(_vm._b({staticClass:"button d-flex tp-text-subtitle-semibold-white",class:{
    disabled: _vm.disabled,
    'button--dark': _vm.dark,
    'button--x-small': _vm.xSmall,
    'button--white': _vm.white,
    'button--gray': _vm.gray,
    'button--no-shadow': _vm.noShadow,
  },style:({ background: _vm.backgroundColor, ..._vm.cssProps }),attrs:{"as":"button","disabled":_vm.disabled},on:{"click":_vm.click}},'button',_vm.$attrs,false),_vm.$listeners),[_c('div',{ref:"progress",staticClass:"button__progress",class:{
      'button__progress--animating': !_vm.stop,
    }}),(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":15,"width":2}}):_c('span',{staticClass:"button__text"},[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }