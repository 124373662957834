<template>
    <div class="category-section mb-5" id="special-deals" v-if="items.length > 0">
      <LayoutCard>
        <div class="mb-4 tp-text-subtitle-bold">{{ $t('Special Deals') }}</div>
        <menu-item v-for="(item, index) in items" v-bind="item" v-bind:key="index"></menu-item>
      </LayoutCard>
    </div>
</template>

<script>
    import MenuItem from '@/components/MenuItem/MenuItem.vue'
    import categoryHasImages from '../helpers/categoryHasImages.js';
import LayoutCard from './LayoutCard.vue';
    export default {
      props: {
        category: Object
      },
      components: {
    MenuItem,
    LayoutCard
},
      computed: {
        items() {
          return this.$store.state.specialDealItems;
        },
        showFullWidthInfo() {
          return !categoryHasImages(this.items);
        },
      }
    }
</script>