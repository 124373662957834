<template>
  <div>
    <NavHeader
      :title="$t('screens.signUp.headerTitle')"
      @onBackClick="goBack"
    />
    <LayoutCard rounded>
      <v-form class="mb-3" @submit.prevent="createAccount">
        <v-text-field
          ref="emailInput"
          v-model="email"
          :label="$t('components.firebaseLogin.emailLabel')"
          required
          type="email"
        ></v-text-field>
        <v-text-field
          v-model="password"
          :label="$t('components.firebaseLogin.passwordLabel')"
          type="password"
          required
        ></v-text-field>
        <v-text-field
          v-model="confirmPassword"
          :label="$t('components.firebaseLogin.passwordConfirmationLabel')"
          type="password"
          required
        ></v-text-field>
        <Button
          type="submit"
          height="42px"
          class="my-3 white--text rounded-lg"
          color="primary"
          data-cy="email-register"
          block
          :loading="isCreating"
          :disabled="isCreating"
        >
          {{ $t('components.firebaseLogin.registerWithEmail') }}
        </Button>
      </v-form>
    </LayoutCard>
    <v-alert
      v-if="error"
      border="left"
      colored-border
      color="red accent-4"
      class="ma-6"
    >
      {{ error }}
    </v-alert>
  </div>
</template>

<script>
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { getAuthErrorMessage } from '@/utils/firebase';

import NavHeader from '@/components/Navigation/NavHeader.vue';
import LayoutCard from '@/components/LayoutCard.vue';
import Button from '@/components/Button.vue';

export default {
  components: {
    NavHeader,
    LayoutCard,
    Button,
  },
  data() {
    return {
      email: '',
      password: '',
      confirmPassword: '',
      isCreating: false,
      error: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.emailInput.focus();
    });
  },
  methods: {
    async createAccount() {
      this.isCreating = true;
      if (this.password !== this.confirmPassword) {
        this.handleError({ code: 'auth/passwords-do-not-match' });
        return;
      }

      const auth = getAuth();
      const email = this.email;
      const password = this.password;

      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;
        if (user) {
          this.goBack();
        }
      } catch (error) {
        this.handleError(error);
      }
    },
    handleError(error) {
      this.isCreating = false;
      const message = getAuthErrorMessage(this.$t, error);
      this.error = message;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style></style>
