<template>
  <div>
    <div v-if="showLoading" class="loaderContainer">
      <v-progress-circular indeterminate />
    </div>
    <div v-else>
      <NavHeader
        :title="$t('screens.order.headerTitle')"
        @onBackClick="goBack"
      />
      <OrderInfo v-if="order" v-bind="order" headless />
      <LayoutCard v-else rounded style="height: 100vh">
        <StateScreen
          :title="$t('screens.viewOrder.orderNotFound.title')"
          :subtitle="$t('screens.viewOrder.orderNotFound.subtitle')"
          :image="require(`@/assets/images/states/empty.svg`)"
        />
      </LayoutCard>
    </div>
  </div>
</template>

<script>
import NavHeader from '@/components/Navigation/NavHeader.vue';
import OrderInfo from '@/components/Orders/OrdersView/OrderInfo.vue';
import StateScreen from '@/components/StateScreen.vue';
import LayoutCard from '@/components/LayoutCard.vue';

export default {
  components: {
    OrderInfo,
    LayoutCard,
    StateScreen,
    NavHeader,
  },
  data() {
    return {
      loading: false,
      uniqueOrderNumber: null,
      fetchOrdersInterval: null,
    };
  },
  computed: {
    order() {
      return this.$store.getters.getFoodCourtOrderOrderNumber(
        this.uniqueOrderNumber
      );
    },
    showLoading() {
      return !this.order;
    },
    orderStatusClosed() {
      return this.order?.status === 'closed';
    },
  },
  async mounted() {
    this.loading = true;
    const sessionToken = this.$route.params.session;
    const uniqueOrderNumber = this.$route.params.uniqueOrderNumber;

    await this.$store.dispatch('fetchSessionData', {
      session: sessionToken,
      force: true,
    });

    // Note: this is a workaround to make sure the order is fetched, probably not needed
    await this.fetchFoodCourtOrders();

    this.uniqueOrderNumber = uniqueOrderNumber;

    this.loading = false;

    this.initiateFetchOrdersInterval();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    fetchFoodCourtOrders() {
      this.$store.dispatch('fetchFoodCourtOrders');
    },
    initiateFetchOrdersInterval() {
      if (this.fetchOrdersInterval === null && !this.orderStatusClosed) {
        this.fetchOrdersInterval = setInterval(
          this.fetchFoodCourtOrders,
          15000
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loaderContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
