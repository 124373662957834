<template>
  <div class="order">
    <NavHeader
      :title="$t('screens.placeOrder.headerTitle')"
      :subtitle="navHeaderSubtitle"
      :cta="{
        icon: 'mdi-delete-outline',
        onClick: () => (showDeleteModal = true),
      }"
      @onBackClick="goBack"
    />
    <LayoutCard v-if="!hasCartItems" :loading="isLoading">
      <StateScreen
        class="mt-8"
        :title="$t('screens.placeOrder.emptyStateTitle')"
        :subtitle="
          $t($store.getters.customizationEmptyCartScreenDescriptionText) ||
            $t('screens.placeOrder.emptyStateSubtitle')
        "
        image="https://cdn.greet.menu/choose_item_small_3.gif"
      />
    </LayoutCard>
    <div v-else>
      <SortableCart
        v-if="ableToSortCartItems"
        :change-quantity="changedItemQuantity"
        @enablePayButton="enablePayButton"
      />
      <LayoutCard
        v-else
        :title="$t('screens.placeOrder.orderCardTitle')"
        rounded
      >
        <TransitionGroup name="opacity">
          <div
            v-for="(item, key) in getCartItems"
            :key="`cart-item-wrapper-${item.id}`"
            class="order-item"
          >
            <CartItem
              :id="item.id"
              :key="`cart-item-${item.id}`"
              :item-key="key"
              :name="item.name + (item.variation ? ` (${item.variation.name})` : '')"
              :price="item.price"
              :quantity="item.quantity"
              :modifiers="item.modifiers"
              @changeQuantity="changedItemQuantity"
              @enablePayButton="enablePayButton"
            />
          </div>
        </TransitionGroup>
      </LayoutCard>
      <LayoutCard
        v-if="$store.getters.functionalityOrderCommentEnabled"
        :title="$t('screens.placeOrder.userCommentTitle')"
      >
        <div class="pb-4">
          <div class="tp-text-caption">
            {{ $t('screens.placeOrder.userCommentSubtitle') }}
          </div>
        </div>
        <v-textarea
          v-model="userComment"
          class="comment-textarea"
          name="user-comment"
          rows="1"
          :placeholder="$t('screens.placeOrder.userCommentPlaceholder')"
          auto-grow
          outlined
          hide-details
          :disabled="isLoading"
        />
      </LayoutCard>
      <LayoutCard
        v-if="tipsBeforePaymentEnabled"
        :title="$t('screens.placeOrder.tipsTitle')"
        rounded
      >
        <div class="tp-text-caption">
          {{ $t('screens.placeOrder.tipsSubtitle') }}
        </div>
        <TipsPicker
          :color="$store.getters.customizationPrimaryColor || null"
          :tips="tipChips"
          :subtotal-price="subtotalPrice"
          @on-change="onTipsChange"
        />
      </LayoutCard>
      <DiscountCodeInput v-if="functionalityDiscountCodeEnabled" />
      <CartSubtotal id="paySummary" />
      <Modal
        :is-open="showDeleteModal"
        :title="$t('screens.placeOrder.cartDeleteTitle')"
        :subtitle="$t('screens.placeOrder.cartDeleteSubtitle')"
        :cta-label="$t('screens.placeOrder.cartDeleteCta')"
        @cta-click="clearCart"
        @close-click="showDeleteModal = false"
      />
    </div>
    <div class="px-10 py-3">
      <Button
        v-if="hasCartItems"
        dark
        :loading="isLoading"
        :disabled="ctaDisabled"
        data-cy="go-to-payment"
        @click="navigateToPayment"
      >
        {{ ctaLabel }}
      </Button>
      <Button v-else dark @click="goBack">
        {{ $t('screens.order.backCta') }}
      </Button>
    </div>
    <v-overlay :value="backdropVisible">
      <div class="d-flex flex-column justify-center align-center">
        <lottie-vue-player
          :src="backdrop.image"
          style="width: 100px; height:100px"
          autoplay
          loop
          background-color="transparent"
        />
        <p class="tp-text-subtitle-bold white--text pt-6">
          {{ $t(backdrop.text) }}
        </p>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import LayoutCard from '@/components/LayoutCard.vue';
import NavHeader from '@/components/Navigation/NavHeader.vue';
import StateScreen from '@/components/StateScreen.vue';
import CartItem from '@/components/Cart/CartItem.vue';
import Button from '@/components/Button.vue';
import Modal from '@/components/common/Modal.vue';
import TipsPicker from '@/components/Cart/TipsPicker.vue';
import DiscountCodeInput from '@/components/Cart/DiscountCodeInput.vue';
import CartSubtotal from '@/components/Cart/CartSubtotal.vue';
import { tipChipsByPercentage } from '@/utils/tips';
import SortableCart from '@/components/Cart/SortableCart.vue';
import isEqual from 'lodash/isEqual';

const BACKDROPS = {
  loading: {
    text: 'screens.extraDetails.loadingBackdropText',
    image: '/assets/order-status-animations/loading.json',
  },
  error: {
    text: 'screens.extraDetails.failedBackdropText',
    image: '/assets/order-status-animations/failed-payment.json',
  },
};

export default {
  components: {
    LayoutCard,
    NavHeader,
    CartItem,
    StateScreen,
    Button,
    Modal,
    TipsPicker,
    DiscountCodeInput,
    CartSubtotal,
    SortableCart,
  },
  data() {
    return {
      isLoading: false,
      showDeleteModal: false,
      order: {},
      userComment: this.$store.getters.orderComment || null,
      paymentButtonEnabled: true,
      tipAmount: 0,
      scrolledToBottom: false,
      uniqueOrderNumber: null,
      backdropVisible: false,
      backdrop: {},
    };
  },
  computed: {
    session() {
      return this.$store.state.session;
    },
    navHeaderSubtitle() {
      const restaurantType = this.$store.state.restaurant.type;
      const tableName = this.tableName;
      const isRestaurant = restaurantType === 'restaurant';
      const selfService = this.$store.getters.tableTypeIsSelfService;

      if (selfService) {
        return null;
      }

      if (isRestaurant) {
        return this.$t('screens.placeOrder.headerSubtitle', {
          tableName,
        });
      }

      return this.$t('screens.placeOrder.headerSubtitleWithoutTable', {
        tableName,
      });
    },
    ctaDisabled() {
      return (
        !this.paymentButtonEnabled ||
        !this.scrolledToBottom ||
        this.isNegativeCartAmount
      );
    },
    tipsBeforePaymentEnabled() {
      const tipsBeforePaymentEnabled = this.$store.getters
        .functionalityTipsBeforePayment;
      const skipPaymentEnabled = this.$store.getters
        .functionalitySkipPaymentEnabled;

      if (skipPaymentEnabled) {
        return false;
      }

      return tipsBeforePaymentEnabled;
    },
    tableName() {
      return this.$store.state.table.name;
    },
    functionalitySkipPaymentEnabled() {
      return this.$store.getters.functionalitySkipPaymentEnabled;
    },
    functionalityDiscountCodeEnabled() {
      return this.$store.getters.functionalityDiscountCodeEnabled;
    },
    hasFullDiscount() {
      return this.$store.getters.hasFullDiscount;
    },
    requiresExtraOrderDetails() {
      return this.$store.getters.requiresExtraOrderDetails;
    },
    ctaLabel() {
      if (this.requiresExtraOrderDetails) {
        return this.$t('screens.placeOrder.nextCtaLabel');
      } else if (this.functionalitySkipPaymentEnabled || this.hasFullDiscount) {
        return this.$t('screens.placeOrder.placeOrderCta');
      }
      return this.$t('screens.placeOrder.continueToPaymentCta');
    },
    hasCartItems() {
      return this.$store.getters.hasCartItems;
    },
    getCartItems() {
      return this.$store.getters.normalizedCartItems;
    },
    ableToSortCartItems() {
      const hasMoreThanOneCartItem = this.getCartItems.length > 1;
      const hasCourses = this.$store.getters.courses.length > 0;

      return hasMoreThanOneCartItem && hasCourses;
    },
    getTipAmount() {
      return this.tipAmount;
    },
    subtotalPrice() {
      return this.$store.getters.cartSubtotalPrice;
    },
    cartAmount() {
      return this.$store.getters.cartAmount;
    },
    isNegativeCartAmount() {
      return this.$store.getters.isNegativeCartAmount;
    },
    tipChips() {
      const formatPrice = amount =>
        this.$store.getters.getFormattedPrice(amount, 0);
      const tipChips = tipChipsByPercentage({
        formatPrice,
        amount: this.cartAmount,
        preselectedIndex:
          this.$store.getters
            .customizationCollectiveTipsDefaultSelectionIndex ?? 1,
      });

      return tipChips;
    },
    placedOrder() {
      const { getOrderByUniqueOrderNumber } = this.$store.getters;
      return getOrderByUniqueOrderNumber(this.uniqueOrderNumber);
    },
  },
  watch: {
    userComment: function() {
      this.$store.dispatch('setOrderComment', this.userComment);
    },
    placedOrder(newOrder, oldOrder) {
      const isSelfService = this.$store.getters.tableTypeIsSelfService;
      if (!isSelfService || isEqual(newOrder, oldOrder)) {
        return;
      }
      const sendingToPOS = newOrder.sending_to_pos;
      const orderSentToPOS = newOrder.sent_to_pos;
      const orderFailed = newOrder.status === 'failed';

      if (orderFailed) {
        this.printReceipt(
          this.$t('kiosk.printer.orderFailed'),
          newOrder.order_id
        );
        this.showBackdrop('error', 2000);
        this.isLoading = false;
      } else if (sendingToPOS && orderSentToPOS) {
        this.printReceipt(
          this.$t('kiosk.printer.couponCharge'),
          newOrder.order_id
        );
        this.$router.push({
          name: 'PayInPersonSuccess',
          query: {
            orderNumber: newOrder.unique_order_number,
          },
          params: {
            session: newOrder.session_token,
            paymentUUID: 'null',
          },
        });
      } else if (sendingToPOS) {
        this.showBackdrop('loading');
      }
    },
  },
  async mounted() {
    const sessionToken = this.$route.params.session;

    this.isLoading = true;
    await this.$store.dispatch('fetchSessionData', { session: sessionToken });
    if (!this.$store.getters.functionalityOrderEnabled) {
      this.$router.push({
        name: 'Menu',
        params: { session: sessionToken },
      });
      return;
    }
    await this.$store.dispatch('fetchCart', sessionToken);
    this.isLoading = false;
    this.observeScroll();
  },
  methods: {
    async navigateToPayment() {
      this.isLoading = true;

      if (this.requiresExtraOrderDetails) {
        this.$router.push({
          name: 'ExtraDetails',
          params: {
            session: this.$store.state.session,
          },
        });

        this.$store.dispatch('sendAnalyticsEvent', {
          event: 'navigate_to_payment',
          origin: 'place_order',
          extra_details: true,
        });

        return;
      }

      const onError = () => (this.isLoading = false);
      const onSuccess = async order => {
        // Coupon code covers the entire order amount
        const isSelfService = this.$store.getters.tableTypeIsSelfService;
        const shouldSkipPayment =
          this.functionalitySkipPaymentEnabled ||
          order.total === 0 ||
          this.$store.getters.isDiscountTypePayAtRestaurant;

        if (shouldSkipPayment) {
          if (isSelfService) {
            this.uniqueOrderNumber = order.unique_order_number;
            this.$store.dispatch('subscribeToPusherChannels');
            return;
          }

          await this.$store.dispatch('fetchOrders');
          await this.$store.dispatch('openOrder', order.unique_order_number);

          this.$router.push({
            name: 'Menu',
            query: {
              order: order.unique_order_number,
            },
            params: {
              session: this.$store.state.session,
            },
          });
        } else {
          this.navigateToPaymentScreen(order);
        }
        this.isLoading = false;
      };

      this.$store.dispatch('sendAnalyticsEvent', {
        event: 'navigate_to_payment',
        origin: 'place_order',
        extra_details: false,
      });

      this.$store.dispatch('placeOrder', {
        comment: this.userComment,
        tipAmount: this.getTipAmount * 100 || 0,
        onSuccess,
        onError,
      });
    },
    goBack() {
      this.$router.push({
        name: 'Menu',
        params: { session: this.$store.state.session },
      });
    },
    async clearCart() {
      this.showDeleteModal = true;
      await this.$store.dispatch('clearCart');
      await this.axios.post(`/cart/${this.$store.state.session}/clear`);
      await this.$store.dispatch('fetchCart', this.$route.params.session);
    },
    async changedItemQuantity({ cart_item_id, quantity }) {
      await this.$store.dispatch('changeCartItemQuantity', {
        cartItemId: cart_item_id,
        quantity,
      });
      this.paymentButtonEnabled = false;
    },
    async enablePayButton() {
      await this.$store.dispatch('fetchCart', this.$route.params.session);
      this.paymentButtonEnabled = true;
    },
    observeScroll() {
      const element = document.querySelector('#paySummary');

      if (!element) {
        return;
      }

      const position = element.getBoundingClientRect();

      if (position.top >= 0 && position.bottom <= window.innerHeight) {
        this.scrolledToBottom = true;
      }
      if (!this.scrolledToBottom) {
        window.onscroll = () => {
          const element = document.querySelector('#paySummary');

          if (!element) {
            return;
          }

          const position = element.getBoundingClientRect();

          if (position.top >= 0 && position.bottom <= window.innerHeight) {
            this.scrolledToBottom = true;
          }
        };
      }
    },
    scrollToBottom() {
      window.scrollTo(0, document.body.scrollHeight);
    },
    async onTipsChange(value) {
      const tipAmount = parseFloat(value);
      await this.$store.dispatch('setTipAmount', tipAmount);
      this.tipAmount = tipAmount;
    },
    navigateToPaymentScreen(order) {
      const isSelfService = this.$store.getters.tableTypeIsSelfService;
      const session = this.$store.state.session;
      const uniqueOrderNumber = order.unique_order_number;
      const path = isSelfService ? 'PayInPerson' : 'Pay';

      this.$router.push({
        name: path,
        params: { session, order: uniqueOrderNumber },
      });
    },
    printReceipt(text, orderId = null) {
      this.$store.dispatch('printReceipt', {
        text,
        orderId,
      });
    },
    async showBackdrop(backdrop, timeout) {
      await this.hideBackdrop(); // hide previous backdrop
      this.backdropVisible = true;
      this.backdrop = BACKDROPS[backdrop];

      if (timeout) {
        await new Promise(resolve => setTimeout(resolve, timeout));
        this.hideBackdrop();
      }
    },
    hideBackdrop() {
      this.backdropVisible = false;
      this.backdrop = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  width: 100vw;
  max-width: $max-app-width;
  margin-left: auto;
  margin-right: auto;
  background-color: $color-background-gray;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  &--controls-enabled {
    padding-bottom: 88px;
  }
}

.order {
  background-color: $color-background-gray !important;
  padding-bottom: 100px;
}

.order-item {
  display: flex;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.modal {
  border-radius: 5px !important;
}

/* HACk for now */
::v-deep .v-textarea textarea {
  font-weight: 400;
  font-size: 14px;
}

::v-deep .v-textarea textarea::placeholder {
  font-weight: 400;
  font-size: 14px;
  color: #9e9e9e;
}
</style>
