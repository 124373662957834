<template>
  <div class="payment-information container-32">
    <div class="payment-information__total">
      <div class="tp-text-subtitle">
        {{ $t('screens.order.orderTotalAmount') }}
      </div>
      <div class="tp-text-subtitle">
        {{ orderTotalFormatted }}
      </div>
    </div>
  </div>
</template>

<script>
import formatPriceWithCurrency from '@/helpers/formatPriceWithCurrency.js';
export default {
  props: {
    subtotal: Number,
  },
  computed: {
    orderTotalFormatted() {
      return formatPriceWithCurrency(
        this.subtotal,
        this.$store.state.restaurant.currency.code,
        this.$store.state.restaurant.locale.locale_code
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-information {
  &__total {
    display: flex;
    justify-content: space-between;
  }
}
</style>
