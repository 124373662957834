import shuffle from 'lodash/shuffle';
import Vue from 'vue';

export default {
  state: {
    personnelList: [],
    standalonePersonnel: {},
  },
  mutations: {
    SET_PERSONNEL(state, personnelList) {
      state.personnelList = personnelList;
    },
    SET_STANDALONE_PERSONNEL(state, personnelMember) {
      state.standalonePersonnel = personnelMember;
    },
  },
  actions: {
    async fetchPersonnel({ rootState, commit }) {
      const { session } = rootState;
      const response = (await Vue.axios.get(`/personnel/get-list-v2/${session}`))
        .data.data;
      commit('SET_PERSONNEL', response);
    },
    async fetchStandalonePersonnel({ commit, rootState }, { tipsQrCardHash }) {
      try {
        const response = await Vue.axios.get(`/personnel/get-v2/${tipsQrCardHash}`);
        const personnel = response.data.data;

        commit('SET_STANDALONE_PERSONNEL', personnel);

        // TODO - this is a hack, we should be using a vuex module for this
        if (!rootState.restaurant.id) {
          rootState.currency = personnel.currency;
          rootState.locale = personnel.locale;
          rootState.payment_providers = [{ provider: 'stripe' }];
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {
    personnelListCount(_, getters) {
      const { personnelList } = getters;
      return personnelList.length;
    },
    personnelMemberById: (_, getters) => id => {
      const { personnelList } = getters;
      return personnelList.find(personnel => `${personnel.id}` === `${id}`);
    },
    personnelList(state) {
      return shuffle(state.personnelList) || [];
    },
    standalonePersonnelMember(state) {
      return state.standalonePersonnel;
    },
  },
};
