<template>
  <div>
    <div v-if="loading" class="loaderContainer">
      <v-progress-circular indeterminate color="primary" />
    </div>
    <div v-else>
      <OrderInfo v-if="order" v-bind="order" headless add-top-padding />
      <LayoutCard v-else rounded style="height: 100vh">
        <StateScreen
          :title="$t('screens.viewOrder.orderNotFound.title')"
          :subtitle="$t('screens.viewOrder.orderNotFound.subtitle')"
          :image="require(`@/assets/images/states/empty.svg`)"
        />
      </LayoutCard>
    </div>
  </div>
</template>

<script>
import OrderInfo from '@/components/Orders/OrdersView/OrderInfo.vue';
import StateScreen from '@/components/StateScreen.vue';
import LayoutCard from '@/components/LayoutCard.vue';

export default {
  components: {
    OrderInfo,
    LayoutCard,
    StateScreen,
  },
  data() {
    return {
      loading: false,
      uniqueOrderNumber: null,
    };
  },
  computed: {
    order() {
      const { getOrderByUniqueOrderNumber } = this.$store.getters;
      return getOrderByUniqueOrderNumber(this.uniqueOrderNumber);
    },
  },
  async mounted() {
    this.loading = true;
    const sessionToken = this.$route.params.session;
    const force = this.$route.query.force === '1';
    const uniqueOrderNumber = this.$route.params.uniqueOrderNumber;

    // DO NOT USE JUST fetchSession action
    await this.$store.dispatch('fetchSessionData', {
      session: sessionToken,
      force,
    });
    await this.$store.dispatch('fetchOrders');

    this.uniqueOrderNumber = uniqueOrderNumber;

    // if (uniqueOrderNumber) {
    //   this.$store.dispatch('subscribeToPusherChannels');
    // }

    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.loaderContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
