<template>
  <svg
    style="position: absolute; top: 0; left: 0; width: 100%;  z-index: 0;"
    xmlns="http://www.w3.org/2000/svg"
    width="390"
    height="195"
    viewBox="0 0 390 195"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M350.977 75.8288L479.619 194.476C511.75 158.365 613.938 32.2892 636.608 -31.7067C659.278 -95.7025 663.088 -152.054 661.331 -220.658C658.798 -319.961 619.704 -396.024 560.297 -446.663C503.981 -494.73 436.53 -514.063 382.32 -517.971C321.777 -522.312 77.5879 -539.616 -36.8541 -547.709L-139.678 -555L-158.093 -253.64C-158.093 -253.64 -162.207 -178.064 -157.326 -133.644C-138.477 37.8564 -10.686 132.993 119.778 140.965C193.671 145.48 276.567 126.95 344.474 80.4255C346.671 78.9104 348.823 77.3924 350.977 75.8288Z"
      :fill="colorOverride || tipsColorGlobal"
    />
  </svg>
</template>

<script>
export default {
  name: 'TipsHeader',
  props: {
    colorOverride: {
      type: String,
      default: null,
    },
  },
};
</script>
