<template>
  <SplashScreen
    v-if="loadingScreenCustomizations"
    :status="'active'"
    :failure="false"
    :loading-screen-customizations="loadingScreenCustomizations"
  ></SplashScreen>
</template>

<script>
import SplashScreen from '@/components/SplashScreen.vue';
export default {
  components: {
    SplashScreen,
  },
  computed: {
    loadingScreenCustomizations() {
      return this.$store.getters.loadingScreenCustomizations;
    },
  },
};
</script>
