<template>
  <div>
    <NavHeader
      :title="$t('screens.signIn.headerTitle')"
      @onBackClick="goBack"
    />
    <LayoutCard rounded>
      <v-form class="mb-3" @submit.prevent="signInWithEmail">
        <v-text-field
          ref="emailInput"
          v-model="email"
          data-cy="sign-in-email"
          :label="$t('components.firebaseLogin.emailLabel')"
          type="email"
          required
        />
        <v-text-field
          v-model="password"
          data-cy="sign-in-password"
          :label="$t('components.firebaseLogin.passwordLabel')"
          type="password"
          required
        />
        <Button
          type="submit"
          class="my-3 white--text rounded-lg"
          color="primary"
          data-cy="sign-in-submit"
          block
          :loading="loading"
          :disabled="loading"
        >
          {{ $t('screens.signIn.signInCta') }}
        </Button>
      </v-form>
    </LayoutCard>
    <v-alert
      v-if="error"
      border="left"
      colored-border
      color="red accent-4"
      class="ma-6"
    >
      {{ error }}
    </v-alert>
  </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getAuthErrorMessage } from '@/utils/firebase';

import NavHeader from '@/components/Navigation/NavHeader.vue';
import LayoutCard from '@/components/LayoutCard.vue';
import Button from '@/components/Button.vue';

export default {
  components: {
    NavHeader,
    LayoutCard,
    Button,
  },
  data() {
    return {
      email: '',
      password: '',
      error: null,
      loading: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.emailInput.focus();
    });
  },
  methods: {
    async signInWithEmail() {
      const auth = getAuth();
      const email = this.email;
      const password = this.password;
      this.loading = true;

      try {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );

        if (userCredential.user) {
          this.goBack();
        }
        this.error = null;
      } catch (error) {
        this.handleError(error);
      }

      this.loading = false;
    },
    handleError(error) {
      const message = getAuthErrorMessage(this.$t, error);
      this.error = message;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style></style>
