<template>
  <div class="success-container">
    <v-btn
      class="close-button"
      dense
      text
      icon
      :disabled="startingOver"
      @click="startNewOrder"
    >
      <v-icon color="black" dense>mdi-close</v-icon>
    </v-btn>
    <div v-if="isLoading">
      <v-progress-circular indeterminate />
    </div>
    <div v-else class="success-content-container">
      <div class="success-card" data-cy="payment-success-screen">
        <lottie-vue-player
          src="/assets/order-status-animations/successful-payment.json"
          style="width: 120px; height:120px; margin-top: -50px"
          autoplay
        />
        <div class="my-10 text-center">
          <h3 class="header-text mb-10">
            {{ $t('kiosk.success.title', { orderId }) }}
          </h3>
          <span>{{ $t('kiosk.success.content') }}</span>
        </div>
      </div>
      <div class="cta-container">
        <div class="px-10">
          <CountDownButton
            dark
            with-progress
            :loading="startingOver"
            :stop="requestReceiptModalOpen"
            @onTimeoutEnd="startNewOrder"
            @click="startNewOrder"
          >
            {{ $t('kiosk.success.cta') }}
          </CountDownButton>
        </div>
        <div class="d-flex justify-center align-center mt-6">
          <RequestReceiptButton
            v-if="payment"
            link
            :session="session"
            :payment-uuid="paymentUUID"
            @on-open="requestReceiptModalOpen = true"
            @on-close="requestReceiptModalOpen = false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountDownButton from '@/components/common/CountDownButton.vue';
import RequestReceiptButton from '@/components/Payments/RequestReceiptButton.vue';

export default {
  components: {
    CountDownButton,
    RequestReceiptButton,
  },
  data() {
    return {
      session: null,
      isLoading: true,
      payment: {},
      uniqueOrderNumber: null,
      paymentUUID: null,
      startingOver: false,
      requestReceiptModalOpen: false,
    };
  },
  computed: {
    amountFormatted() {
      return this.$store.getters.getFormattedPrice(this.payment.amount);
    },
    paymentId() {
      return this.payment.id;
    },
    orderId() {
      const { getOrderByUniqueOrderNumber } = this.$store.getters;
      const order = getOrderByUniqueOrderNumber(this.uniqueOrderNumber);

      return order?.order_id;
    },
  },
  async mounted() {
    const session = this.$route.params.session;
    const paymentUUID = this.$route.params.paymentUUID;
    const uniqueOrderNumber = this.$route.query.orderNumber;

    await this.$store.dispatch('fetchSessionData', { session });
    const payment = await this.$store.dispatch('fetchPaymentByUUID', {
      paymentUUID,
    });
    this.$store.dispatch('fetchOrders');
    this.payment = payment;
    this.uniqueOrderNumber = uniqueOrderNumber;
    this.paymentUUID = paymentUUID;
    this.session = session;

    this.isLoading = false;
  },
  methods: {
    startNewOrder() {
      this.startingOver = true;
      this.$store.dispatch('generateNewKioskSession');
    },
  },
};
</script>

<style lang="scss" scoped>
.success-container {
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  animation: backgroundFill 3s normal forwards;
  background-size: 400% 400%;
}

.success-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  width: 100%;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 40px;
  padding: 40px;
}

.success-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header-text {
  font-size: 32px;
  font-weight: 600;
  color: #489f31;
}

.h-100 {
  height: 100%;
}

.cta-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 80px;
  padding: 0 40px;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
}

@keyframes backgroundFill {
  from {
    background-color: rgba(255, 255, 255, 0);
  }
  to {
    background-color: rgba(72, 159, 49, 0.6);
  }
}
</style>
