<template>
  <div v-show="hasUserEnrolledLoyaltyPrograms" class="text-center pt-7">
    <div class="tp-text-subtitle-medium">
      {{ $t('screens.profile.discountCardTitle') }}
    </div>
    <div class="carousel">
      <div
        v-for="(loyaltyProgram, i) in loyaltyPrograms"
        :id="`card${i}`"
        :key="i"
        :ref="`carousel`"
      >
        <div class="pa-2" @click="navigateToLoyaltyProgram(loyaltyProgram)">
          <LoyaltyCard
            :loyalty-program="loyaltyProgram"
            width="180px"
            height="115px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoyaltyCard from '@/components/Loyalty/LoyaltyCard.vue';

export default {
  components: {
    LoyaltyCard,
  },
  computed: {
    hasUserEnrolledLoyaltyPrograms() {
      return this.$store.getters.userEnrolledLoyaltyPrograms.length > 0;
    },
    loyaltyPrograms() {
      if (!this.hasUserEnrolledLoyaltyPrograms) {
        return [];
      }

      const spacerLoyaltyProgram = {
        card_color: '#f6eff9',
        card_secondary_color: '#cfd3ec',
      };

      return [
        spacerLoyaltyProgram,
        ...this.$store.getters.userEnrolledLoyaltyPrograms,
        spacerLoyaltyProgram,
      ];
    },
  },
  mounted() {
    setTimeout(() => {
      this.scrollToMiddle();
    }, 300);
  },
  methods: {
    scrollToMiddle() {
      const loyaltyProgramsLength = this.loyaltyPrograms.length;

      if (loyaltyProgramsLength < 3) {
        return;
      }

      const middleIndex = Math.floor(this.loyaltyPrograms.length / 2);

      this.$refs.carousel[middleIndex].scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
      });
    },
    navigateToLoyaltyProgram(loyaltyProgram) {
      this.$router.push({
        name: 'LoyaltyProgramDetails',
        params: {
          loyaltyProgramId: loyaltyProgram.id,
          session: this.$store.state.session,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: scroll;
  align-items: center;
  padding-top: 12px;

  /* Hide the scrollbar for Internet Explorer, Edge and Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide the scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
