<template>
    <div class="popular-badge" v-bind:class="badgeClass">
        {{ $t('components.menuItem.popular') }}
    </div>
</template>

<script>
  export default {
    props: {
      bigger: Boolean,
    },
    computed: {
      badgeClass() {
        return {
          'bigger': this.bigger
        }
      }
    }
  }
</script>

<style lang="scss">
    .popular-badge {
        background: black;
        border-radius: 16px;
        color: white;
        padding: 0 10px;
        font-size: 0.65rem !important;
        height: 16px;

        &.bigger {
            font-size: 0.8rem !important;
            height: 20px;
        }
    }
</style>