<template>
  <div class="card-container" :style="cssProps">
    <div class="card">
      <div class="card-inner">
        <div class="front">
          <div
            v-if="loyaltyProgram.logo && !hasCustomImage"
            class="logo-wrapper"
            :style="{
              small: true
            }"
          >
            <img :src="loyaltyProgram.logo" class="logo-img" />
          </div>
        </div>
        <div class="back">
          <div class="logo-wrapper">
            <h3 v-if="!hasCustomImage && !small">{{ loyaltyProgram.name }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loyaltyProgram: {
      type: Object,
      required: true,
    },
    spin: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '220px',
    },
    small: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    backgroundImage() {
      const { card_color, card_secondary_color, image } = this.loyaltyProgram;
      const cardColor = card_color || '#ec77ab';
      const cardSecondaryColor = card_secondary_color || '#f7b801';

      const cardImage = image
        ? `url(${image})`
        : `linear-gradient(45deg, ${cardColor}, ${cardSecondaryColor})`;

      return cardImage;
    },
    hasCustomImage() {
      return !!this.loyaltyProgram.image;
    },
    cssProps() {
      return {
        '--card-color': this.loyaltyProgram.card_color || '#ec77ab',
        '--card-secondary-color':
          this.loyaltyProgram.card_secondary_color || '#f7b801',
        '--rotate': this.spin ? 'rotateY(360deg)' : 'rotateY(0deg)',
        '--width': this.width,
        '--height': this.height,
        '--image': this.backgroundImage,
        '--border-radius': this.small ? '5px' : '15px',
        '--logo-height': this.small ? '30px' : '80px',
        '--container-height': this.small ? null : '100%',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  width: var(--container-height);
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  width: var(--width);
  height: var(--height);
  color: #fff;
  cursor: pointer;
  perspective: 1000px;
}

.card-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transition: transform 1.5s ease;
  transform-style: preserve-3d;
  animation: 1.5s ease 0.1s 1 slideInFromLeft;
}

.front,
.back {
  width: 100%;
  height: 100%;
  background-image: var(--image);
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--border-radius);
  overflow: hidden;
  z-index: 1;
  backface-visibility: hidden;
}

.logo-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.logo-img {
  height: var(--logo-height);
  z-index: -1;
}

.back {
  transform: rotateY(180deg);
}

.card:hover .card-inner {
  transform: var(--on-hover-rotate);
}

@keyframes slideInFromLeft {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: var(--rotate);
  }
}
</style>
