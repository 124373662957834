<template>
  <img
    class="lazy-img"
    v-bind:class="{ 'lazy-img--loading': !noBlur }"
    :src="!loaded ? lazyImg : img"
    v-intersect="{
      handler: onIntersect,
      options: {
        threshold: 1,
        rootMargin: '-50px 0px 100% 0px',
      },
    }"
  />
</template>

<script>
export default {
  props: {
    img: String,
    lazyImg: String,
    noBlur: Boolean,
  },
  data() {
    return {
      loaded: false,
    };
  },
  methods: {
    onIntersect(entries) {
      entries.forEach(({ target, isIntersecting }) => {
        if (isIntersecting) {
          target.classList.remove("lazy-img--loading");
          this.loaded = true;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.lazy-img {
  transition: filter 0.1s linear;
  &--loading {
    filter: blur(3px);
    transition: filter 0.1s linear;
  }
}
</style>
