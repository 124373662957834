<template>
  <LayoutCard rounded>
    <div class="option-group">
      <div>
        <div class="d-flex flex-row justify-space-between tp-text-caption">
          <!-- TODO not sure why the same for functionalityOrderEnabled  -->
          <div
            v-if="
              $store.getters.functionalityOrderEnabled &&
                !this.$store.getters.ordersTemporarilyDisabled
            "
            class="d-flex flex-column"
          >
            <div v-if="max_choices > 1 && !singleSelection" class="mb-1">
              {{
                $t('components.options.choose_up_to_x_options', {
                  amount: max_choices,
                })
              }}
            </div>
            <div class="tp-text-subtitle">
              {{ $t(name) }}
            </div>
          </div>
          <div v-else>
            <div v-if="max_choices > 1 && !singleSelection">
              {{
                $t('components.options.can_choose_up_to_x_options', {
                  amount: max_choices,
                })
              }}
            </div>
            <div v-if="max_choices < 2 && !singleSelection">
              {{
                $t('components.options.can_choose_up_to_one_option', {
                  amount: max_choices,
                })
              }}
            </div>
            <div class="tp-text-subtitle">
              {{ $t(name) }}
            </div>
          </div>
          <div>
            <v-chip
              v-if="type === 'single' || min_choices > 0"
              class="d-flex chip tp-text-label"
              small
            >
              {{ $t('components.options.required') }}
            </v-chip>
          </div>
        </div>
      </div>
      <div class="mt-6">
        <v-radio-group
          v-if="singleSelection"
          v-model="radioSelected"
          hide-details
          class="mt-0 pt-0"
        >
          <Option
            v-for="(option, index) in getOptions"
            :key="`option-${index}`"
            v-bind="{
              ...option,
              displayFullPrice: display_full_price,
              menuItemPrice,
              menuItemLoyaltyPrice,
              isCategoryItemsAvailable
            }"
            single-selection
          ></Option>
        </v-radio-group>

        <Option
          v-for="(option, index) in getOptions"
          v-else
          :key="`option-${index}`"
          v-model="optionsSelection[index]"
          v-bind="{...option, isCategoryItemsAvailable}"
          :max-selections-reached="maxSelectionsReached"
        ></Option>
      </div>
    </div>
  </LayoutCard>
</template>

<script>
import Option from '@/components/MenuItem/Options/Option.vue';
import LayoutCard from '@/components/LayoutCard.vue';

export default {
  components: {
    Option,
    LayoutCard,
  },
  props: {
    name: String,
    type: String,
    max_choices: Number,
    min_choices: Number,
    display_full_price: Boolean,
    options: Array,
    id: {
      type: Number,
      required: true,
    },
    selection: Array,
    menuItemPrice: Number,
    menuItemLoyaltyPrice: [Number, Boolean],
    isCategoryItemsAvailable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      singleSelection: this.type === 'single',
      radioSelected: this.getDefaultRadioSelected(),
      optionsSelection: this.selection,
    };
  },
  computed: {
    getOptions() {
      return this.options.filter((option) => {
        return !!option.active;
      })
    },
    maxSelectionsReached() {
      const selectionCount = this.optionsSelection.filter(option => {
        return option.selected;
      }).length;

      return this.max_choices <= selectionCount;
    },
  },
  watch: {
    radioSelected() {
      this.optionsSelection = this.optionsSelection.map(option => {
        return {
          id: option.id,
          selected: this.radioSelected === option.id,
        };
      });
    },
    optionsSelection: {
      handler() {
        this.$emit('optionSelectionChange', {
          optionGroupId: this.id,
          selection: this.optionsSelection,
        });
      },
      deep: true,
    },
  },
  methods: {
    getDefaultRadioSelected() {
      const defaultOptions = this.options.filter(option => {
        return option.is_default;
      });
      if (defaultOptions.length > 0) {
        return defaultOptions[0].id;
      }

      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-1 {
  flex: 1 !important;
}
.chip {
  background-color: $color-gray-500 !important;
  border-radius: 16px !important;
  padding: 3px 9.5px !important;
  color: $color-white !important;
}
</style>
