<template>
  <div>
    <slot name="activator" :toggle="toggle"></slot>
    <v-bottom-sheet
      :value="isOpen"
      :max-width="maxAppWidth"
      @click:outside="close"
    >
      <v-sheet class="pa-4 pb-6" rounded="xl b-0" min-height="250px">
        <div class="close-review-wrapper">
          <img
            class="close-review "
            :src="require(`@/assets/images/icons/chevron-down-white-icon.svg`)"
            @click="close"
          />
        </div>
        <LeaveReview
          :tips-qr-card-hash="tipsQrCardHash"
          :unique-order-number="uniqueOrderNumber"
          class="mt-1"
        />
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import LeaveReview from '@/components/Reviews/Review.vue';

export default {
  components: {
    LeaveReview,
  },
  props: {
    uniqueOrderNumber: {
      type: String,
      required: false,
      default: null,
    },
    tipsQrCardHash: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
.close-review-wrapper {
  position: relative;
  background: lightgray;
}

.close-review {
  border-radius: 32px;
  position: absolute;
  right: 0;
}
</style>
