<template>
  <v-container class="pa-0 status-container">
    <NavHeader
      :title="$t('screens.paymentSuccess.headerTitle')"
      :show-back-arrow="false"
      :subtitle="tableName"
    />
    <div class="h-100">
      <LayoutCard grow>
        <div
          class="d-flex flex-column justify-center align-center text-center h-100"
        >
          <!-- changing lottie src during runtime results in animations not changing this is a workaround -->
          <lottie-vue-player
            v-show="status.animation === 'success'"
            src="/assets/order-status-animations/successful-payment.json"
            style="width: 60px; height:60px"
            autoplay
            :loop="false"
            class="mb-4"
          />
          <lottie-vue-player
            v-show="status.animation === 'loading'"
            src="/assets/order-status-animations/loading.json"
            style="width: 100px; height:100px"
            autoplay
            loop
            class="mb-4"
          />
          <lottie-vue-player
            v-if="status.animation === 'error'"
            src="/assets/order-status-animations/failed-payment.json"
            style="width: 70px; height:70px"
            autoplay
            :loop="false"
            class="mb-4"
          />
          <div v-show="status.showCountDown">
            <Countdown
              :end-date="new Date(countdownEndDate)"
              @ended="onEnded"
              @ending-soon="onEndingSoon"
            >
              <template #default="{ minutes, seconds }">
                <div
                  class="d-flex flex-column justify-center align-center text-center"
                >
                  <span class="countdownText mb-4">
                    {{ minutes }}:{{ seconds }}
                  </span>
                </div>
              </template>
            </Countdown>
          </div>
          <h3 class="mb-4">{{ $t(status.title) }}</h3>
          <p>
            {{ $t(status.content) }}
          </p>
          <v-fade-transition>
            <v-alert
              v-show="endingSoon"
              dense
              color="#FCECD3"
              class="text-left"
            >
              <v-row align="center">
                <v-col class="shrink">
                  <v-icon small color="black">mdi-alert</v-icon>
                </v-col>
                <v-col class="grow tp-text-body">
                  {{ $t('screens.paymentStatus.loading.longerThanExpected') }}
                </v-col>
              </v-row>
            </v-alert>
          </v-fade-transition>
        </div>
        <div class="mt-auto"></div>
        <v-fade-transition>
          <Button
            v-if="status.showReturnToOrder"
            dark
            class="mb-8"
            @click="navigateToStart"
          >
            {{ $t('screens.paymentStatus.error.cta') }}
          </Button>
        </v-fade-transition>
      </LayoutCard>
    </div>
  </v-container>
</template>

<script>
import LayoutCard from '@/components/LayoutCard.vue';
import NavHeader from '@/components/Navigation/NavHeader.vue';
import Countdown from '@/components/common/Countdown.vue';
import Button from '@/components/Button.vue';

const STATUS = {
  success: {
    title: 'screens.paymentStatus.success.title',
    content: 'screens.paymentStatus.success.content',
    animation: 'success',
    showCountDown: false,
  },
  loading: {
    title: 'screens.paymentStatus.loading.title',
    content: 'screens.paymentStatus.loading.content',
    animation: 'loading',
    showCountDown: true,
  },
  error: {
    title: 'screens.paymentStatus.error.title',
    content: 'screens.paymentStatus.error.content',
    animation: 'error',
    showCountDown: false,
    showReturnToOrder: true,
    loopAnimation: false,
  },
};

export default {
  components: {
    LayoutCard,
    NavHeader,
    Countdown,
    Button,
  },
  data() {
    return {
      isLoading: false,
      uniqueOrderNumber: null,
      failedPayment: false,
      paymentUuid: null,
      endingSoon: false,
      ended: false,
      status: STATUS.loading,
    };
  },
  computed: {
    session() {
      return this.$store.state.session;
    },
    tableName() {
      return this.$store.state.table.name;
    },
    countdownEndDate() {
      const now = new Date();
      const oneMinuteFromNow = new Date(now.getTime() + 120 * 1000);
      return new Date(oneMinuteFromNow);
    },
  },
  async mounted() {
    const sessionToken = this.$route.params.session;
    this.isLoading = true;

    await this.$store.dispatch('fetchSessionData', { session: sessionToken });

    const uniqueOrderNumber = this.$route.query.orderNumber;
    const paymentUuid = this.$route.query.paymentUuid;

    this.uniqueOrderNumber = uniqueOrderNumber;
    this.paymentUuid = paymentUuid;

    await this.fetchPaymentDetails();

    this.isLoading = false;
  },
  methods: {
    async fetchPaymentDetails() {
      const isNeopay = this.$route.query['neopay-callback'] === '1';

      if (isNeopay) {
        await this.fetchNeoPayPaymentData();
        return;
      }
    },
    async fetchNeoPayPaymentData() {
      const token = this.$route.query.token;
      const data = this.$route.query.data;
      const canceled = this.$route.query.canceled === '1';
      const pending = this.$route.query.pending === '1';

      const onPaymentSuccess = data => {
        this.isLoading = false;
        this.paymentUuid = data.uuid;
        this.status = STATUS.success;
        this.goForward();
      };
      const onPaymentError = () => {
        this.isLoading = false;
        this.failedPayment = true;
        this.status = STATUS.error;
      };
      if (pending) {
        this.status = STATUS.loading;
        return this.$store.dispatch('tryToFetchNeopayPaymentData', {
          token: data,
          onPaymentSuccess,
          onPaymentError,
        });
      }

      if (canceled) {
        this.isLoading = false;
        this.navigateToStart();
        return;
      }

      if (token) {
        return this.$store.dispatch('tryToFetchNeopayPaymentData', {
          token,
          onPaymentSuccess,
          onPaymentError,
        });
      }
    },
    async goForward() {
      const origin = this.$route.query.origin;

      const SUCCESS_ROUTES = {
        PAY_GO_SUCCESS: 'PaymentSuccess',
        ORDER_SUCCESS: 'Menu',
      };
      const REDIRECT = {
        pay: SUCCESS_ROUTES.PAY_GO_SUCCESS,
        order: SUCCESS_ROUTES.ORDER_SUCCESS,
      };
      const routerName = REDIRECT[origin] || SUCCESS_ROUTES.PAY_GO_SUCCESS;

      if (routerName === SUCCESS_ROUTES.ORDER_SUCCESS) {
        await this.$store.dispatch('openOrder', this.uniqueOrderNumber);
      }

      this.$router.push({
        name: routerName,
        replace: true,
        query: {
          orderNumber: this.uniqueOrderNumber,
        },
        params: {
          session: this.session,
        },
      });
    },
    navigateToStart() {
      const origin = this.$route.query.origin;
      const REDIRECT = {
        pay: 'Order',
        order: 'Menu',
      };
      const routerName = REDIRECT[origin] || REDIRECT.order;

      this.$router.push({
        name: routerName,
        replace: true,
        params: {
          session: this.session,
        },
      });
    },
    onEndingSoon() {
      if (this.ended) return;

      if (this.status === STATUS.loading) {
        this.endingSoon = true;
      }
    },
    onEnded() {
      this.status = STATUS.error;
      this.endingSoon = false;
      this.ended = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: $color-background-gray;
  display: flex;
  flex-flow: column;
  min-height: 100svh;
  height: 100%;
}

.countdownText {
  font-size: 24px;
  font-weight: 700;
}
</style>
