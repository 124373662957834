<template>
  <div class="donate-success-container">
    <EasyWithGreet
      v-if="!isLoading"
      style="position: absolute; top: 32px; left: 50%; transform: translate(-50%, -50%);"
    />
    <div class="close-button">
      <v-icon
        v-if="!isLoading"
        color="white"
        data-cy="donate-success-close"
        @click="returnBackToDonate"
      >
        mdi-close
      </v-icon>
    </div>
    <div v-if="isLoading" class="donate-success-content">
      <v-progress-circular indeterminate color="white" />
    </div>
    <div v-else class="donate-success-content pa-8">
      <div v-if="failedPayment">
        <h3 class="pt-4">
          {{ $t('screens.donateSuccess.failed.title') }}
        </h3>
        {{ $t('screens.donateSuccess.failed.subtitle') }}
      </div>
      <div v-else class="w-100">
        <div>
          <Avatar
            :name="donatee.name"
            :photo-url="donatee.cover"
            :lazy-src="donatee.cover_thumbnail"
            :on-click="emojiRain"
            :size="150"
          />
        </div>
        <h3 class="pt-4" data-cy="donate-success-name">
          {{ donatee.name }}
        </h3>
        <div class="pb-6">
          <span data-cy="donate-success-donation-amount">
            {{ $t('screens.donateSuccess.title', { amount: amountFormatted }) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { emojisplosion, emojisplosions } from 'emojisplosion';

import EasyWithGreet from '@/components/EasyWithGreet.vue';
import Avatar from '@/components/common/Avatar.vue';
import { parseQueryParam } from '../../utils/strings';

const physics = {
  fontSize: {
    min: 34,
    max: 44,
  },
};

const moneyEmojis = ['🫰', '♥️'];

export default {
  components: {
    Avatar,
    EasyWithGreet,
  },
  data() {
    return {
      isLoading: true,
      stripe: null,
      donateQrCardHash: null,
      amount: null,
      failedPayment: false,
      paymentUuid: null,
    };
  },
  computed: {
    donatee() {
      return this.$store.getters.donatee;
    },
    amountFormatted() {
      return this.$store.getters.getFormattedPrice(this.amount);
    },
  },
  async mounted() {
    const hash = this.$route.params.donateQrCardHash;
    const query = this.$route.query;

    const paymentUuid = parseQueryParam(query.paymentUuid);

    this.isLoading = true;

    this.donateQrCardHash = hash;
    this.paymentUuid = paymentUuid;

    await this.$store.dispatch('fetchDonationSession', {
      hash,
    });
    await this.loadStripeIfNeeded();
    await this.fetchPaymentDetails();

    this.isLoading = false;
  },
  methods: {
    async loadStripeIfNeeded() {
      if (!this.stripe) {
        this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_PK || '');
      }
    },
    async checkNeoPayPaymentStatus(token) {
      return this.$store.dispatch('checkNeoPayPaymentStatus', {
        token,
      });
    },
    moneyEmojiExplosion() {
      const { cancel } = emojisplosions({
        emojis: moneyEmojis,
        emojiCount: 6,
        interval: 300,
        physics,
      });

      setTimeout(cancel, 1500);
    },
    returnBackToDonate() {
      this.$router.replace({
        name: 'Donate',
        params: {
          donateQrCardHash: this.donateQrCardHash,
        },
      });
    },
    async tryToFetchNeopayPaymentData(token) {
      let retryAttempts = 0;
      const retryAttemptsLimit = 20;
      const retryInterval = 10000;

      while (retryAttempts < retryAttemptsLimit) {
        const { amount } = await this.checkNeoPayPaymentStatus(token);

        if (amount) {
          this.amount = amount;
          this.moneyEmojiExplosion();
          return;
        }

        retryAttempts += 1;

        await new Promise(resolve => setTimeout(resolve, retryInterval));
      }

      this.failedPayment = true;
      this.isLoading = false;
      return;
    },
    async fetchNeoPayPaymentData() {
      const token = this.$route.query.token;
      const data = this.$route.query.data;
      const canceled = this.$route.query.canceled === '1';
      const pending = this.$route.query.pending === '1';

      if (pending) {
        return this.tryToFetchNeopayPaymentData(data);
      }

      if (canceled) {
        this.isLoading = false;
        this.returnBackToDonate();
        return;
      }

      if (token) {
        return this.tryToFetchNeopayPaymentData(token);
      }
    },
    async fetchPaymentDetails() {
      const isNeopay = this.$route.query['neopay-callback'] === '1';

      if (isNeopay) {
        await this.fetchNeoPayPaymentData();
        return;
      }

      try {
        const paymentDetailsResponse = await this.axios.get(
          `/payments/payment-by-uuid/${this.paymentUuid}`
        );
        const { amount } = paymentDetailsResponse.data.data;

        this.amount = amount;
        this.moneyEmojiExplosion();
      } catch (error) {
        console.error(error);
        this.isLoading = false;
      }
    },
    emojiRain() {
      emojisplosion({
        emojis: moneyEmojis,
        emojiCount: 7,
        physics,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.donate-success-container {
  height: 100vh;
  height: 100dvh;
  background-color: $color-donations;
}

.donate-success-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
}

.close-button {
  position: absolute;
  top: 28px;
  right: 28px;
  z-index: 10;
}
</style>
