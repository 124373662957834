<template>
  <div>
    <div ref="avatarContainerRef" class="avatar-container">
      <div v-for="(avatar, key) in avatars" :key="key">
        <v-img
          class="avatar"
          :src="avatar.avatar_url"
          :style="{
            top: avatar?.style?.top + 'px',
            left: avatar?.style?.left + 'px',
            transitionDuration: avatar?.style?.transitionDuration + 'ms',
          }"
          @click="scatter"
        >
          <span class="tp-text-label-black label">
            {{ $t(avatar.nickName) }}
          </span>
        </v-img>
      </div>
    </div>
    <div class="tp-text-body text-center">
      {{
        $t('screens.order.orderStatusWaitingOthersDisclaimer', {
          time: batchCountDown,
        })
      }}
    </div>
    <Button
      dark
      x-small
      :disabled="sendToPosDisabled"
      :loading="sendToPosDisabled"
      @click="sendToPos"
    >
      {{ $t('screens.order.sentToPosCta') }}
    </Button>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';

export default {
  name: 'FloatingAvatars',
  components: {
    Button,
  },
  props: {
    guestCount: {
      type: Number,
      default: 0,
    },
    uniqueOrderNumber: {
      type: String,
      required: false,
      default: null,
    },
    batchActivateAt: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      avatars: [],
      sendToPosDisabled: false,
      batchCountDown: '',
    };
  },
  computed: {
    predefinedPlaces() {
      const h = this.$refs.avatarContainerRef.clientHeight - 80;
      const w = this.$refs.avatarContainerRef.clientWidth - 70;

      const h2 = Math.floor(h / 2 + 45);
      const h4 = Math.floor(h / 2 - 45);

      const w1 = Math.floor(w / 4 - w / 8);
      const w2 = Math.floor((w / 4) * 2 - w / 8);
      const w3 = Math.floor((w / 4) * 3 - w / 8);
      const w4 = Math.floor((w / 4) * 4 - w / 8);

      return {
        1: {
          top: h4,
          left: w1,
        },
        2: {
          top: h2,
          left: w2,
        },
        3: {
          top: h4,
          left: w3,
        },
        4: {
          top: h2,
          left: w4,
        },
        5: {
          top: h4,
          left: w4,
        },
        6: {
          top: h2,
          left: w3,
        },
        7: {
          top: h4,
          left: w2,
        },
        8: {
          top: h2,
          left: w1,
        },
      };
    },
  },
  watch: {
    guestCount() {
      this.avatars = Array.from({ length: this.guestCount }, (_, key) => {
        const [nh, nw, s, face, nickName] = this.newPosition(key);
        return {
          avatar_url: face,
          style: {
            top: nh,
            left: nw,
            transitionDuration: s,
          },
          nickName,
        };
      });
    },
  },
  mounted() {
    this.avatars = Array.from({ length: this.guestCount }, (_, key) => {
      const [nh, nw, s, face, nickName] = this.newPosition(key);
      return {
        avatar_url: face,
        style: {
          top: nh,
          left: nw,
          transitionDuration: s,
        },
        nickName,
      };
    });
    this.$store.dispatch('sendAnalyticsEvent', {
      event: 'app_order-info_batch-screen_view',
    });

    this.setCountDownTimer();
  },
  methods: {
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    newPosition(key) {
      if (key < 8) {
        return [
          this.predefinedPlaces[key + 1].top,
          this.predefinedPlaces[key + 1].left,
          1000,
          `https://cdn.greet.menu/greet-face-icons/face-${key + 1}.png`,
          this.$t(`screens.order.foodie.${key + 1}`),
        ];
      }

      const h = this.$refs.avatarContainerRef.clientHeight - 80;
      const w = this.$refs.avatarContainerRef.clientWidth - 80;
      const nh = this.getRandomInt(10, h);
      const nw = this.getRandomInt(10, w);
      const s = Math.floor(Math.random() * 10) * 300;
      const randomFace = this.getRandomInt(1, 10);
      const face = `https://cdn.greet.menu/greet-face-icons/face-${randomFace}.png`;
      const randomNickname = this.$t(
        `screens.order.foodie.${this.getRandomInt(1, 8)}`
      );

      return [nh, nw, s, face, randomNickname];
    },
    addAvatar() {
      const [nh, nw, s, face, nickName] = this.newPosition(this.avatars.length);
      const newAvatar = {
        avatar_url: face,
        style: {
          top: nh,
          left: nw,
          transitionDuration: s,
        },
        nickName,
      };

      this.avatars.push(newAvatar);
    },
    scatter() {
      this.avatars = this.avatars.map(avatar => {
        const [nh, nw, s] = this.newPosition();
        avatar.style.top = nh;
        avatar.style.left = nw;
        avatar.style.transitionDuration = s;
        return avatar;
      });
    },
    async sendToPos() {
      this.sendToPosDisabled = true;
      await this.$store.dispatch('activateOrderBatch', {
        uniqueOrderNumber: this.uniqueOrderNumber,
      });
      this.sendToPosDisabled = false;

      this.$store.dispatch('sendAnalyticsEvent', {
        event: 'app_order-info_batch-screen_send-instantly',
      });
    },
    setCountDownTimer() {
      if (this.batchActivateAt && !this.batchCountDownInterval) {
        const now = this.$moment();
        const activatedAt = this.$moment(this.batchActivateAt);
        const diff = activatedAt.diff(now, 'seconds');
        this.batchCountDown = this.$moment.utc(diff * 1000).format('mm:ss');

        this.batchCountDownInterval = setInterval(() => {
          const now = this.$moment();
          const activatedAt = this.$moment(this.batchActivateAt);
          const diff = activatedAt.diff(now, 'seconds');

          if (diff <= 0) {
            clearInterval(this.batchCountDownInterval);
            this.batchCountDownInterval = '00:00';
          }
          this.batchCountDown = this.$moment.utc(diff * 1000).format('mm:ss');
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  width: 70px;
  height: 70px;
  overflow: visible;
  box-shadow: 0 0px 5px 0px rgba(0, 0, 0, 0.05);
  animation: float 6s ease-in-out infinite;

  border-radius: 50%;
  position: absolute;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-5px);
  }
  100% {
    transform: translatey(0px);
  }
}

.avatar-container {
  width: 100%;
  height: 200px;
  position: relative;
  border-radius: 10px;
  margin-top: 12px;
}

.label {
  top: 72px;
  position: absolute;
  text-align: center;
  flex-wrap: no-wrap;
}
</style>
