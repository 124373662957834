import Vue from 'vue';

export const callWaiterRequest = async ({ session, orderNumber }) => {
  const response = await Vue.axios.post(
    `${process.env.VUE_APP_API_URL}call-waiter/call`,
    {
      session,
      unique_order_number: orderNumber,
    }
  );
  return response.data;
};
