<template>
  <v-overlay :value="backdropVisible">
    <div class="d-flex flex-column justify-center align-center">
      <lottie-vue-player
        :src="backdrop.image"
        style="width: 100px; height:100px"
        autoplay
        loop
      />
      <p v-if="backdrop?.text" class="tp-text-subtitle-bold white--text pt-6">
        {{ $t(backdrop.text) }}
      </p>
    </div>
  </v-overlay>
</template>

<script>
const BACKDROPS = {
  success: {
    text: 'screens.payment.paymentSuccessful',
    image: '/assets/order-status-animations/successful-payment.json',
  },
  loading: {
    text: 'screens.payment.paymentInProgress',
    image: '/assets/order-status-animations/loading.json',
  },
  error: {
    text: 'screens.payment.paymentFailed',
    image: '/assets/order-status-animations/failed-payment.json',
  },
  loadingGeneric: {
    image: '/assets/order-status-animations/loading.json',
  },
};

export default {
  data: () => ({
    backdropVisible: false,
    backdrop: {},
  }),
  methods: {
    async show(backdrop, timeout) {
      await this.hide(); // hide previous backdrop
      this.backdropVisible = true;
      this.backdrop = BACKDROPS[backdrop]

      if (timeout) {
        await new Promise(resolve => setTimeout(resolve, timeout));
        this.hide();
      }
    },
    hide() {
      this.backdropVisible = false;
      this.backdrop = {};
    },
  },
};
</script>

<style></style>
