var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"extra-details"},[_c('NavHeader',{attrs:{"title":_vm.$t('screens.extraDetails.headerTitle')},on:{"onBackClick":_vm.goBack}}),_c('LayoutCard',{attrs:{"title":_vm.$t('screens.extraDetails.formTitle'),"rounded":"","required":""}},[_c('div',{staticClass:"tp-text-body"},[_vm._v(_vm._s(_vm.fieldsText))]),_c('div',{staticClass:"pb-0"},_vm._l((_vm.additionalFields),function(field,key){return _c('div',{key:key},[(field.type === 'autocomplete')?_c('v-autocomplete',{key:field.key,staticClass:"py-3",attrs:{"rules":[
            () =>
              !field.required ||
              !!_vm.details[field.key] ||
              _vm.$t('generic.requiredField'),
          ],"label":_vm.$t(field.placeholder),"placeholder":_vm.$t(field.placeholder),"items":field.select_options.map(option => ({
              label: _vm.$t(option),
              value: option,
            })),"item-text":"label","item-value":"value"},model:{value:(_vm.details[field.key]),callback:function ($$v) {_vm.$set(_vm.details, field.key, $$v)},expression:"details[field.key]"}}):(field.type === 'select')?_c('v-select',{key:field.key,staticClass:"py-3",attrs:{"rules":[
            () =>
              !field.required ||
              !!_vm.details[field.key] ||
              _vm.$t('generic.requiredField'),
          ],"label":_vm.$t(field.placeholder),"placeholder":_vm.$t(field.placeholder),"items":field.select_options.map(option => ({
              label: _vm.$t(option),
              value: option,
            })),"item-text":"label","item-value":"value"},model:{value:(_vm.details[field.key]),callback:function ($$v) {_vm.$set(_vm.details, field.key, $$v)},expression:"details[field.key]"}}):(field.type === 'checkbox')?_c('v-checkbox',{key:field.key,attrs:{"rules":[
            () =>
              !field.required ||
              !!_vm.details[field.key] ||
              _vm.$t('generic.requiredField'),
          ],"label":_vm.$t(field.placeholder)},model:{value:(_vm.details[field.key]),callback:function ($$v) {_vm.$set(_vm.details, field.key, $$v)},expression:"details[field.key]"}}):_c('v-text-field',{key:field.key,staticClass:"py-3",attrs:{"rules":[
            () =>
              !field.required ||
              !!_vm.details[field.key] ||
              _vm.$t('generic.requiredField'),
          ],"label":_vm.$t(field.placeholder),"placeholder":_vm.$t(field.placeholder),"type":"text","maxlength":"100"},model:{value:(_vm.details[field.key]),callback:function ($$v) {_vm.$set(_vm.details, field.key, $$v)},expression:"details[field.key]"}})],1)}),0)]),(_vm.functionalitySkipPaymentEnabled)?_c('LayoutCard',{attrs:{"rounded":"","primary":""}},[_c('div',{staticClass:"tp-text-subtitle white--text"},[_vm._v(" "+_vm._s(_vm.$store.getters.customizationExtraDetailsDisclaimerTitle ? _vm.$t(_vm.$store.getters.customizationExtraDetailsDisclaimerTitle) : _vm.$t('screens.extraDetails.disclaimerTitle'))+" ")]),_c('div',{staticClass:"tp-text-body white--text pt-3"},[_vm._v(" "+_vm._s(_vm.$store.getters.customizationExtraDetailsDisclaimerSubtitle ? _vm.$t(_vm.$store.getters.customizationExtraDetailsDisclaimerSubtitle) : _vm.$t('screens.extraDetails.disclaimerSubtitle'))+" ")])]):_vm._e(),_c('CartSubtotal',{attrs:{"id":"paySummary"}}),_c('div',{staticClass:"px-10 py-3"},[_c('Button',{attrs:{"dark":"","disabled":!_vm.formValid || _vm.loading,"loading":_vm.loading,"data-cy":"confirm-details"},on:{"click":_vm.confirmOrder}},[_vm._v(" "+_vm._s(_vm.ctaLabel)+" ")])],1),_c('v-overlay',{attrs:{"value":_vm.backdropVisible}},[_c('div',{staticClass:"d-flex flex-column justify-center align-center"},[_c('lottie-vue-player',{staticStyle:{"width":"100px","height":"100px"},attrs:{"src":_vm.backdrop.image,"autoplay":"","loop":"","background-color":"transparent"}}),_c('p',{staticClass:"tp-text-subtitle-bold white--text pt-6"},[_vm._v(" "+_vm._s(_vm.$t(_vm.backdrop.text))+" ")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }