<template>
  <LayoutCard v-if="isOpen" primary>
    <div class="d-flex flex-row">
      <img :src="require(`@/assets/images/icons/bell-icon.svg`)" class="mr-3" />
      <div>
        <div class="tp-text-subtitle-semibold white--text pb-1">
          {{ $t('screens.order.turnOnNotificationsHeader') }}
        </div>
        <div class="tp-text-body white--text">
          {{
            notificationsAvailable
              ? $t('screens.order.turnOnNotificationsBody')
              : $t('screens.order.turnOnSMSNotificationsBody')
          }}
        </div>
      </div>
    </div>
    <Button class="mt-3" color="white" white x-small @click="ctaClicked">
      {{
        notificationsAvailable
          ? $t('screens.order.turnOnNotificationsCta')
          : $t('screens.order.turnOnSMSNotificationsCta')
      }}
    </Button>
  </LayoutCard>
</template>

<script>
import LayoutCard from '@/components/LayoutCard.vue';
import Button from '@/components/Button.vue';

export default {
  name: 'NotificationBanner',
  components: {
    LayoutCard,
    Button,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    notificationsAvailable() {
      // return 'Notification' in window;
      return false;
    },
  },
  methods: {
    ctaClicked() {
      this.$emit('cta-click');
    },
  },
};
</script>

<style></style>
