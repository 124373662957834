<template>
  <Modal
    :is-open="!orderOnboardingDone"
    :cta-label="ctaLabel"
    :show-close-button="false"
    persistent
    @cta-click="ctaClicked"
  >
    <template slot="content">
      <div>
        <v-item-group v-model="onboardingSteps" mandatory>
          <v-window v-model="stepIndex">
            <v-window-item v-for="step in onboardingSteps" :key="step.id">
              <v-img
                aspect-ratio="1"
                contain
                :lazy-src="step.image"
                :src="step.image"
                :transition="false"
                height="200px"
              />
              <div class="tp-text-subtitle-semibold pt-7">
                {{ $t(step.title) }}
              </div>
              <div class="tp-text-body black--text pt-4">
                {{ $t(step.description) }}
              </div>
            </v-window-item>
          </v-window>
        </v-item-group>

        <div class="pt-7 pb-3">
          <v-row align="center" justify="center">
            <v-item-group v-model="stepIndex">
              <v-item
                v-for="n in onboardingSteps.length"
                :key="n"
                v-slot="{ active, toggle }"
              >
                <v-btn
                  :input-value="active"
                  icon
                  x-small
                  @click="toggle"
                >
                  <v-icon x-small>mdi-record</v-icon>
                </v-btn>
              </v-item>
            </v-item-group>
          </v-row>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/common/Modal.vue';

export default {
  components: {
    Modal,
  },
  data() {
    return {
      stepIndex: 0,
      length: 3,
      stepsDone: false,
      onboardingSteps: [
        {
          id: 1,
          title: 'components.onboarding.orders.step1.title',
          description: 'components.onboarding.orders.step1.description',
          image: 'https://cdn.greet.menu/choose_item_small_3.gif',
        },
        {
          id: 2,
          title: 'components.onboarding.orders.step2.title',
          description: 'components.onboarding.orders.step2.description',
          image: 'https://cdn.greet.menu/onboarding_step_2.png',
        },
        {
          id: 3,
          title: 'components.onboarding.orders.step3.title',
          description: 'components.onboarding.orders.step3.description',
          image: 'https://cdn.greet.menu/onboarding_step_3.png',
        },
      ],
    };
  },
  computed: {
    orderOnboardingDone() {
      return this.$store.getters.orderOnboardingDone;
    },
    ctaLabel() {
      return this.$i18n.translate(
        this.stepIndex === this.onboardingSteps.length - 1
          ? 'generic.understood'
          : 'generic.next'
      );
    },
  },
  methods: {
    ctaClicked() {
      if (this.stepIndex === this.onboardingSteps.length - 1) {
        this.$store.dispatch('setOrderOnboardingDone');
      } else {
        this.stepIndex++;
      }
    },
  },
};
</script>

<style></style>
