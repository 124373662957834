<template>
  <div class="request-invoice">
    <NavHeader
      :title="$t('components.requestInvoice.title')"
      @onBackClick="goBack"
    />
    <LayoutCard>
      <template v-if="!showSuccessScreen">
        <div class="tp-text-subtitle">
          {{ $t('components.requestInvoice.form.title') }}
        </div>
        <div class="tp-text-body mt-3">
          {{ $t('components.requestInvoice.form.subtitle') }}
        </div>
        <v-form ref="form" validate-on="submit" @submit.prevent="submitData">
          <v-text-field
            v-model="form.name"
            :disabled="form.loading"
            :rules="[() => !!form.name || $t('generic.requiredField')]"
            :label="$t('components.requestInvoice.form.namePlaceholder')"
            :placeholder="$t('components.requestInvoice.form.namePlaceholder')"
            type="text"
            class="py-3"
            maxlength="100"
          ></v-text-field>
          <v-text-field
            v-model="form.businessName"
            :disabled="form.loading"
            :rules="[() => !!form.businessName || $t('generic.requiredField')]"
            :label="
              $t('components.requestInvoice.form.businessNamePlaceholder')
            "
            :placeholder="
              $t('components.requestInvoice.form.businessNamePlaceholder')
            "
            type="text"
            class="py-3"
            maxlength="100"
          ></v-text-field>
          <v-text-field
            v-model="form.businessCode"
            :disabled="form.loading"
            :rules="[() => !!form.businessCode || $t('generic.requiredField')]"
            :label="
              $t('components.requestInvoice.form.businessCodePlaceholder')
            "
            :placeholder="
              $t('components.requestInvoice.form.businessCodePlaceholder')
            "
            type="text"
            class="py-3"
            maxlength="100"
          ></v-text-field>
          <v-text-field
            v-model="form.email"
            :disabled="form.loading"
            :rules="[() => !!form.email || $t('generic.requiredField')]"
            :label="$t('components.requestInvoice.form.emailPlaceholder')"
            :placeholder="$t('components.requestInvoice.form.emailPlaceholder')"
            type="text"
            class="py-3"
            maxlength="100"
          ></v-text-field>
          <v-text-field
            v-model="form.businessAddress"
            :disabled="form.loading"
            :rules="[
              () => !!form.businessAddress || $t('generic.requiredField'),
            ]"
            :label="
              $t('components.requestInvoice.form.businessAddressPlaceholder')
            "
            :placeholder="
              $t('components.requestInvoice.form.businessAddressPlaceholder')
            "
            type="text"
            class="py-3"
            maxlength="100"
          ></v-text-field>
          <v-autocomplete
            v-model="form.country"
            :items="countries"
            item-text="title"
            item-value="value"
            :disabled="form.loading"
            :rules="[() => !!form.country || $t('generic.requiredField')]"
            :label="$t('components.requestInvoice.form.countryPlaceholder')"
            :placeholder="
              $t('components.requestInvoice.form.countryPlaceholder')
            "
            class="py-3"
          ></v-autocomplete>

          <div
            class="d-flex align-center justify-space-between"
            :class="{ 'mb-8': !form.enableVatCodeField }"
          >
            <div class="tp-text-subtitle-semibold">
              {{ $t('components.requestInvoice.form.vatCodeQuestion') }}
            </div>
            <v-switch
              v-model="form.enableVatCodeField"
              hide-details
              class="mt-0"
            ></v-switch>
          </div>
          <v-text-field
            v-if="form.enableVatCodeField"
            v-model="form.vatCode"
            :disabled="form.loading"
            :rules="[
              () =>
                (form.enableVatCodeField && !!form.vatCode) ||
                $t('generic.requiredField'),
            ]"
            :label="$t('components.requestInvoice.form.vatCodePlaceholder')"
            :placeholder="
              $t('components.requestInvoice.form.vatCodePlaceholder')
            "
            type="text"
            class="py-3"
            maxlength="100"
          ></v-text-field>

          <div class="px-6">
            <Button dark :disabled="form.loading">
              <v-progress-circular
                v-if="form.loading"
                indeterminate
                :size="15"
                :width="2"
              ></v-progress-circular>
              <span v-else>{{
                $t('components.requestInvoice.form.submitButton')
              }}</span>
            </Button>
          </div>

          <div
            v-if="error"
            class="text-center mt-3 tp-text-caption"
            :style="{ color: 'red' }"
          >
            {{ error }}
          </div>
        </v-form>
      </template>
      <template v-else>
        <div
          class="d-flex flex-column justify-space-between"
          :style="{ minHeight: `80vh` }"
        >
          <div></div>
          <div class="d-flex flex-column align-center justify-center">
            <img
              :src="require(`@/assets/images/icons/checkmark-success.svg`)"
              width="128"
            />
            <div class="tp-text-title-bold mt-8">
              {{ $t('components.requestInvoice.success.title') }}
            </div>
            <div class="tp-text-body text-center mt-3">
              {{ $t('components.requestInvoice.success.subtitle') }}
            </div>
          </div>
          <div class="px-6">
            <Button dark @click="goBack">
              <span>{{ $t('components.requestInvoice.success.cta') }}</span>
            </Button>
          </div>
        </div>
      </template>
    </LayoutCard>
  </div>
</template>

<script>
import NavHeader from '@/components/Navigation/NavHeader.vue';
import LayoutCard from '@/components/LayoutCard.vue';
import Button from '@/components/Button.vue';
import { CountriesList } from '@/utils/constants';

export default {
  components: {
    NavHeader,
    LayoutCard,
    Button,
  },
  data() {
    return {
      form: {
        name: null,
        businessName: null,
        businessCode: null,
        businessAddress: null,
        email: null,
        country: 'LT',
        enableVatCodeField: false,
        vatCode: null,
        loading: false,
      },
      showSuccessScreen: false,
      error: null,
    };
  },
  computed: {
    countries() {
      const countries = CountriesList;

      return Object.entries(countries).map(([code, name]) => {
        return {
          title: name,
          value: code,
        };
      });
    },
  },
  async mounted() {
    this.form.loading = true;
    const sessionToken = this.$route.params.session;

    await this.$store.dispatch('fetchSessionData', {
      session: sessionToken,
      force: true,
    });
    await this.$store.dispatch('fetchOrders');

    this.form.loading = false;
  },
  methods: {
    async submitData() {
      this.form.loading = true;
      this.error = null;
      if (this.$refs.form.validate()) {
        try {
          await this.$store.dispatch('submitInvoiceRequest', {
            data: {
              payment_id: this.$route.params.paymentId,
              name: this.form.name,
              business_name: this.form.businessName,
              business_code: this.form.businessCode,
              business_address: this.form.businessAddress,
              email: this.form.email,
              business_vat_code: this.form.enableVatCodeField
                ? this.form.vatCode
                : null,
              business_country_code: this.form.country,
            },
          });
          this.showSuccessScreen = true;
        } catch (e) {
          if (e.response.data.message.includes('Invoice cannot be changed')) {
            this.error = this.$i18n.translate(
              'components.requestInvoice.error.cannotUpdateInvoice'
            );
          } else {
            this.error = e.response.data.message;
          }
        }
      }

      this.form.loading = false;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
