<template>
  <div class="profile-details-container">
    <NavHeader
      :title="$t('screens.profileDetails.headerTitle')"
      @onBackClick="goBack"
    />
    <LayoutCard grow>
      <div v-for="(value, key) in userDetails" :key="key">
        <v-text-field disabled :label="value.label" :value="value.value" />
      </div>
      <div class="mt-auto"></div>
      <p class="text-center tp-text-label-2 grey--text text--darken-1 mb-0">
        {{ $t('screens.profileDetails.disclaimer') }}
        <br />
        <a href="mailto:privacy@greet.menu">
          {{ $t('screens.profileDetails.contactUs') }}
        </a>
      </p>
    </LayoutCard>
  </div>
</template>

<script>
import { compact } from 'lodash';

import NavHeader from '@/components/Navigation/NavHeader.vue';
import LayoutCard from '@/components/LayoutCard.vue';

export default {
  components: {
    NavHeader,
    LayoutCard,
  },
  computed: {
    session() {
      return this.$store.state.session;
    },
    user() {
      return this.$store.getters.user;
    },
    localeCode() {
      return this.$store.state.locale.locale_code;
    },
    userDetails() {
      const { email, displayName, metadata } = this?.user || {};
      const joined = new Date(metadata?.creationTime).toLocaleDateString(
        this.localeCode
      );

      const details = compact([
        displayName && {
          label: this.$t('screens.profileDetails.nameFieldLabel'),
          value: displayName,
        },
        email && {
          label: this.$t('screens.profileDetails.emailFieldLabel'),
          value: email,
        },
        joined && {
          label: this.$t('screens.profileDetails.joinedFieldLabel'),
          value: joined,
        },
      ]);

      return details;
    },
  },
  async mounted() {
    const sessionToken = this.$route.params.session;
    await this.$store.dispatch('fetchSessionData', { session: sessionToken });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss">
.profile-details-container {
  display: flex;
  flex-flow: column;
  min-height: 100svh;
  height: 100%;
}
</style>
