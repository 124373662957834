<template>
  <div id="menu-header-image">
    <v-img
      v-if="image"
      :contain="!!customizationMenuHeaderCoverContain"
      :max-height="customizationMenuHeaderCoverHeight ?? `150px`"
      :min-height="customizationMenuHeaderCoverHeight ?? `150px`"
      :src="image"
    />
  </div>
</template>

<script>
export default {
  computed: {
    image() {
      return this.$store.state.restaurant.cover;
    },
    customizationMenuHeaderCoverContain() {
      return this.$store.getters.customizationMenuHeaderCoverContain;
    },
    customizationMenuHeaderCoverHeight() {
      return this.$store.getters.customizationMenuHeaderCoverHeight;
    },
  },
};
</script>
