<template>
  <v-dialog
    :persistent="persistent"
    class="dialog"
    :value="isOpen"
    :max-width="maxAppWidth"
    @click:outside="closeClick"
  >
    <div
      class="white d-flex flex-column text-center container"
      :class="{ 'pt-9 pb-9': verticallyPadded }"
      :style="cssProps"
    >
      <v-btn
        v-if="closeIcon"
        class="close-icon"
        dense
        text
        icon
        @click="closeClick"
      >
        <v-icon color="black" dense>mdi-close</v-icon>
      </v-btn>
      <slot name="image"></slot>
      <div v-if="title" class="tp-text-subtitle-semibold">
        {{ title }}
      </div>
      <div v-if="subtitle" class="tp-text-body black--text mt-3">
        {{ subtitle }}
      </div>
      <slot name="content"></slot>
      <div class="px-9">
        <v-btn
          v-show="ctaLabel"
          block
          :disabled="ctaDisabled"
          elevation="0"
          color="primary"
          class="tp-text-body text-no-wrap mt-7 rounded-lg"
          data-cy="modal-cta-button"
          @click="ctaClicked"
        >
          <div class="button-text">
            {{ ctaLabel }}
          </div>
        </v-btn>
        <v-btn
          v-if="showCloseButton"
          block
          elevation="0"
          class="tp-text-body text-no-wrap mt-4 rounded-lg"
          data-cy="modal-close-button"
          @click="closeClick"
        >
          <div class="button-text">
            {{ closeLabel ? closeLabel : $t('generic.cancel') }}
          </div>
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    ctaLabel: {
      type: String,
      default: null,
    },
    closeLabel: {
      type: String,
      default: null,
    },
    image: {
      type: String,
      default: null,
    },
    ctaDisabled: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    bottomPadding: {
      type: Number,
      default: null,
    },
    closeIcon: {
      type: Boolean,
      default: false,
    },
    verticallyPadded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cssProps() {
      return {
        '--bottom-padding': this.bottomPadding
          ? `${this.bottomPadding}px`
          : '24px',
      };
    },
  },
  methods: {
    ctaClicked() {
      this.$emit('cta-click');
    },
    closeClick() {
      this.$emit('close-click');
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  padding: 24px;
  padding-bottom: var(--bottom-padding) !important;
}

.button-text {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  letter-spacing: normal !important;
}

.cta-button {
  max-width: 100px;
}

.close-icon {
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 999;
}
</style>
