<template>
  <div>
    <v-bottom-sheet v-model="open" inset :max-width="maxAppWidth">
      <v-card class="mx-auto" tile>
        <v-list flat>
          <v-list-item-group color="primary">
            <template v-for="(item, key) in items">
              <v-list-item @click="item.click" :key="`information-${key}`">
                <v-list-item-icon>
                  <v-icon color="black" v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="key < items.length - 1"
                :key="`information-divider-${key}`"
              ></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-bottom-sheet>
    <locale-selector></locale-selector>
  </div>
</template>
<script>
import LocaleSelector from '@/components/LocaleSelector.vue';
export default {
  components: {
    LocaleSelector,
  },
  data() {
    return {
      open: this.$store.state.moreInformationOpen,
      languageOpen: false,
      items: [
        {
          icon: 'mdi-magnify',
          text: 'components.moreInformation.search',
          click: () => {
            this.$store.dispatch('openSearch');
          },
        },
        {
          icon: 'mdi-translate',
          text: 'components.moreInformation.translate',
          click: () => {
            this.$store.dispatch('openLanguageSelector');
          },
        },
      ],
    };
  },
  watch: {
    '$store.state.moreInformationOpen': function() {
      this.open = this.$store.state.moreInformationOpen;
    },
    open: function() {
      if (!this.open) {
        this.$store.dispatch('toggleMoreInformation');
      }
    },
  },
};
</script>
