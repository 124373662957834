var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeSheet),expression:"closeSheet"}],ref:"container",staticClass:"menu-item__sheet",style:(_vm.containerStyle)},[_c('div',{staticClass:"menu-item-sheet__close-btn-wrapper",on:{"click":_vm.closeSheet}},[_c('img',{staticClass:"menu-item-sheet__close-btn",attrs:{"src":require(`@/assets/images/icons/close-item-icon.svg`)}})]),_c('div',{directives:[{name:"scroll",rawName:"v-scroll.self",value:(_vm.onScroll),expression:"onScroll",modifiers:{"self":true}}],staticClass:"menu-item-sheet__content",class:{
      'menu-item-sheet__content--out-of-stock':
        !_vm.in_stock || _vm.displayAsOutOfStock,
    }},[_c('div',{staticClass:"menu-item-sheet__top-collapsing-name-wrapper"},[_c('div',{staticClass:"top-collapsing-name tp-text-subtitle"},[_c('v-container',[_vm._v(_vm._s(_vm.$t(_vm.name)))])],1)]),(_vm.photo)?_c('div',{staticClass:"menu-item-sheet__cover"},[_c('LazyImage',{attrs:{"img":_vm.photo,"lazy-img":_vm.thumbnail,"no-blur":true}})],1):_vm._e(),_c('div',{staticClass:"menu-item-sheet-content__hideable-wrapper",class:{
        'menu-item-sheet-content__hideable-wrapper--no-image': !_vm.photo,
        'menu-item-sheet-content__hideable-wrapper--controls-enabled': _vm.displayControls,
        'menu-item-sheet-content__hideable-wrapper--controls-with-remove-enabled':
          _vm.displayControls && _vm.currentItemCountInCart > 0,
      }},[_c('LayoutCard',{attrs:{"type":`item-header`}},[_c('h2',{directives:[{name:"intersect",rawName:"v-intersect",value:({
            handler: _vm.onSheetProductNameIntersect,
            options: {
              threshold: [0.1],
            },
          }),expression:"{\n            handler: onSheetProductNameIntersect,\n            options: {\n              threshold: [0.1],\n            },\n          }"}],staticClass:"menu-item-sheet__title tp-title-bold"},[_vm._v(" "+_vm._s(_vm.$t(_vm.name))+" ")]),(_vm.description)?_c('div',{staticClass:"menu-item-sheet__description tp-text-body-gray pt-3",domProps:{"innerHTML":_vm._s(_vm.$t(_vm.descriptionComputed))}}):_vm._e(),(_vm.dishTagsDefaultAndCustomCombined.length > 0)?_c('dish-tags-sheet',{staticClass:"pt-3",attrs:{"tags":_vm.dishTagsDefaultAndCustomCombined}}):_vm._e(),(!_vm.hasVariations || _vm.popular)?_c('div',{staticClass:"d-flex flex-row align-center pt-4"},[(!_vm.hasVariations)?_c('price',{staticClass:"mr-2",attrs:{"price":_vm.priceComputed,"old-price":_vm.oldPriceComputed,"bigger":true,"custom-font-family":_vm.$store.getters.customizationMenuItemsPriceFontFamily ||
                _vm.$store.getters.customizationMenuFontOverrideFamily,"custom-font-weight":_vm.$store.getters.customizationMenuItemsPriceFontWeight,"custom-font-style":_vm.$store.getters.customizationMenuItemsPriceFontStyle}}):_vm._e(),(_vm.popular)?_c('popular-badge',{attrs:{"bigger":true}}):_vm._e()],1):_vm._e(),(_vm.loyaltyPricingEnabled && _vm.loyalty_price)?_c('div',{staticClass:"d-flex flex-row align-center pt-1"},[(!_vm.hasVariations)?_c('loyalty-price',{attrs:{"price":_vm.loyalty_price,"text":_vm.$store.state.restaurant.loyaltyPricingText,"custom-font-family":_vm.$store.getters.customizationMenuItemsPriceFontFamily ||
                _vm.$store.getters.customizationMenuFontOverrideFamily,"custom-font-weight":_vm.$store.getters.customizationMenuItemsPriceFontWeight,"custom-font-style":_vm.$store.getters.customizationMenuItemsPriceFontStyle,"bigger":true}}):_vm._e()],1):_vm._e(),(_vm.loyaltyPricingEnabled)?_c('loyalty-pricing-disclaimer'):_vm._e(),(!_vm.in_stock || _vm.displayAsOutOfStock)?_c('div',{staticClass:"text-subtitle-2 pt-5"},[_vm._v(" "+_vm._s(_vm.$t('components.menuItem.outOfStock'))+" ")]):_vm._e()],1),(_vm.hasVariations)?_c('LayoutCard',{attrs:{"rounded":""}},[_c('div',{staticClass:"tp-text-subtitle"},[_vm._v(" "+_vm._s(_vm.$t('components.menuItem.chooseVariation'))+" ")]),_c('VariationList',{attrs:{"variations":_vm.variations},on:{"on-change":_vm.selectVariation}})],1):_vm._e(),_vm._l((_vm.option_groups),function(optionGroup,index){return _c('OptionGroup',_vm._b({key:`option-groups-${index}`,attrs:{"selection":_vm.optionsSelection[index].options},on:{"optionSelectionChange":_vm.optionSelectionChange}},'OptionGroup',{
          ...optionGroup,
          menuItemPrice: _vm.priceComputed,
          menuItemLoyaltyPrice: _vm.loyalty_price,
          isCategoryItemsAvailable: _vm.isCategoryItemsAvailable,
        },false))})],2)]),(_vm.displayControls)?_c('div',{staticClass:"menu-item-sheet__controls-wrapper container",class:{
      'pb-5': _vm.currentItemCountInCart === 0,
    }},[_c('div',{staticClass:"menu-item-sheet-controls__add"},[_c('QuantitySelector',{attrs:{"loading":_vm.loading},on:{"changeSelectedQuantity":quantity => (_vm.selectedQuantity = quantity)}}),_c('Button',{attrs:{"loading":_vm.loading,"data-cy":"add-to-order","disabled":!_vm.allowAddToCart || _vm.loading,"background-color":_vm.$store.getters.customizationMenuItemAddToCardButtonColor},on:{"click":_vm.addItemToCart}},[_c('div',{staticClass:"menu-item-sheet-controls__add-btn"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('components.menuItem.addToOrder'))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.priceToOrderFormatted)+" ")])])])],1),(_vm.currentItemCountInCart > 0)?_c('div',{staticClass:"menu-item-sheet-controls__remove tp-text-body-2",class:{
        'mb-5': _vm.currentItemCountInCart > 0,
      },on:{"click":_vm.removeItemsFromCart}},[(_vm.removeLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":15,"width":2}}):_c('span',[_vm._v(_vm._s(_vm.$t('components.menuItem.remove'))+" ("+_vm._s(_vm.currentItemCountInCart)+")")])],1):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }