<template>
  <div>
    <TopButtons />
    <div v-if="loading" class="splash-loader-screen">
      <lottie-vue-player
        src="/assets/animations/greet-logo.json"
        style="width: 120px"
        autoplay
        loop
      />
    </div>
    <SplashScreen
      v-else
      :status="status"
      :failure="failure"
      :loading-screen-customizations="loadingScreenCustomizations"
    />
    <MoreInformation></MoreInformation>
  </div>
</template>

<script>
import SplashScreen from '@/components/SplashScreen.vue';
import MoreInformation from '@/components/MoreInformation';
import TopButtons from '@/components/SplashScreen/TopButtons.vue';

export default {
  name: 'Select',
  components: {
    SplashScreen,
    MoreInformation,
    TopButtons,
  },
  metaInfo() {
    return {
      title: this.pageTitle,
    };
  },
  data() {
    return {
      pageTitle: 'Select - Greet.menu',
      status: 'active',
      failure: false,
      loading: true,
    };
  },
  computed: {
    loadingScreenCustomizations() {
      return this.$store.getters.loadingScreenCustomizations;
    },
  },
  async mounted() {
    window.scrollTo({ top: 0 });
    const sessionToken = this.$route.params.session;
    try {
      if (this.$store.state.restaurant.id === null) {
        await this.$store.dispatch('fetchSessionData', {
          session: sessionToken,
        });
      }

      this.pageTitle = this.$store.state.restaurant.name + ' - Greet.menu';
    } catch (error) {
      this.failure = true;
      if (error.response?.data?.status) {
        this.status = error.response?.data?.status;
      } else {
        throw error;
      }
    } finally {
      this.loading = false;
    }
  },
};
</script>
