import Vue from 'vue';

export const validateUserRequest = async (session, firebaseUid) => {
  const response = await Vue.axios.post(
    `${process.env.VUE_APP_API_URL}loyalty/validate-user`,
    {
      session,
      firebase_uid: firebaseUid,
    }
  );
  return response.data;
};

export const enrollUserRequest = async (
  session,
  firebaseUid,
  loyaltyProgramId,
  details
) => {
  const response = await Vue.axios.post(
    `${process.env.VUE_APP_API_URL}loyalty/enroll-to-program`,
    {
      session,
      firebase_uid: firebaseUid,
      loyalty_program_id: loyaltyProgramId,
      ...details,
    }
  );
  return response.data;
};

export const defaultPhotoUrl = name => {
  return `https://ui-avatars.com/api/?name=${name}`;
};

export const defaultProfilePic = require('@/assets/images/default-profile-image.png');

export const updateUserRequest = async (session, firebaseUid, details) => {
  const response = await Vue.axios.post(
    `${process.env.VUE_APP_API_URL}loyalty/update-user`,
    {
      session,
      firebase_uid: firebaseUid,
      ...details,
    }
  );
  return response.data;
};

export const getUserRequest = async (session, firebaseUid) => {
  const response = await Vue.axios.post(
    `${process.env.VUE_APP_API_URL}loyalty/get-user`,
    {
      session,
      firebase_uid: firebaseUid,
    }
  );
  return response.data.data;
};
