import Vue from 'vue';

export const generateInPersonPaymentIntentRequest = async ({
  session,
  order,
}) => {
  try {
    return await Vue.axios.post(`/payments/worldline/create-sale-transaction`, {
      session,
      order,
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateInPersonPaymentIntentRequest = async ({
  session,
  data,
  type,
}) => {
  try {
    return await Vue.axios.post(`/payments/worldline/callback`, {
      data: {
        session,
        ...data,
      }, // terminal data
      type, // 'payment_succeeded' | 'payment_failed' | 'payment_info_event' | 'settlement_succeeded'
    });
  } catch (error) {
    console.error(error);
  }
};

export const fetchPaymentByUUIDRequest = async ({ paymentUUID }) => {
  return await Vue.axios.get(`/payments/payment-by-uuid/${paymentUUID}`);
};
