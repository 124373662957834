<template>
  <div class="menu-item-quantity-selector">
    <div class="decrease" @click="decreaseQuantity">-</div>
    <div class="tp-text-subtitle-semibold">{{ quantity }}</div>
    <div class="increase" @click="increaseQuantity">+</div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      quantity: 1,
    };
  },
  methods: {
    decreaseQuantity() {
      if (this.loading) {
        return;
      }
      if (this.quantity <= 1) {
        return;
      }
      this.quantity--;

      this.$store.dispatch('sendAnalyticsEvent', {
        event: 'decrease_quantity',
      });

      this.$emit('changeSelectedQuantity', this.quantity);
    },
    increaseQuantity() {
      if (this.loading) {
        return;
      }
      if (this.quantity >= 50) {
        return;
      }
      this.quantity++;

      this.$store.dispatch('sendAnalyticsEvent', {
        event: 'increase_quantity',
      });

      this.$emit('changeSelectedQuantity', this.quantity);
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-item-quantity-selector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  position: relative;
  z-index: inherit;
  background: white;
  border-radius: 10px;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    background: var(--color-primary);
    opacity: 0.05;
    border-radius: 10px;
    z-index: 0;
  }
}

.decrease {
  font-size: 24px;
  color: $color-gray-500;
  z-index: 1;
}
.increase {
  font-size: 24px;
  color: var(--color-primary);
  z-index: 1;
}
</style>
