<template>
  <Modal
    :is-open="!payGoOnboardingDone"
    :cta-label="ctaLabel"
    :show-close-button="false"
    :persistent="false"
    close-icon
    vertically-padded
    @cta-click="ctaClicked"
    @close-click="close"
  >
    <template slot="content">
      <div>
        <v-item-group v-model="onboardingSteps" mandatory>
          <v-window v-model="stepIndex">
            <v-window-item v-for="step in onboardingSteps" :key="step.id">
              <v-img
                aspect-ratio="1"
                contain
                :src="step.image"
                height="200px"
              />
              <div class="tp-text-subtitle-semibold pt-7">
                {{ $t(step.title) }}
              </div>
              <div class="tp-text-body black--text pt-4">
                {{ $t(step.description) }}
              </div>
            </v-window-item>
          </v-window>
        </v-item-group>

        <div class="pt-7 pb-3">
          <v-row align="center" justify="center">
            <v-item-group v-model="stepIndex">
              <v-item
                v-for="n in onboardingSteps.length"
                :key="n"
                v-slot="{ active, toggle }"
              >
                <v-btn :input-value="active" icon x-small @click="toggle">
                  <v-icon x-small>mdi-record</v-icon>
                </v-btn>
              </v-item>
            </v-item-group>
          </v-row>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/common/Modal.vue';

export default {
  components: {
    Modal,
  },
  data() {
    return {
      stepIndex: 0,
      length: 3,
      stepsDone: false,
    };
  },
  computed: {
    onboardingSteps() {
      return [
        {
          id: 1,
          title: 'components.onboarding.payGo.step1.title',
          description: 'components.onboarding.payGo.step1.description',
          image: `https://cdn.greet.menu/pay-go-modal-2/1-pay-go.png`,
        },
        {
          id: 2,
          title: 'components.onboarding.payGo.step2.title',
          description: 'components.onboarding.payGo.step2.description',
          image: `https://cdn.greet.menu/pay-go-modal-2/2-pay-go-${this.$i18n.locale()}.png`,
        },
        {
          id: 3,
          title: 'components.onboarding.payGo.step3.title',
          description: 'components.onboarding.payGo.step3.description',
          image: `https://cdn.greet.menu/pay-go-modal-2/3-pay-go-${this.$i18n.locale()}.png`,
        },
        {
          id: 4,
          title: 'components.onboarding.payGo.step4.title',
          description: 'components.onboarding.payGo.step4.description',
          image: `https://cdn.greet.menu/pay-go-modal-2/4-pay-go-${this.$i18n.locale()}.png`,
        },
      ];
    },
    payGoOnboardingDone() {
      return this.$store.getters.payGoOnboardingDone;
    },
    ctaLabel() {
      return this.$i18n.translate(
        this.stepIndex === this.onboardingSteps.length - 1
          ? 'generic.understood'
          : 'generic.next'
      );
    },
  },
  methods: {
    ctaClicked() {
      if (this.stepIndex === this.onboardingSteps.length - 1) {
        this.$store.dispatch('setPayGoOnboardingDone');
      } else {
        this.stepIndex++;
      }
    },
    close() {
      this.$store.dispatch('setPayGoOnboardingDone');
    },
  },
};
</script>
