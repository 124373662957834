const initialState = {
  externalWallets: [],
};

export default {
  state: initialState,
  mutations: {
    setExternalWallets(state, externalWallets) {
      state.externalWallets = externalWallets;
    },
  },
  actions: {
    setExternalWallets({ commit }, externalWallets) {
      commit('setExternalWallets', externalWallets);
    },
    sendStripeIntegrationDetails({ getters }) {
      const currencyCode = getters.getCurrencyCode;
      postMessageToWebview({
        stripePublishableKey: process.env.VUE_APP_STRIPE_PK,
        walletProps: {
          googlePay: {
            testEnv: process.env.VUE_APP_ENVIRONMENT !== 'production',
            merchantCountryCode: 'LT',
            currencyCode,
          },
        },
      });
    },
    sendStripeClientSecret(_, clientSecret) {
      postMessageToWebview({
        stripeClientSecret: clientSecret,
      });
    },
    subscribeToExternalWallets({ dispatch }) {
      document?.addEventListener(
        'message',
        event => {
          const data = JSON.parse(event.data);

          if (data?.externalWallets) {
            dispatch('setExternalWallets', data.externalWallets);
          }
        },
        false
      );
    },
    subscribeToExternalPaymentStatus(_, { onSuccess, onError }) {
      document?.addEventListener(
        'message',
        event => {
          const data = JSON.parse(event.data);
          const { paymentStatus } = data;
          if (paymentStatus === 'success') {
            onSuccess();
          } else if (paymentStatus === 'error') {
            onError();
          }
        },
        false
      );
    },
    subscribeToFetchMenu({ dispatch }) {
      document?.addEventListener(
        'message',
        event => {
          const data = JSON.parse(event.data);

          if (data?.forceFetchMenu) {
            dispatch('forceFetchMenu');
          }
        },
        false
      );
    },
    openTerminalBackOffice() {
      postMessageToWebview('openTerminalBackOffice');
    },
    generateNewKioskSession() {
      postMessageToWebview('generateNewKioskSession');
    },
  },
  getters: {
    externalGPayWalletAvailable(state) {
      return state.externalWallets.includes('googlePay');
    },
  },
};

export const postMessageToWebview = message => {
  if (!window?.ReactNativeWebView) {
    return;
  }
  window?.ReactNativeWebView?.postMessage(JSON.stringify(message));
};
