<template>
  <div class="text-center">
    <v-dialog v-model="show" width="400" persistent>
      <v-card class="mx-auto">
        <v-card-title>
          <v-icon left color="#ffd700">
            mdi-alert
          </v-icon>
          <span class="text-h5 font-weight-bold">
            {{ $t('kiosk.terminalCommands.unresolvedCRNs.title') }}
          </span>
        </v-card-title>

        <v-card-text class="text-h6 font-weight-light">
          {{ $t('kiosk.terminalCommands.unresolvedCRNs.description') }}
          <div class="d-flex justify-center py-4">
            <v-progress-circular
              v-if="loading"
              indeterminate
              color="black"
            ></v-progress-circular>
          </div>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import { OPERATION } from '@/utils/worldLine';
export default {
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    unresolvedCRNs() {
      return this.$store.getters.unresolvedCRNs || [];
    },
    hasUnresolvedCRNs() {
      return this.unresolvedCRNs.length > 0;
    },
    printerOnline() {
      return this.$store.getters.printerIsOnline;
    },
    posConnected() {
      return this.$store.getters.terminalOnline;
    },
    show() {
      return this.$store.getters.loadingUnresolvedCRNs;
    },
  },
};
</script>
