<template>
  <div class="top-menu" :class="{ visible: isScrolled }">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    scrollThreshold: {
      type: Number,
      default: 50,
    },
  },
  data() {
    return {
      isScrolled: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      if (window.scrollY > this.scrollThreshold) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.top-menu {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  transition: transform 0.3s;
  z-index: 1000;
  transform: translateY(-100%);
  border-radius: 0 0 5px 5px !important;
  flex-direction: row;
  display: flex;
  padding: 12px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);

  @media screen and (min-width: $max-app-width) {
    max-width: $max-app-width;
    margin-left: auto;
    margin-right: auto;
  }
}

.top-menu.visible {
  transform: translateY(0);
}
</style>
