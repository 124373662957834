<template>
  <div></div>
</template>

<script>
export default {
  name: 'Preview',
  metaInfo: {
    title: 'Preview - Greet.menu',
  },
  async mounted() {
    const response = await this.axios.get(
      `/preview/${this.$route.params.hash}`
    );
    const session = response.data.data.session;
    const mode = response.data.data.mode;

    this.$store.dispatch('setSessionMode', mode);

    const editor = this.$route.query.editor === 'true';
    const previewPathName = this.$route.query.previewPathName;

    if (editor) {
      this.$router.push({ name: previewPathName, params: { session } });
    } else {
      this.$router.push({ name: 'Menu', params: { session } });
    }
  },
};
</script>
