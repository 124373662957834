<template>
  <LayoutCard
    v-if="tipsEnabled"
    :title="$t('components.tipPersonnel.title')"
    rounded
  >
    <div class="tp-text-body pb-3 grey--text">
      {{ $t('components.tipPersonnel.subtitle') }}
    </div>
    <WaiterCarousel @on-change="changeWaiter" />
    <v-expand-transition>
      <v-row v-show="showCta" no-gutters>
        <Button
          dark
          x-small
          class="mt-3"
          data-cy="leave-tip-cta"
          @click="navigateToTipWaiter"
        >
          {{ $t('components.personnelPicker.ctaLabel') }}
        </Button>
      </v-row>
    </v-expand-transition>
  </LayoutCard>
</template>

<script>
import LayoutCard from '@/components/LayoutCard.vue';
import WaiterCarousel from '@/components/Tips/WaiterCarousel.vue';
import Button from '@/components/Button.vue';

export default {
  components: {
    WaiterCarousel,
    LayoutCard,
    Button,
  },
  props: {
    orderId: {
      type: String,
      required: false,
      default: null,
    },
    sessionToken: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: () => ({
    selected: null,
  }),
  computed: {
    personnelList() {
      return this.$store.getters.personnelList;
    },
    tipsEnabled() {
      if (this.personnelList.length === 0) {
        return false;
      }
      return this.$store.getters.functionalityTipsEnabled;
    },
    showCta() {
      return this.selected !== null;
    },
    session() {
      return this.$store.state.session;
    },
    selectedPersonnelMemberName() {
      if (this.selected === null) {
        return null;
      }
      return this.$store.getters.personnelMemberById(this.selected).name;
    },
    tipsQrCardHash() {
      return this.$store.getters.personnelMemberById(this.selected)
        .tips_qr_card_hash;
    },
  },
  async mounted() {
    await this.$store.dispatch('fetchPersonnel');
  },
  methods: {
    changeWaiter(waiterId) {
      this.selected = waiterId;
    },
    navigateToTipWaiter() {
      this.$router.push({
        name: 'TipWaiter',
        params: {
          tipsQrCardHash: this.tipsQrCardHash,
        },
        query: {
          orderId: this.orderId,
          sessionToken: this.sessionToken,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
