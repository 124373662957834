export const getCartOptionByMenuItemAndOptionId = (menuItem, optionId) => {
  const option = menuItem.option_groups
    .find(optionGroup => {
      return (
        optionGroup.options.filter(option => {
          return option.id === optionId;
        }).length > 0
      );
    })
    .options.find(option => {
      return option.id === optionId;
    });

  return {
    loyalty_markup: option.loyalty_markup,
    markup: option.markup,
    name: option.name,
  };
};
