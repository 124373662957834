<template>
  <div>
    <div v-if="showGreetLoader" class="splash-loader-screen">
      <lottie-vue-player
        src="/assets/animations/greet-logo.json"
        style="width: 120px"
        autoplay
        loop
      />
    </div>
    <div v-else>
      <SplashScreen
        v-if="failure"
        :status="status"
        :failure="failure"
        :loading-screen-customizations="loadingScreenCustomizations"
      />
    </div>
  </div>
</template>

<script>
import SplashScreen from '@/components/SplashScreen.vue';

export default {
  name: 'ScanQr',
  components: {
    SplashScreen,
  },
  metaInfo: {
    title: 'Scanning code - Greet.menu',
  },
  data() {
    return {
      status: 'active',
      failure: false,
      showGreetLoader: true,
    };
  },
  computed: {
    showSelectScreenEnabled() {
      return this.$store.getters.functionalityShowSelectScreenEnabled;
    },
    loadingScreenCustomizations() {
      return this.$store.getters.loadingScreenCustomizations;
    },
  },
  async mounted() {

    window.scrollTo({ top: 0 });
    let session = null;
    const tableHash = this.$route.params.hash;
    try {
      const sessionData = this.$store.getters.getSessionCookie;
      if (sessionData !== false) {
        try {
          const { restaurant_id, table_id } = (
            await this.axios.get(`/restaurant-by-code/${tableHash}`)
          ).data;
          if (
            restaurant_id == sessionData.restaurant_id &&
            table_id === sessionData.table_id
          ) {
            session = sessionData.session;
          } else {
            this.$store.dispatch('deleteSessionCookie');
          }
        } catch (e) {
          this.$store.dispatch('deleteSessionCookie');
          console.log('Not existing session');
        }
      }

      if (!session) {
        session = (await this.axios.get(`/${tableHash}`)).data.data.session;
      }
      this.$store.dispatch('fetchMenuData', { session });
      Promise.all([
        await this.$store.dispatch('fetchSessionData', { session }),
        await new Promise(resolve =>
          setTimeout(() => {
            resolve();
          }, 1200)
        ),
      ]);

      // If pay&go enabled, we show Menu/Order CTAs in Splash Screen
      if (
        (this.$store.getters.functionalityPayGoEnabled ||
          this.showSelectScreenEnabled) &&
        !this.$route.query.skip_select_screen
      ) {
        await this.$router.push({
          name: 'Select',
          params: { session: session },
        });
      } else if (this.$store.getters.restaurantMenuFilters.length > 0) {
        await this.$router.push({
          name: 'MenuFilters',
          params: { session: session },
        });
      } else {
        await this.$router.push({ name: 'Menu', params: { session: session } });
      }
    } catch (error) {
      this.failure = true;
      if (error.response?.data?.status) {
        this.status = error.response?.data?.status;
      } else {
        throw error;
      }
    } finally {
      this.loading = false;
      this.showGreetLoader = false;
    }
  },
};
</script>

<style lang="scss">
.splash-loader-screen {
  background-color: $color-background-gray;
  height: 100vh;
  height: 100dvh;
  height: calc(var(--app-height) * 1px);
  width: 100%;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  position: relative;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
