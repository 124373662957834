import Vue from 'vue';
import cookies from '@/utils/cookies';

import {
  fetchDonationDetailsRequest,
  generateNeoPayDonationPaymentIntentRequest,
  generateStripeDonationPaymentIntentRequest,
  getDonateTranslationsRequest,
} from './api';

const initialState = {
  donationSession: cookies.getCookie('donationSession') || null,
  donatee: {},
  donationQrCodeUnavailable: false,
};

// TTL in hours
const TTL = 6;

export default {
  state: initialState,
  mutations: {
    setDonationSession(state, session) {
      state.donationSession = session;
    },
    setDonatee(state, donatee) {
      state.donatee = donatee;
    },
    setDonationQrCodeUnavailable(state, value) {
      state.donationQrCodeUnavailable = value;
    },
  },
  actions: {
    async fetchDonationSession({ commit, rootState }, { hash }) {
      const donationSession = cookies.getCookie('donationSession') || null;

      try {
        const response = await fetchDonationDetailsRequest({
          donationSession,
          hash,
        });
        const { session, restaurant } = response.data;

        cookies.setCookie({
          name: 'donationSession',
          value: session,
          hours: TTL,
        });
        commit('setDonationSession', session);
        commit('setDonatee', restaurant);

        // TODO - this is a hack
        if (!rootState.restaurant.id) {
          const currency = restaurant.currency;
          const locale = restaurant.locale;
          const payment_providers = restaurant.payment_providers;

          if (!payment_providers) {
            throw new Error('No payment providers found');
          }

          rootState.currency = currency;
          rootState.locale = locale;
          rootState.payment_providers = payment_providers;
        }
      } catch (error) {
        commit('setDonationQrCodeUnavailable', true);
      }
    },
    async createStripeDonationPaymentIntent({ state }, { amount }) {
      const response = await generateStripeDonationPaymentIntentRequest({
        donationSession: state.donationSession,
        amount,
      });
      return response.data;
    },
    async createNeoPayDonationPaymentIntent({ state }, { amount }) {
      const response = await generateNeoPayDonationPaymentIntentRequest({
        donationSession: state.donationSession,
        amount,
      });
      return response.data;
    },
    async getDonateTranslations({ getters }, languageCode) {
      const restaurantId = getters.donatee.id;
      if (restaurantId) {
        const response = await getDonateTranslationsRequest({
          restaurantId,
          languageCode,
        });
        Vue.i18n.add(languageCode, response.data);
      }
    },
  },
  getters: {
    donationSession: state => state.donationSession,
    donatee: state => state.donatee,
    donationQrCodeUnavailable: state => state.donationQrCodeUnavailable,
  },
};
